import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {Message} from '../models/sp-models';

@Injectable({
  providedIn: "root",
})
export class AlertService {
  private subject = new BehaviorSubject<Message>(null);
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert message
          this.clear();
        }
      }
    });
  }

  getAlert() {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type: "success", text: message });
  }

  error(message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type: "error", text: message });
  }

  clear() {
    // ENVOIE UN MESSAGE VIDE
    this.subject.next(null);
  }
}
