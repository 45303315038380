import { DataMocker } from './data-mocker';
import { NpApiResult } from '../../../model/np-api-result';
import { Observable } from 'rxjs/Observable';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

/**
 * Ce dataMocker a pour objectif de laisser passer la requete
 * Utile si on veut vérifier certaines url mais laisser le traitement pour d'autres
 */
export class DataMockerError extends DataMocker {
  mockResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return Observable.throw('Message de np-sdk-data : DataMockerError.Unauthorised');
  }

  matchUrl(request: HttpRequest<any>): boolean {
    return true;
  }
  getData(): NpApiResult {
    return null;
  }

  getUrlPattern(): string {
    return '';
  }
}
