import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {NPElement, NPElementType} from '../model/np-element';
import {Observable} from 'rxjs/Observable';
import {NPAPIElementLinksResult} from '../model/NPAPIElementLinksResult';
import {WSParameters, WsParamsService} from './ws-params.service';
import {DicoCarac, NPDicoCarac} from '../model/np-dico-carac';
import {LastModificationType} from '../model/last-modification-type';
import {ElementsHelper} from '../helpers/elements.helper';


@Injectable({
  providedIn: 'root',
})
export class NPElementRepository {

  private _urlGetElement = '/api/sdk/element/ElementsAndLinks';
  private _urlGetDescendants = '/api/sdk/element/DescendantsAndLinks';
  private _urlGetByCharTemplate = '/api/sdk/element/ElementsByCharTemplate';
  private _urlGetSearchForLink = '/api/sdk/element/GetElementsByTextForDicoCarac';
  private _urlGetLastModified = '/api/sdk/element/LastModifiedAndLinks';

  public forTestAllResults: Map<string, NPElement>;

  constructor(private _http: HttpClient, private _paramServices: WsParamsService) {
  }

  getElements(ElementIDs: string[], linksPath: string[][]): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          ElementsExtIDs: ElementIDs,
          Paths: linksPath,
          ContextID: params.ContextID,
          LangID: params.LangID,
        };
        return this._http.post<Object>(this._urlGetElement, postParameters);
      }),
      map((data) => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return ElementsHelper.rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  public getLastModifieds(elementsExtIDs: string[], linksPath: string[][], lastModificationType: LastModificationType,
                          elementTypes: NPElementType[] =  [NPElementType.Product], maxResults: number = 10) : Observable<NPElement[]> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          ElementsExtIDs: elementsExtIDs,
          Paths: linksPath,
          ContextID: params.ContextID,
          LangID: params.LangID,
          LastModificationType: LastModificationType,
          MaxResults: maxResults,
          ElementTypes: elementTypes
        };
        return this._http.post<Object>(this._urlGetLastModified, postParameters);
      }),
      map((data) => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return ElementsHelper.rebuild(data['Results']);
          }
        }
      }),
      map(data => {
        const result: NPElement[] = [];
        data.forEach((value) => {
          result.push(value);
        });
        return result;
      }),
      first(),
      shareReplay(1)
    );
  }

  getDescendants(ElementIDs: string[], linksPath: string[][], loadData?: string[], OnlyOneLevel?: boolean): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          ElementsExtIDs: ElementIDs,
          Paths: linksPath,
          ContextID: params.ContextID,
          LangID: params.LangID,
          OnlyOneLevel: OnlyOneLevel,
          DCExtIDs: loadData ? loadData : []
        };
        return this._http.post<Object>(this._urlGetDescendants, postParameters);
      }),
      map((data) => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return ElementsHelper.rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  public getByCharTemplates(charTemplatesExtID: string, linksPath: string[][]): Observable<Map<string, NPElement>> {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
          CharTemplateExtID: charTemplatesExtID,
          ContextID: params.ContextID,
          Paths: linksPath,
          LangID: params.LangID
        };
        return this._http.post<Object>(this._urlGetByCharTemplate, postParameters);
      }),
      map((data) => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return ElementsHelper.rebuild(data['Results']);
          }
        }
      }),
      first(),
      shareReplay(1)
    );
  }

  public searchForLink(dicoCarac: NPDicoCarac, searchText: string, element: NPElement) {
    return this._paramServices.getParams().pipe(
      switchMap((params: WSParameters) => {
        const postParameters = {
            DicoCaracID: dicoCarac.ID,
            SearchText: searchText,
            ElementID: element.ID,
            ContextID: params.ContextID,
            LangID: params.LangID
          }
        ;
        return this._http.post<Object>(this._urlGetSearchForLink, postParameters);
      }),
      map((data) => {
        if (data.hasOwnProperty('Results')) {
          if (data['Results'] != null) {
            return ElementsHelper.rebuild(data['Results']);
          }
        }
      }),
      map((data) => {
        const result = [];
        data.forEach(value => {
          result.push(value);
        });
        return result;
      }),
      first(),
      shareReplay(1)
    );
  }
}
