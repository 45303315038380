import {createFeatureSelector, createSelector} from '@ngrx/store';
import {publicationChaptersFeatureKey, PublicationChaptersState} from '@store/publication';
import {Chapter} from '@app/models';
import {ChaptersTags} from '@data/enums';

const selectPublicationChaptersState = createFeatureSelector<PublicationChaptersState>(
    publicationChaptersFeatureKey
);

export const selectPublicationChaptersView = (chapters?: Chapter[]) => createSelector(
    selectPublicationChaptersState,
    (state: PublicationChaptersState) => {

        const channelCategoriesAsChapters: Chapter[] = state.chapters
            ?.map((channelCategory => {
                const channelCategoryAsChapter = new Chapter();
                channelCategoryAsChapter.name = channelCategory.Label;
                channelCategoryAsChapter.channelCategoryID = channelCategory.ID;
                channelCategoryAsChapter.channelCategoryExtID = channelCategory.ExtID;
                return channelCategoryAsChapter;
            }));

        const chaptersWithoutIntroAndLast: Chapter[] = chapters?.filter(chapter =>
            !chapter.isIntroductionChapter && !chapter.isEndChapter) || [];

        const extIdsInChapters = new Set(chaptersWithoutIntroAndLast
            .map(chapter => chapter.channelCategoryExtID));

        const remainingChapters = channelCategoriesAsChapters?.filter(category => !extIdsInChapters.has(category.channelCategoryExtID));
        

        return ({
            ...state,
            channelCategoriesAsChapters,
            chaptersWithoutIntroAndLast,
            remainingChapters
        });
    }
);
