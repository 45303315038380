import {createReducer, on} from '@ngrx/store';
import {ElementsSelection} from './export-selection.actions';

export const elementsSelectionFeatureKey = 'elementsSelection';

export interface ElementElementsSelectionState {
    elementIds: number[];
    excludedElementIds: number[];
    isSelectAll: boolean | null;
    errorMessage: string;
    loading: boolean;
    hasError: boolean;
}

export const initialStateElementsSelection: ElementElementsSelectionState = {
    elementIds: [],
    excludedElementIds: [],
    isSelectAll: null,
    errorMessage: null,
    loading: false,
    hasError: false
};

export const elementsSelectionReducer = createReducer(
    initialStateElementsSelection,
    on(
        ElementsSelection.addToSelection,
        (state, action): ElementElementsSelectionState => {
            let elementIds = [...state.elementIds];
            let excludedElementIds = [...state.excludedElementIds];

            if (state.isSelectAll) {
                if (action.checked) {
                    excludedElementIds = excludedElementIds.filter(id => id !== action.elementId);
                } else {
                    excludedElementIds.push(action.elementId); // Elements to exclude
                }

            } else {
                elementIds = _addOrRemoveElementIds(elementIds, action.elementId);
            }

            return ({
                ...state,
                elementIds: elementIds,
                excludedElementIds: excludedElementIds,
                loading: false,
                hasError: false,
                errorMessage: null
            });
        }
    ),
    on(
        ElementsSelection.setSelectAllElements,
        (state, action): ElementElementsSelectionState => ({
            ...state,
            elementIds: [],
            excludedElementIds: [],
            isSelectAll: action.isSelectAllProducts,
            loading: false,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        ElementsSelection.cleanStore,
        (state): ElementElementsSelectionState => ({
            ...state,
            ...initialStateElementsSelection
        })
    )
);

function _addOrRemoveElementIds(elementIds: number[], idToAddOrRemove: number) {
    let _elementIds = [...elementIds];

    if (_elementIds.some(id => id === idToAddOrRemove)) {
        _elementIds = _elementIds.filter(id => id !== idToAddOrRemove);
    } else {
        _elementIds.push(idToAddOrRemove);
    }

    return _elementIds;
}
