import {AdvSearchArgsBuilder} from '../builders';
import {AdvancedSearchDTO} from '../types';
const CARAC_EXT_IDS = ['##MediaFile', '##MediaLabel', 'DC_MEDIA_METADATA_Size', 'DTOSystemLastModifiedDate'];

export class AdvancedSearchHelper {
    static buildAdvancedSearch(advancedSearch: AdvancedSearchDTO) {
        return new AdvSearchArgsBuilder()
            .withPageSize(10)
            .withCurrentPage(1)
            .withPaths([[]])
            .withConfig(advancedSearch)
            .withDCExtIDs(CARAC_EXT_IDS)
            .build();
    }
}
