import {createFeatureSelector, createSelector} from '@ngrx/store';
import {dashboardPageFeatureKey, DashboardPageState} from './dashboard.reducer';

const selectElementsState = createFeatureSelector<DashboardPageState>(dashboardPageFeatureKey);

export const selectDashboardQueries = createSelector(
    selectElementsState,
    state => state
);
export const selectDashboardQueriesLoader = createSelector(
    selectElementsState,
    state => state.loading && state.hasAdvancedSearches
);

