import {Component} from '@angular/core';
import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import {FormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-logo-supplier',
  template: '',
  standalone: true
})
export class SearchExtensionComponent {
  protected searchFormControl = new FormControl<string>('');
  protected resetSearchField: Observable<string>;
  protected searchFormControlSub: Subscription;


  constructor() {
    this.resetSearchField = this.searchFormControl.valueChanges
        .pipe(filter(searchedValue => _.isEmpty(searchedValue)));
  }

}
