import {createReducer, on} from '@ngrx/store';
import {
    AddMedias,
    createMediaByExternalUrl, createMediaByExternalUrlFailure,
    createMediaByExternalUrlSuccess,
    RemoveMedia,
    UpdateMedia,
    UpdateMediaFailure,
    UpdateMediaSuccess,
    UploadFiles,
    UploadFileSuccess
} from '@store/import-media';
import {UploadImage} from '../../../lib/graphics/np-media-library/np-media-library.component';

export const importMediaFeatureKey = 'importMedia';

export interface ImportMediaState {
    medias: UploadImage[];
    errorMessage: string;
    loading: boolean;
    hasError: boolean;
}

export const initialImportMediaState: ImportMediaState = {
    medias: [],
    errorMessage: null,
    loading: false,
    hasError: false
};

export const importMediaReducer = createReducer(
    initialImportMediaState,
    on(UploadFiles,
        state => ({
            ...state,
            loading: true,
        })
    ),
    on(UploadFileSuccess,
        state => ({
            ...state,
            medias: [],
            loading: false,
            hasError: false,
        })
    ),
    on(UpdateMedia,
        state => ({
            ...state,
            loading: true,
        })
    ),
    on(UpdateMediaSuccess, state => ({
        ...state,
        loading: false
    })),
    on(UpdateMediaFailure, state => ({
        ...state,
        loading: false,
        hasError: true,
    })),
    on(AddMedias, (state, {medias}) => {
            return ({
                ...state,
                medias: concatMedias(Object.assign([], state.medias), medias),
                loading: false,
                hasError: true,
            });
        }
    ),
    on(RemoveMedia, (state, {media}) => {
            return ({
                ...state,
                medias: state.medias.filter(existingMedia => existingMedia !== media),
                loading: false,
                hasError: true,
            });
        }
    ),
    on(createMediaByExternalUrl,
        state => ({
            ...state,
            loading: true,
        })
    ),
    on(createMediaByExternalUrlSuccess,
        state => ({
            ...state,
            loading: false,
        })
    ),
    on(createMediaByExternalUrlFailure,
        state => ({
            ...state,
            loading: false,
            hasError: true,
        })
    ),

);

function concatMedias(oldMedias: UploadImage[], newMedias: UploadImage[]) {

    newMedias.forEach(media => {
        if (!oldMedias.some(oldMedia => oldMedia.name === media.name)) {
            oldMedias.push(media);
        }
    });

    return oldMedias;
}

