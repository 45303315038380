import {createReducer, on} from '@ngrx/store';
import {ProductSummaryWithTotalRow} from '@data/types';
import {
    FetchProductsElementsFailure,
    FetchProductsElementsLoader,
    FetchProductsElementsSuccess
} from './products.actions';

export const productsFeatureKey = 'products';

export interface ProductsState {
    productWithTotalRow: ProductSummaryWithTotalRow;
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
}

export const initialElements: ProductsState = {
    productWithTotalRow: null,
    errorMessage: null,
    loading: true,
    hasError: false
};

export const productsReducer = createReducer(
    initialElements,
    on(
        FetchProductsElementsLoader,
        (state, ): ProductsState => ({
            ...state,
            productWithTotalRow: null,
            loading: true, // To maintain the loader
            errorMessage: null,
        })
    ),
    on(
        FetchProductsElementsSuccess,
        (state, {productSummariesResult}): ProductsState => ({
            ...state,
            productWithTotalRow: productSummariesResult,
            loading: false,
            errorMessage: null,
        })
    ),
    on(FetchProductsElementsFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
