import {ExportMediaItem, ExportMediasParam, ExportMediaType} from '../models/export-medias.param.model';
import {ExportExcelSummary} from '../models/export-models';
import {NpUser} from '@nextpage/np-sdk-data';
import * as _ from 'lodash';
import { ExportParams } from '../models/export.params.model';
import {Router} from '@angular/router';

export class ExportHelper {

    static buildExportMediaParam(allowedThumbsSizes: string[], label: string) {
        const param = new ExportMediasParam(label);
        param.parameters = param.parameters.concat(
            allowedThumbsSizes.map(allowedThumbsSize =>
                new ExportMediaItem(ExportMediaType.EXPORT_MEDIA_FORMAT, allowedThumbsSize, allowedThumbsSize)
            ));
        return param;
    }

    static buildExportExcelSummary(
        elementIds: number[],
        exportProfile: string,
        fileName: string,
        exportConfig: ExportParams,
        user: NpUser
    ): ExportExcelSummary {
        return {
            fileName: fileName,
            userEmail: user?.Email,
            userID: user?.ID,
            productsId: elementIds,
            mediasId: elementIds,
            profileExportName: exportProfile,
            exportConfig: exportConfig
        };
    }

     static generateExportName(router: Router) {

        const prefixExportType = router.url.includes('medias') ? 'MEDIA_' : 'PRODUCT_';

        return `${prefixExportType}${_.head(self.crypto.randomUUID().split('-')).toUpperCase()}`;

    }

}
