import { Component, Inject} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { TemplatesNames, TemplatesTags } from "@data/enums";
import { selectLoadingAuthentication } from "src/app/store/selector";
import { CatalogPage, Chapter } from "@app/models";
import { UpdateChapter } from "@store/publication";

export class Operator {
    name: string;
    code: string;
}

@Component({
    selector: 'app-dialog-pages-addition',
    templateUrl: './dialog-pages-addition.component.html',
    styleUrls: ['./dialog-pages-addition.component.scss']
})

export class DialogPagesAdditionComponent {

    public loading$ = this._store.select(selectLoadingAuthentication); //TODO new store?

    public addPagesForm: FormGroup;
    public operators: Operator[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { chapter: Chapter, page: CatalogPage },
        private _fb: FormBuilder,
        private _store: Store,
        private _translate: TranslateService) {
        this.addPagesForm = this._fb.group({
            pagesOperators: ['before', Validators.required],
            pagesNumber: [1, Validators.required],
        });

        this.operators = [
            { name: this._translate.instant('common.fields.before'), code: 'before' },
            { name: this._translate.instant('common.fields.after'), code: 'after' }
        ];

    }

    public addPagesToChapter(): void {
        const currentPageIndex = _.findIndex(this.data.chapter.pages, page => _.isEqual(page, this.data.page));
        const pagesOperator = this.addPagesForm.controls.pagesOperators.value;
        const pagesNumber = this.addPagesForm.controls.pagesNumber.value;

        if (currentPageIndex === -1) {
            console.warn('Page not found in chapter.');
            return;
        }

        const pagesToAdd = Array.from({ length: pagesNumber }, () => new CatalogPage({
            name: `${TemplatesTags.PRODUCT} - Page`,
            type: TemplatesNames.PRODUCT,
            isEditable: true,
            products: [],
            template: null
        }));

        if(this.data.chapter) {
            const insertIndex = pagesOperator === 'before' ? currentPageIndex : currentPageIndex + 1;
            this.data.chapter.pages.splice(insertIndex, 0, ...pagesToAdd);
    
            _.remove(this.data.chapter.pages, (page: CatalogPage) => page.name.includes(TemplatesTags.NOTE));

            this._store.dispatch(UpdateChapter({chapter: this.data.chapter}));
        }
    }
}