  <p-multiSelect
    [options]="displayValues"
    [formControl]="selectedItems"
    optionLabel="Valeur"
    placeholder=""
    styleClass="p-multi-select-custom "
    showClear="true"
  ></p-multiSelect>

  <ul class="mt-3 mb-0 list-unstyled selected-values" *ngIf="caracConfig?.Specific?.displaySelectedValues">
    <li *ngFor="let selectedItem of selectedItems.value">
      <button class="remove-btn" title="Supprimer" (click)="removeItem(selectedItem)">
        <fa-icon [icon]="['far', 'xmark']"></fa-icon>
      </button>
      {{selectedItem.Valeur}}
    </li>
  </ul>
