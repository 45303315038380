<mat-dialog-content>
    <div id="upload-modal">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeSelectMedia()"><span aria-hidden="true">&times;</span></button>
        <div class="upload-action-container">
      <span class="upload-trigger" tabindex="0" [class.active]="filesList.length" (click)="uploadAllFiles($event)">
        Envoyer
      </span>
        </div>
        <div class="dropzone" fileDrop (filesDropped)="handleDrop($event)" (filesHovered)="handleHover($event)"
             [class.hovered]="dropzoneHovered">
            <span>Déposez vos fichiers dans la zone</span>
            <div class="file-list">
                <div *ngFor="let file of filesList" class="file-container">
          <span class="file-delete-button" *ngIf="messageEnvoi === 'Envoyer'">
            <a [routerLink]="[]" (click)="removeFile(file.name)">Supprimer</a>
          </span>
                    <div class="file-name">{{file.name}}</div>
                </div>
            </div>
        </div>
        <!--<input name="newMedia" type="file" (change)="onFileChange($event)">-->
        <div id="media-search-block">
            <div class="search-bar">
                <input type="search" [value]="searchText" [formControl]="searchTextControl" placeholder="Recherchez une image" />
            </div>
            <div class="previousandtext">
                <img class="img-responsive" src="assets/imgs/picto-fleche-gauche.png" (click)="changeDirectory(previousDirectory);" *ngIf="previousDirectory"/>
                <p  *ngIf="messageChargement" npUiTranslate="_Msg_ChargementAjax">Chargement</p>
                <p  *ngIf="messageEmpty">{{ directoryName }} est vide .</p>
            </div>
            <ul class="list-unstyled search-results">
                <li *ngFor="let mediaDirectory of mediasDirectory" class="search-result">
          <span (click)="changeDirectory(mediaDirectory.rootDirectory)" [title]="mediaDirectory.name" class="item-wrapper">
            <span class="img-wrapper"><img class="img-responsive" src="assets/imgs/folder.png" [alt]="mediaDirectory.name" /></span>
            <span class="img-name">{{mediaDirectory.name}}</span>
          </span>
                </li>
                <li *ngFor="let media of medias" class="search-result">
          <span (click)="selectMedia(media)" [title]="media.alt" class="item-wrapper">
            <span class="img-wrapper"><img class="img-responsive" [src]="media.thumb" /></span>
            <span class="img-name">{{media.alt}}</span>
          </span>
                </li>
            </ul>
        </div>
    </div>
</mat-dialog-content>
