import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Sheet, ThemingService, ValueComponentResolver} from '../../../services';
import {
  Access,
  CaracConfig,
  DicocaracRepository,
  ManagementRulesCheckerService,
  NPCaracValeur,
  NPElement,
  TypeCode
} from '@nextpage/np-sdk-data';
import {ComponentClass} from './component-class';
import {CaracIdStateValue, CaracInfo} from '../Model';
import {ShowConditionHelper} from '../../../helpers/show-condition-helper';
import jss from 'jss';

@Component({
  selector: 'lib-app-value-view',
  templateUrl: './np-value-view.component.html',
  styleUrls: ['./np-value-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
/**
 * Composant "violet"
 * Permet de choisir le bon composant d'affichage à partir de NPCaracValeur et du contexte (droite / lecture écriture")
 */
export class NPValueViewComponent implements OnInit, OnChanges {

  @Input() caracIdStateValue: CaracIdStateValue;
  @Input() element?: NPElement;
  @Input() customDatas: any;

  // paramètre permettant d'afficher ou non les différentes valeurs (d'un choix multiple)
  @Input() enableMultiValues = false;


  /**
   * DicoCarac + Autorisation
   */
  caracInfo: CaracInfo;
  /**
   * Classe/type du component à utiliser grâce à la directive ndc-dynamic
   */
  componentClass: ComponentClass;
  /**
   * Data à passer au composant d'affichage
   */
  componentInputs: {
    caracInfo: CaracInfo,
    value: NPCaracValeur,
    caracConfig: CaracConfig,
    element?: NPElement,
    unite?: string,
    customDatas?: any,
    baseElement: NPElement // permet de conserver l'élément de base lorsque la config contient des liens
  };

  public label: string;
  public printLabel = true;

  @Input() noLabel = false;

  public required = false;

  public classes: Object;

  constructor(private _dicoCaracRepository: DicocaracRepository,
              private _valueComponentResolver: ValueComponentResolver,
              private _ManagementRulesChecker: ManagementRulesCheckerService,
              private _theming: ThemingService) {
  }

  ngOnInit() {
    this._initLabel();
    // [ngClass]="classes && classes['title'] ? classes['title']: ''"
    const override_css = this._theming.getComponentConfigStyle('NPValueViewComponent');
    if (override_css !== undefined && override_css !== '') {
      const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
      this.classes = sheet.classes;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.caracIdStateValue == null) {
      return;
    }
    const changesCISV = changes.caracIdStateValue;
    this.caracIdStateValue = changesCISV.currentValue;
    const previousCISV = changesCISV.previousValue;
    /**
     * changes.currentValue: NPCaracValeur
     * changes.previousValue: NPCaracValeur
     */
    if (changesCISV.firstChange || this.componentClass == null || (this.caracIdStateValue.value !== undefined && previousCISV !== undefined && previousCISV.value &&
      this.caracIdStateValue.value != null && this.caracIdStateValue.value.DicoCaracExtID !== previousCISV.value.DicoCaracExtID)
    ) {
      this._updateComponent();
    }

    this.componentInputs = {
      caracInfo: this.caracInfo,
      value: this.caracIdStateValue ? this.caracIdStateValue.value : null,
      caracConfig: this.caracIdStateValue ? this.caracIdStateValue.config : null,
      element: this.element,
      customDatas: this.customDatas,
      baseElement: this.caracIdStateValue ? this.caracIdStateValue.baseElement : null
    };

    this._initLabel();

    if (this.caracIdStateValue) {
      this.required = this._ManagementRulesChecker.isRequired(this.caracIdStateValue.config.DicoCarac);
    }
  }

  public isShowing(caracIDStateValue: CaracIdStateValue): boolean {
    return ShowConditionHelper.IsShowConditionFulfilled(caracIDStateValue);
  }

  /**
   * Récupère l'unité de la Carac
   */
  getUnite() {
    if (this.caracIdStateValue == null || this.caracIdStateValue.config == null) {
      return '';
    }

    if (this.caracIdStateValue == null) {
      return;
    }

    const dicoCarac = this.caracIdStateValue.config.DicoCarac;
    if (!dicoCarac) {
      return '';
    }
    return dicoCarac.Unite;
  }

  private _initLabel() {
    this.label = '$$' + this.caracIdStateValue.config.DicoCaracExtIDLabel;
    this._dicoCaracRepository.getDicoCarac(this.caracIdStateValue.config.DicoCaracExtIDLabel).subscribe(
      next => {
        if (next != null) {
          this.label = next.Name;
        }
      },
      error => {
        console.log('Il y a eu une erreur lors de la récupération de DicoCarac', error);
      }
    );
    this.printLabel = (this.caracIdStateValue.config.PrintLabel != null) ? this.caracIdStateValue.config.PrintLabel : true;
  }

  private _updateComponent() {
    if (this.caracIdStateValue !== undefined && this.isShowing(this.caracIdStateValue)) {
      const config = this.caracIdStateValue.config;
      const editable = (config != null && config.DicoCarac != null) ? (config.DicoCarac.Editable === '1') : true;
      const access: Access = (config != null && config.DicoCarac != null) ? config.DicoCarac.Access : 2;
      const authorization: Access = (editable) ? access : 2;
      let type: TypeCode = TypeCode.TXT;
      if (config != null && config.DicoCarac != null) {
        type = TypeCode[config.DicoCarac.TypeCode];
        // Gérer le cas de DC_PHOTOS pour qui on utilise np-value-image
        if (config.DicoCarac.Destination != null && config.DicoCarac.Destination.split(';').indexOf('202') > -1) {
          type = TypeCode.IMG;
        }
      }

      /**
       * On attends name: '##ProductLabel', type: TypeCode.TXT, authorization: Access.LECTURESEULE
       */
      let configComponent: any;
      if (config != null) {
        configComponent = config.Component;
      }
      this.caracInfo = {
        authorization: authorization,
        type: type,
        name: (config != null && config.DicoCaracExtIDLabel != null) ? config.DicoCaracExtIDLabel : '',
        component: configComponent,
        componentRouting: config.ComponentRouting,
        unite: this.getUnite(),
        blocName: config.Bloc,
        enableMultiValues: this.enableMultiValues
      };

      // S'il y a un forcedState alors il prend le dessus sur le droit sur la Carac
      if (config != null && Number(config.ForcedState) >= 0) {
        this.caracInfo.authorization = Number(config.ForcedState);
      }

      this.componentClass = this._valueComponentResolver.getComponentClass(this.caracInfo);
    }
  }
}
