import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogPageDeletionComponent
} from 'src/app/graphics/components/publication/dialog-page-deletion/dialog-page-deletion.component';
import {
  DialogPagesAdditionComponent
} from 'src/app/graphics/components/publication/dialog-pages-addition/dialog-pages-addition.component';
import { CatalogueTemplateSelectionOverlayComponent } from '@view/publication';
import {
  CatalogueCoverEditionOverlayComponent
} from '../catalogue-cover-edition-overlay/catalogue-cover-edition-overlay.component';
import {
  CatalogueEditoEditionOverlayComponent
} from '../catalogue-edito-edition-overlay/catalogue-edito-edition-overlay.component';
import { CatalogPage, Chapter } from '@app/models';
import { TemplatesNames, TemplatesTags } from '@data/enums';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UpdateChapter } from '@store/publication';

@Component({
  selector: 'app-catalogue-page-title',
  templateUrl: './catalogue-page-title.component.html',
  styleUrls: ['./catalogue-page-title.component.scss']
})
export class CataloguePageTitleComponent {

  @ViewChild('catalogueCoverEditionOverlayComponent') catalogueCoverEditionOverlayComponent: CatalogueCoverEditionOverlayComponent;
  @ViewChild('catalogueEditoEditionOverlayComponent') catalogueEditoEditionOverlayComponent: CatalogueEditoEditionOverlayComponent;
  @ViewChild('catalogueTemplateSelectionOverlayComponent') catalogueTemplateSelectionOverlayComponent: CatalogueTemplateSelectionOverlayComponent;

  @Input() isEditable: boolean = false;
  @Input() chapter: Chapter;
  @Input() page: CatalogPage;

  public productPageTypeEnum = TemplatesNames.PRODUCT;

  private _confirmPageDeletionSub: Subscription;

  constructor(
    public dialog: MatDialog,
    private _store: Store
  ) {
  }

  public editPage(event: Event, page: CatalogPage): void {
    switch (page.type) {
      case TemplatesNames.COVER:
        this.catalogueCoverEditionOverlayComponent.toggle(event);
        break;
      case TemplatesNames.EDITO:
        this.catalogueEditoEditionOverlayComponent.toggle(event);
        break;
      default:
        this.catalogueTemplateSelectionOverlayComponent.toggle(event);
    }
  }

  public confirmPageDeletion(): void {
    this._confirmPageDeletionSub = this.dialog.open(DialogPageDeletionComponent, {
      panelClass: 'page-deletion-dialog'
    }).afterClosed()
      .subscribe(confirmDeletion => {
        if (confirmDeletion
          && this.chapter?.pages
          && this.page) {
          _.remove(this.chapter.pages, this.page);

          _.remove(this.chapter.pages, (page: CatalogPage) => page.name.includes(TemplatesTags.NOTE));
          
          this._store.dispatch(UpdateChapter({chapter: this.chapter}));
        }
      });
  }

  public openPagesAddition(): void {
    this.dialog.open(DialogPagesAdditionComponent, {
      panelClass: 'add-pages-dialog',
      data: { chapter: this.chapter, page: this.page }
    });
  }

  ngOnDestroy(): void {
    this._confirmPageDeletionSub?.unsubscribe();
  }
}
