import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {NPCaracLienRebuildValue, NPElement} from '../model';

interface BioRecette {
    element: NPCaracLienRebuildValue; // Le produit qui contient les ingrédients
    current: boolean; // Si recette rattachée directement au produit en cours
}

@Injectable({
    providedIn: 'root'
})
export class StructuredDataService {

    public subChanges: BehaviorSubject<StructuredDataChanges> = new BehaviorSubject<StructuredDataChanges>(null);
    public subFilterValue: BehaviorSubject<StructuredDataFilter> = new BehaviorSubject<StructuredDataFilter>(null);
    public subFilterValueResearch: BehaviorSubject<FilterValue> = new BehaviorSubject<FilterValue>(null);
    public recettes: BioRecette[] = [];
    private _mapRecettes: Map<string, NPCaracLienRebuildValue[]> = new Map<string, NPCaracLienRebuildValue[]>();

    constructor() {
    }

    /**
     * Ajoute une recette
     */
    public addRecette(key: string, rebuildValues: NPCaracLienRebuildValue[]) {
        this._mapRecettes.set(key, rebuildValues);
    }

    public getRecetteByElementExtId(elementExtId: string): NPCaracLienRebuildValue[] {
        return this._mapRecettes.get(elementExtId);
    }

    getSelectChanges(): Observable<StructuredDataChanges> {
        return this.subChanges.asObservable();
    }

    setSelectChanges(structuredDataChanges: StructuredDataChanges) {
        this.subChanges.next(structuredDataChanges);
    }

    filterSucturedDataList(element: NPElement, dicoCaracExtID: string) {
        this.subFilterValue.next({element, dicoCaracExtID});
    }

    getListenedCaracValueForFilter(listenedCaracExtID: string) {
        return this.subFilterValueResearch.asObservable()
            .pipe(
                filter(data => data && data.caracKey === listenedCaracExtID)
            );
    }

    setFilterValueResearch(caracKey: string, researchValue: string) {
        this.subFilterValueResearch.next({caracKey: caracKey, value: researchValue});
    }

    getSelectedValueChangeByCaracExtID(caracExtID: string): Observable<NPElement> {
        return this.subFilterValue.asObservable().pipe(
            filter(data => data && data.dicoCaracExtID === caracExtID),
            map(data => data ? data.element : null)
        );
    }
}

export interface StructuredDataChanges {
    elementSD: NPElement;
    elementLinked: NPElement;
    dicoCaracExtID: string;
}

interface StructuredDataFilter {
    element: NPElement;
    dicoCaracExtID: string;
}

interface FilterValue {
    caracKey: string;
    value: string;
}
