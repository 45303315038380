import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {NpLanguageDto} from 'src/lib/data/model/np-language-dto';
import {LocalStorageService} from '../../services/local-storage.service';
import {selectDataLanguages} from '@store/data-languages';
import {Router} from '@angular/router';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  public readonly dataLanguageView$ = this.store.select(selectDataLanguages)
      .pipe(tap(result => this._selectedLanguage = result.selectedDataLanguage));

  public _selectedLanguage: NpLanguageDto;

  constructor(
      private _localStorageService: LocalStorageService,
      private store: Store,
      public router: Router
  ) {
  }

  public changeDataLanguage(dataLanguage: NpLanguageDto) {
    if (this._selectedLanguage?.ID !== dataLanguage.ID) {
      this._localStorageService.saveDataLanguage(dataLanguage);

      // TODO: A remplacer par l'évolution de Laureline
      location.reload();
    }
  }

}
