import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { OverlayPanel } from "primeng/overlaypanel";
import { PublicationChaptersActions, selectPublicationChaptersView, PublicationChaptersState, EditCatalog } from "@store/publication";
import { selectLoadingAuthentication } from "src/app/store/selector";
import { Catalog, CatalogConfig } from "@app/models";
import * as _ from "lodash";
import { tap } from "rxjs/operators";
import { NPElement } from "@lib/models";
import { PublicationHelper } from "src/app/data/helpers/publication.helper";

@Component({
    selector: 'app-catalogue-edition-overlay',
    templateUrl: './catalogue-edition-overlay.component.html',
    styleUrls: ['./catalogue-edition-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CatalogueEditionOverlayComponent implements OnInit {
    @ViewChild('op') overlayPanel: OverlayPanel;

    @Input() catalog: Catalog;
    @Input() catalogId: number;

    public chaptersView$;

    public loading$ = this._store.select(selectLoadingAuthentication); //TODO new store?
    public catalogForm: FormGroup;
    public chaptersIncludedInCatalog = new Map<number, boolean>();

    private _chapters: NPElement[];

    constructor(
        private _fb: FormBuilder,
        private _store: Store) {
    }

    public ngOnInit(): void {
        this._store.dispatch(PublicationChaptersActions.fetchPublicationChapters());
    }

    public toggle(event: Event): void {
        this.overlayPanel.toggle(event);
        this._initForm();        
    }

    public closeOverlay(): void {
        this.overlayPanel.hide();
    }

    public editCatalogue(): void {
        this.catalog.name = this.catalogForm.controls.catalogName.value;
        this.catalog.description = this.catalogForm.controls.catalogDescription.value;
        const checkedChapters = this._chapters?.filter(
            chapter => this.catalogForm.controls[chapter.ID].value).map(chapter => chapter.ExtID);

        const catalogConfig = new CatalogConfig();
        catalogConfig.catalogId = this.catalogId;
        catalogConfig.structure = PublicationHelper.removeFirstBlankPage(this.catalog) as Catalog;
        catalogConfig.selectedChapters = checkedChapters;

        this._store.dispatch(EditCatalog({catalogConfig}));

        this.closeOverlay();
    }

    private _initForm(): void {
        this.catalogForm = this._fb.group({
            catalogName: [this.catalog?.name, Validators.required],
            catalogDescription: [this.catalog?.description],
            chapters: FormArray<FormControl>
        });

        this.chaptersView$ = this._store.select(selectPublicationChaptersView(this.catalog?.chapters))
        .pipe(
            tap((chaptersState: PublicationChaptersState) => {
                this._chapters = chaptersState.chapters;

                chaptersState.chaptersWithoutIntroAndLast?.forEach(chapter => {
                    this.catalogForm.addControl(`${chapter.channelCategoryID}`, new FormControl(true));
                });

                chaptersState.remainingChapters?.forEach(chapter => {
                    this.catalogForm.addControl(`${chapter.channelCategoryID}`, new FormControl(false));
                });
            })
        );
    }
}
