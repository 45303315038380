import {createAction, props} from '@ngrx/store';

export const InitDataTable = createAction(
    '[advanced-search-params] Fetch Data'
);

export const UpdateScope = createAction(
    '[advanced-search-params] Update Scope',
    props<{ scopeId: number, isChannel: boolean }>()
);

export const FetchAdvancedSearchParamsSuccess = createAction(
    '[advanced-search-params] Fetch Success',
    props<{ scopeId: number, isChannel: boolean }>()
);

export const UpdateScopeSuccess = createAction(
    '[advanced-search-params] Update Success',
    props<{ scopeId: number, isChannel: boolean }>()
);

export const FetchAdvancedSearchParamsFailure = createAction(
    '[advanced-search-params] Fetch Success Failure',
    props<{ message: string }>());
