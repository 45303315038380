<ng-container *ngIf="configurationView">
    <div [ngClass]="customClass$ | async">
        <div mat-dialog-content class="dialog-content">
            <p> {{ 'products.about-export-data' | translate }} </p>
            <ng-container>
                <mat-slide-toggle
                        [(ngModel)]="configurationView.mediasParams.checked"
                        color="primary"
                        tabIndex="-1">{{ 'products.include-media' | translate }}
                </mat-slide-toggle>

                <mat-expansion-panel [expanded]="configurationView.mediasParams.checked" hideToggle class="mat-elevation-z0">
                    <ul>
                        <li *ngFor="let parameter of configurationView.mediasParams.parameters">
                            <mat-checkbox [(ngModel)]="parameter.checked" color="primary">
                                {{parameter.label}}
                            </mat-checkbox>
                        </li>
                    </ul>
                </mat-expansion-panel>
            </ng-container>

            <mat-slide-toggle
                        #customEmail
                        color="primary"
                        tabIndex="-1">{{ 'products.send-specific-recipient' | translate }}
            </mat-slide-toggle>

            <mat-expansion-panel [expanded]="customEmail.checked" hideToggle class="mat-elevation-z0">
                <mat-form-field appearance='outline' class="w-100 mt-2 email-field">
                    <mat-label>{{ 'common.fields.email' | translate }}</mat-label>
                    <input type='email' matInput [formControl]="emailFormControl"/>
                    <mat-error *ngIf="emailFormControl.invalid">{{'products.invalid-email' | translate}}</mat-error>
                </mat-form-field>
            </mat-expansion-panel>
        </div>

        <mat-dialog-actions>
            <button mat-button [mat-dialog-close]="null">{{ 'common.cancel' | translate }}</button>
            <button class='buttonConnection'
                    color='primary'
                    mat-raised-button
                    [mat-dialog-close]="configurationView"
                    [disabled]="customEmail.checked && emailFormControl.invalid"
                    [title]="'products.email-send' | translate">{{ 'products.export' | translate }}
            </button>
        </mat-dialog-actions>
    </div>

</ng-container>

