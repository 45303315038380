import {createFeatureSelector, createSelector} from '@ngrx/store';
import {publicationTemplateFeatureKey, PublicationTemplateState} from '@store/publication';

const selectPublicationTemplateState = createFeatureSelector<PublicationTemplateState>(
    publicationTemplateFeatureKey
);

export const selectPublicationTemplateView = createSelector(
    selectPublicationTemplateState, (state) => state
);
