import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {LocalStorageService} from 'src/app/graphics/services/local-storage.service';
import {NpLanguageDto} from 'src/lib/data/model/np-language-dto';
import {DataLanguageActions} from './data-language.action';
import {combineLatest, of} from 'rxjs';
import {NpLanguageService} from '../../../lib/data/services/np-language.service';
import {NpUserService} from '@nextpage/np-sdk-data';
import { NpCurrentProfil } from 'src/lib/data/model/np-current-profil';

@Injectable()
export class DataLanguageEffect {
    constructor(
        private actions$: Actions,
        private _languageService: NpLanguageService,
        private _npUserService: NpUserService,
        private _localStorageService: LocalStorageService,
    ) {
    }

    fetchDataLang$ = createEffect(() => this.actions$.pipe(
        ofType(DataLanguageActions.fetchDataLanguages),
        switchMap(() => {
            return combineLatest([
                this._languageService.getWithWritePermissions()
                    .pipe(map(languages => languages?.filter((language: NpLanguageDto) => language.EstData))),
                this._npUserService.getCurrentProfile()
                    .pipe(map((result: NpCurrentProfil) => result.LangID))
            ])
                .pipe(
                    map(([dataLanguages, selectedDataLangId]) => {
                        const _oldSelectedLanguage = this._localStorageService.getDataLanguage();
                        const _selectedLanguage = _oldSelectedLanguage ?? dataLanguages.find(item => item.ID === selectedDataLangId);
                        const _next = DataLanguageActions.fetchDataLanguagesSuccess({
                            params: {
                                dataLanguages,
                                selectedDataLanguage: _selectedLanguage
                            }
                        });

                        this._localStorageService.saveDataLanguage(_selectedLanguage);

                        return _next;
                    })
                );
        })
    ));
}
