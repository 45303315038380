import {NPElementType, NPElementTypeName} from '@nextpage/np-sdk-data';

export const elementTypeNamesByCode = {
    [NPElementType.Root]: NPElementTypeName.Root,
    [NPElementType.Family]: NPElementTypeName.Family,
    [NPElementType.Product]: NPElementTypeName.Product,
    [NPElementType.Reference]: NPElementTypeName.Reference,
    [NPElementType.DicoCharacteristic]: NPElementTypeName.DicoCharacteristic,
    [NPElementType.Notification]: NPElementTypeName.Notification,
    [NPElementType.ChannelDirectory]: NPElementTypeName.ChannelDirectory,
    [NPElementType.Channel]: NPElementTypeName.Channel,
    [NPElementType.ChannelNode]: NPElementTypeName.ChannelNode,
    [NPElementType.MediaDirectory]: NPElementTypeName.MediaDirectory,
    [NPElementType.Media]: NPElementTypeName.Media,
};

