import {createFeatureSelector, createSelector} from '@ngrx/store';
import { CatalogPage } from '@app/models';
import { catalogFeatureKey, CatalogState } from './catalog.reducer';
import * as _ from 'lodash';

const selectCatalogState = createFeatureSelector<CatalogState>(
    catalogFeatureKey
);

export const selectCatalogView = createSelector(
    selectCatalogState, (state) => {
        const chapters = state.catalog?.chapters?.map(chapter => {
            if (chapter.isIntroductionChapter && !_.isEmpty(chapter.pages[0].name)) {
                const blankPage = new CatalogPage({
                    name: '',
                    description: ''
                });

                return {
                    ...chapter,
                    pages: [blankPage, ...(chapter.pages || [])]
                };
            }

            return chapter;
        });

        return {
            ...state,
            catalog: {
                ...state.catalog,
                chapters: chapters
            }
        };
    }
);