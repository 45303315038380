import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import {saveAs} from 'file-saver';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { PhotosInfos } from '../../np-value/Model';
import { CaracConfig, DicoCarac, NPCaracLien, NPCaracLienRebuildValue, NPCaracValeur, NPElementType } from 'src/lib/data/model';
import { ElementWriterService, NpDownloadService } from 'src/lib/data/services';
import { FileReaderService, MediaLibraryService } from 'src/lib/services';

@Component({
    selector: 'lib-visual-media-gallery',
    templateUrl: './visual-media-gallery.component.html',
    styleUrls: ['./visual-media-gallery.component.scss']
})
export class VisualMediaGalleryComponent implements OnInit {

    public photoUrls: PhotosInfos[] = [];
    public currentPhoto: PhotosInfos;
    private _value: NPCaracLien;
    private _caracConfig: CaracConfig;
    private _caracValue: NPCaracLien;
    private _cardinality: number;
    private canAddMore: boolean;
    public dialogTitle: string;
    private _isImage: boolean;
    public currentMedia: NPCaracValeur;

    constructor(
        private _dialogRef: MatDialogRef<VisualMediaGalleryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _elementWriter: ElementWriterService,
        private _fileReader: FileReaderService,
        @Inject(forwardRef(() => MediaLibraryService)) private _mediaLibraryService: MediaLibraryService,
        private _downloadService: NpDownloadService
    ) {
    }

    ngOnInit() {
        this._initData();
    }

    private _initData() {
        if (this.data && this.data.photos && this.data.value && this.data.caracConfig) {
            this._isImage = this.data.isImage;
            if (this._isImage) {
              const _tmpPhoto = new PhotosInfos();
                _tmpPhoto.src = this._fileReader.toUrlCustomImageFromPath(this.data.photos.Value);
                _tmpPhoto.thumbSrc = this._fileReader.toUrlCustomImageThumbFromPath(this.data.photos.Value);
                _tmpPhoto.alt = this.data.photos.Value;
                this.photoUrls.push(_tmpPhoto);
            } else {
                this.photoUrls = this.data.photos;
            }
            this.currentPhoto = this.data.currentPhoto ? this.data.currentPhoto : this.photoUrls.length > 0 ? this.photoUrls[0] : undefined;
            this._value = this.data.value;
            this._caracValue = this._value.Element.getValueLien(this._value.DicoCaracExtID);
            this._caracConfig = this.data.caracConfig;

            const elementLabelCarac = this._value.Element.ElementType === NPElementType.Product ? DicoCarac.PRODUCT_LABEL :
                this._value.Element.ElementType === NPElementType.Reference ? DicoCarac.REFERENCE_LABEL : DicoCarac.FAMILY_LABEL;
            const elementLabel = this._value.Element.getValueTextValue(elementLabelCarac);
            this.dialogTitle = elementLabel === '' ? this._caracConfig.Label :
                `${elementLabel} - ${this._caracConfig.Label}`;


        }
    }

    circleSelected(selectedPhoto: PhotosInfos) {
        this.currentPhoto = selectedPhoto;
    }

    close(value = 0) {
        this._dialogRef.close(value);
    }

  downloadPicture() {
    if (this._isImage) {
        this._downloadService.downloadFile(this.currentPhoto.src, this.currentPhoto.alt).subscribe();
    }
  }

    changePicture(picture: PhotosInfos) {
        const mediaToChange: NPCaracLienRebuildValue = !this._isImage ? this._getLinkedMedia(picture) : undefined;
        this._mediaLibraryService.open(this._value.DicoCaracExtID, mediaToChange ? mediaToChange.Element.ID : undefined)
            .subscribe((result) => {
                if (this._isImage) {
                    const _tmpCV = result.getValue(DicoCarac.MEDIA_FILE);
                    if (_tmpCV) {
                        const _tmpPhoto = new PhotosInfos();
                        _tmpPhoto.src = this._fileReader.toUrlCustomImageFromPath(_tmpCV.Value);
                        _tmpPhoto.thumbSrc = this._fileReader.toUrlCustomImageThumbFromPath(_tmpCV.Value);
                        this._elementWriter.setValueText(this._value.Element, this._value.DicoCaracExtID, _tmpCV.Value);
                        this.currentPhoto = _tmpPhoto;
                    }
                } else {
                    this._elementWriter.changeValueLink(this._value.Element, this._value.DicoCaracExtID, result, mediaToChange.Order);
                    this._buildState(true);
                }
            });
    }

    private _getLinkedMedia(picture: PhotosInfos) {
        const value = this._caracValue.RebuildLinkedElements.filter((val: NPCaracLienRebuildValue) => {
            return picture.order === val.Order;
        });
        if (value.length === 1) {
            return value[0];
        }
        return null;
    }

    deletePicture(picture: PhotosInfos) {
        if (this._isImage) {
            this._elementWriter.setValueText(this._value.Element, this._value.DicoCaracExtID, '');
            this.close(-1);
        } else {
            const mediaToDelete: NPCaracLienRebuildValue = this._getLinkedMedia(picture);
            this._elementWriter.deleteValueLink(this._value.Element, this._value.DicoCaracExtID, mediaToDelete.Element);
            this._buildState();
        }
    }

    private _buildState(hasBeenChanged = false) {
        this._caracValue = this._value.Element.getValueLien(this._value.DicoCaracExtID);
        this.photoUrls = [];
        this._cardinality = this._caracConfig.DicoCarac.Cardinality;
        if (this._cardinality == null) {
            this._cardinality = 9999;
        }
        if (this._caracValue.RebuildLinkedElements) {
            this._caracValue.RebuildLinkedElements.forEach(link => {
                if (this._cardinality == null || this._cardinality > this.photoUrls.length) {
                    const filePath = this._fileReader.toUrlThumbImage(link.Element);
                    const file = link.Element.getValueTextValue(DicoCarac.MEDIA_FILE);
                    const fileExploded = file.replace(new RegExp(/\\/i, 'g'), '/').split('/');
                    const fileName = fileExploded[fileExploded.length - 1];

                    this.photoUrls.push(new PhotosInfos(filePath, this._fileReader.toUrlThumbImage(link.Element), fileName, link.Order));
                }
            });
            this.canAddMore = this._cardinality > this.photoUrls.length;
            this.photoUrls = this.photoUrls.sort((a, b) => {
                return a.order - b.order;
            });
            if (this.photoUrls.length > 0) {
                this.currentPhoto = hasBeenChanged ?
                    this.photoUrls.find(photo => photo.order === this.currentPhoto.order) : this.photoUrls[0];
            } else {
                this.currentPhoto = undefined;
                this.close();
            }
        }
    }

    isSelected(photo: PhotosInfos) {
        return photo.order === this.currentPhoto.order;
    }
}
