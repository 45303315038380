import * as _ from "lodash";
import { CatalogConfig, Catalog, CatalogPage, Chapter, PageTemplate, PublicationTemplate, TemplateDescription } from "@app/models";
import { TemplatesTags } from "../enums/publication-tags.enum";
import { Store } from "@ngrx/store";
import { PUBLICATION_DEFAULT_COVER_TITLE_MAX_LENGTH } from "@data/constants";
import { EditPage } from "src/app/store/publication";

export class PublicationHelper {
    public static removeFirstBlankPage(catalog: CatalogConfig | Catalog) {
        const catalogStructure: Catalog = catalog instanceof CatalogConfig ? catalog?.structure : catalog;
        const introductionChapter = catalogStructure?.chapters?.find(chapter => chapter.isIntroductionChapter);

        if (introductionChapter?.pages?.length > 0 && _.isEmpty(introductionChapter?.pages[0].name)) {
            introductionChapter.pages.shift();
        }

        return catalog;
    }

    public static updateCatalogPage(formTitle: string, formTemplateId: number, updatedPage: CatalogPage, templates: PublicationTemplate[], isIntroPage: boolean) {
        if(updatedPage) {
            if(isIntroPage) {
                updatedPage.description = formTitle;
            } else {
                updatedPage.name = `${TemplatesTags.PRODUCT} - ${formTitle}`;
                updatedPage.nameWithoutTag = formTitle;
            }

            const selectedPublicationTemplate = templates.find(template => template.id === formTemplateId);
            if (selectedPublicationTemplate) {
                let descriptionJSONParsed: TemplateDescription  | null = null;
                if(isIntroPage) {
                    if (selectedPublicationTemplate.description) {
                        try {
                            descriptionJSONParsed = JSON.parse(selectedPublicationTemplate.description);
                        } catch (e) {}
                    }
                }

                updatedPage.setTemplate(
                    new PageTemplate({
                        id: selectedPublicationTemplate.id,
                        path: selectedPublicationTemplate.jpgFile,
                        completePath: selectedPublicationTemplate.jpgFile,
                        numberOfElements: selectedPublicationTemplate.numberOfElements,
                        maxCharactersLength: descriptionJSONParsed?.MaxCharactersLength || PUBLICATION_DEFAULT_COVER_TITLE_MAX_LENGTH
                    })
                );
            }
        }
    }

    public static transformPath(page: CatalogPage, imageTemplateByDefault: string): boolean {
        const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
        return page?.template?.path
            && !_.isEqual(page.template.path, imageTemplateByDefault)
            && _.isEqual(page.template.path, page.template.completePath)
            && !urlRegex.test(page.template.completePath);
    }
}