import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {CaracConfig, NPCaracLien, StructuredDataService} from '@nextpage/np-sdk-data';
import {CaracInfo} from '../np-value/Model';
import {TreeviewItem, TreeviewService} from '../../services';

@Component({
    selector: 'lib-np-treeview',
    templateUrl: './np-treeview.component.html',
    styleUrls: ['./np-treeview.component.css']
})
export class NpTreeviewComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public ingredientTreeview: TreeviewItem[] = [];
    private _initialIngredientTreeview: TreeviewItem[] = [];

    constructor(@Inject(forwardRef(() => TreeviewService)) private _treeviewService: TreeviewService,
                private _structuredDataService: StructuredDataService) {
    }

    ngOnInit() {

        this._initData();

        /**
         * Filtre les données de Treewiew en fonction du texte saisi dans la zonne de recherche
         * se trouvant dans la zone du titre bleu
         */
        this._structuredDataService.getListenedCaracValueForFilter(this.caracConfig.DicoCaracExtID)
            .subscribe(researchValue => {
                this.ingredientTreeview = researchValue && researchValue.value !== '' ?
                    this._initialIngredientTreeview.filter(item => item.title.toUpperCase().indexOf(researchValue.value.toUpperCase()) !== -1) :
                    this._initialIngredientTreeview;
            });
    }

    private _initData() {
        this._treeviewService.setRebuildLinkedElements(this.value.RebuildLinkedElements, this.caracConfig);
        this.ingredientTreeview = this._treeviewService.getTreviewData();
        this._initialIngredientTreeview = Object.assign([], this.ingredientTreeview);
    }
}
