<ul class="d-flex flex-column w-auto list-unstyled products-list">
  <ng-container *ngFor="let productWrapper of page?.productsToAdd">
    <li *ngIf="productWrapper.product && !productWrapper.product.name.includes('[MEMBER]')" class="d-flex justify-content-between w-100 products-list__item item-selected">
      <button (click)="selectProduct(productWrapper)">{{ productWrapper.product.name }}</button>
      <button (click)="confirmProductDeletion(productWrapper)"><fa-icon [icon]="['far', 'square-xmark']"></fa-icon></button>
    </li>
    <li *ngIf="!productWrapper.product" class="d-flex justify-content-between w-100 products-list__item no-item-selected">
      <button class="add-btn" (click)="selectProduct(productWrapper)">{{ 'publication.catalogue.add-product' | translate }}</button>
    </li>
  </ng-container>
</ul>
