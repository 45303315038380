<ng-container *ngIf="!isEditable; else edition">
    <!--Read-only-->
    {{page?.nameWithoutTag}}
</ng-container>

<!--Edition-->
<ng-template #edition>
    <button class="d-flex delete-btn" 
    *ngIf="page?.type === productPageTypeEnum">
        <fa-icon [icon]="['far', 'trash']" (click)="confirmPageDeletion()"></fa-icon>
    </button>
    <div class="d-flex align-items-start catalogue-right-actions">
        <button class="edit-btn" (click)="editPage($event, page)">
            {{page?.nameWithoutTag}}
            <fa-icon [icon]="['far', 'pen']"></fa-icon>
        </button>
        <button class="page-btn" (click)="openPagesAddition()"
        *ngIf="page?.type === productPageTypeEnum">
            <fa-icon [icon]="['far', 'file-plus']" ></fa-icon>
        </button>
    </div>
    <app-catalogue-cover-edition-overlay #catalogueCoverEditionOverlayComponent [page]="page"></app-catalogue-cover-edition-overlay>
    <app-catalogue-edito-edition-overlay #catalogueEditoEditionOverlayComponent [page]="page"></app-catalogue-edito-edition-overlay>
    <app-catalogue-template-selection-overlay #catalogueTemplateSelectionOverlayComponent 
    [chapter]="chapter" 
    [page]="page"></app-catalogue-template-selection-overlay>
</ng-template>
