import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Subscription} from 'rxjs';
import {SpDicoCarac} from 'src/app/data/constants';
import {
    EditPage,
    FetchPublicationTemplates,
    FetchPublicationTemplatesSuccess,
    PublicationTemplateState,
    selectPublicationTemplateView
} from '@store/publication';
import {selectLoadingAuthentication} from 'src/app/store/selector';
import {CatalogPage, PublicationTemplate, TemplateDescription} from '@app/models';
import {PUBLICATION_DEFAULT_EDITO_TITLE_MAX_LENGTH} from '@data/constants';
import {NpInstanceService} from '@data/services';
import {PublicationHelper} from 'src/app/data/helpers/publication.helper';

@Component({
    selector: 'app-catalogue-edito-edition-overlay',
    templateUrl: './catalogue-edito-edition-overlay.component.html',
    styleUrls: ['./catalogue-edito-edition-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CatalogueEditoEditionOverlayComponent implements OnInit, OnDestroy {
    @Input() page: CatalogPage;

    @ViewChild('op') overlayPanel: OverlayPanel;

    public loading$ = this._store.select(selectLoadingAuthentication); // TODO new store?
    public editoTemplatesView$ = this._store.select(selectPublicationTemplateView);

    public catalogEditoForm: FormGroup;
    public editoTextLength = 0;
    public editoTextMaxLength: number;

    private _imageTemplateByDefault = this._instanceService.getPublicationImageTemplateByDefault();
    private _editoTemplatesViewSub: Subscription;
    private _editoTemplates: PublicationTemplate[];

    constructor(
        private _fb: FormBuilder,
        private _store: Store,
        private _instanceService: NpInstanceService) {
    }

    public ngOnInit(): void {
        this.catalogEditoForm = this._fb.group({
            catalogEditoText: [this.page?.description],
            catalogEditoTemplate: ['', Validators.required]
        });
    }

    public getEditoTemplates(): void {
        this._editoTemplatesViewSub = this._store.select(selectPublicationTemplateView)
        .subscribe((templatesView: PublicationTemplateState) => {
            if (templatesView?.hasTemplates) {
                this._editoTemplates = templatesView.publicationTemplates;
                this.setDefaultEditoTemplate(templatesView);
            }
        });

        this._store.dispatch(FetchPublicationTemplates({ templateCharacteristicExtID: SpDicoCarac.CP_EXTID_MODELE_PAGE_EDITO }));
    }

    public displayDefaultImage(editoTemplate: PublicationTemplate): void {
        editoTemplate.jpgFile = this._imageTemplateByDefault;
    }

    public toggle(event: Event): void {
        this.overlayPanel.toggle(event);
        this.editoTextLength = this.page?.description?.length;
        this.editoTextMaxLength = this.page?.template?.maxCharactersLength || PUBLICATION_DEFAULT_EDITO_TITLE_MAX_LENGTH;
    }

    public closeOverlay(): void {
        this.overlayPanel.hide();
        this._store.dispatch(FetchPublicationTemplatesSuccess({ publicationTemplates: null }));
    }

    public editEdito(): void {
        this.closeOverlay();

        PublicationHelper.updateCatalogPage(
            this.catalogEditoForm.controls.catalogEditoText.value,
            this.catalogEditoForm.controls.catalogEditoTemplate.value,
            this.page,
            this._editoTemplates,
            true);

        this._store.dispatch(EditPage({page: this.page, isIntroPage: true}));
    }

    public onTextChange(value: string): void {
        this.editoTextLength = value.length;
    }

    public templateChange(editoTemplate: PublicationTemplate): void {
        let descriptionJSONParsed: TemplateDescription  | null = null;
        if (editoTemplate.description) {
            try {
                descriptionJSONParsed = JSON.parse(editoTemplate.description);
            } catch (e) {}
        }

        this.editoTextMaxLength = descriptionJSONParsed?.MaxCharactersLength || PUBLICATION_DEFAULT_EDITO_TITLE_MAX_LENGTH;
    }

    private setDefaultEditoTemplate(templatesView: PublicationTemplateState): void {
        const defaultEditoTemplate = this.page?.template || templatesView?.publicationTemplates[0];
        if (defaultEditoTemplate) {
            this.catalogEditoForm.patchValue({ catalogEditoTemplate: defaultEditoTemplate.id });
        }
    }

    ngOnDestroy(): void {
        this._editoTemplatesViewSub?.unsubscribe();
    }
}
