
<div class="d-flex w-100 justify-content-between align-items-center">
    <mat-card-header>
        <mat-card-title>{{product?.label}}</mat-card-title>
    </mat-card-header>
    <mat-card-actions>
        <button class="view-btn" mat-icon-button (click)="viewProductDetails(product, $event)">
            <fa-icon [icon]="['far', 'eye']"></fa-icon>
        </button>
    </mat-card-actions>
</div>
<mat-card-content class="d-flex align-items-start">
    <img mat-card-image [src]="product?.imageUrl ? product.imageUrl : imageByDefault$ | async " alt="{{product?.label}}" />
    <div class="content" *ngIf="productDescriptionValue">{{productDescriptionValue}}</div>
</mat-card-content>