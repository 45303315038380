<div class="wrapper" *ngIf="filteredCard.length > 0; else noCards">
  <div class="card" *ngFor="let card of filteredCard">
    <div class="d-flex justify-content-between align-items-center card__header">
      <ng-container *ngIf="isSupplierEntityName">
        <button mat-icon-button class="d-flex icon-more-info"
                [matTooltip]="'common.edit-information' | translate"
                (click)='editInfos(card, $event)'>
            <img ngSrc="./assets/icons/edit.svg" class="fontawesome-icon-custom icon-eye" alt="edit" height="16"
                width="16">
        </button>
      </ng-container>
      <button mat-icon-button class="favorite-btn" 
              [matTooltip]="((favoritesState$ | async | favorite:card) ? 'common.remove-favorites' : 'common.add-favorites') | translate"
              (click)='toggleFavorite(card, $event)'>
        <mat-icon [style.color]="(favoritesState$ | async | favorite:card) ? 'red' : ''">{{(favoritesState$ | async | favorite:card) ? 'favorite' : 'favorite_border'}}</mat-icon>
      </button>
    </div>

    <div class="card__body">
      <div class="card__body-cover">
        <img class="card__body-cover-image" [src]="card.imageUrl ? card.imageUrl : imageByDefault$ | async " alt="Product view" />
      </div>
      <div class="card__body-header">
        <p class="card__body-header-title" title="{{ card.label }}">{{ card.label | truncate: 50 }}</p>
        <div class="card__body-header-status np-tag"
          *ngIf="card.status"
          matTooltipPosition="above">
            <span class="status-color" [style.background]="card.status.Color"></span>
            <span class="status">{{ card.status.Libelle }}</span>
        </div>
      </div>
      <div>
        <button (click)="moreInfo(card); $event.stopPropagation()"
                class="button-dialog"
                mat-flat-button>
                {{ 'common.more-information' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #noCards>
  <div *ngIf="!loading" class="noCardsAvailable">{{ 'common.no-result' | translate }}</div>
</ng-template>
