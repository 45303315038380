import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NpUser} from '../model';

@Injectable({
    providedIn: 'root'
})
export class NpUserInfoService {

    private readonly _urlGetCurrentUser = '/api/sdk/user/getCurrentUser';

    constructor(private _http: HttpClient) {
    }

    public getCurrentUser(): Observable<NpUser[]> {
        return this._http.get<Object>(this._urlGetCurrentUser)
            .pipe(
                map((data) => {
                    if (data.hasOwnProperty('Results')) {
                        if (data['Results'] != null) {
                            return <NpUser[]>data['Results'].Users;
                        }
                    }
                })
            );
    }
}
