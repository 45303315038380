import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ElementExportState, elementsExportFeatureKey} from './elements-export.reducer';

const selectState = createFeatureSelector<ElementExportState>(
    elementsExportFeatureKey
);

export const selectExported = createSelector(
    selectState, (state) => state.exported
);

export const selectExportError = createSelector(
    selectState, (state) => state.hasError
);
