import {createReducer, on} from '@ngrx/store';
import {FetchMediaCharTemplatesFailure, FetchMediaTemplatesSuccess} from './media-char-template.actions';
import {CharTemplateDto} from '@app/models';

export const mediaCharTemplateFeatureKey = 'MediaCharTemplate';

export interface CharTemplateState {
    charTemplates: CharTemplateDto[];
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
}

export const initialCharTemplateState: CharTemplateState = {
    charTemplates: [],
    errorMessage: null,
    loading: true,
    hasError: false,
};

export const mediaCharTemplateReducer = createReducer(
    initialCharTemplateState,
    on(
        FetchMediaTemplatesSuccess,
        (state, {charTemplates}): CharTemplateState => {
            return ({
                ...state,
                charTemplates: charTemplates,
                loading: false,
                errorMessage: null,
                hasError: false
            });
        }
    ),
    on(FetchMediaCharTemplatesFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
