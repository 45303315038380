import {Component, forwardRef, Inject, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';

import {CaracInfo} from '../Model';
import {
    CaracConfig,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracStd,
    TypeCode,
    ValueOneElementHelper
} from '@nextpage/np-sdk-data';
import jss from 'jss';
import {Sheet, ThemingService} from '../../../services';

@Component({
    selector: 'lib-app-value-text-write-view',
    templateUrl: './np-value-text-write-view.component.html',
    styleUrls: ['./np-value-text-write-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueTextWriteViewComponent implements OnInit, OnChanges {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracStd;
    @Input() caracConfig: CaracConfig;

    public required = false;
    public displayRequiedError = false;
    public classes: Object;
    private _voe = new ValueOneElementHelper();
    public hasUnite = false;
    public isNotMaxLength = true;
    public stdValue: string;
    public placeholder = '';

    public maxLength = () => this.caracConfig.DicoCarac.MaxLength > 0 ? this.caracConfig.DicoCarac.MaxLength : -1; // - 1 : Taille illimité

    constructor(private _elementWriter: ElementWriterService,
                private _ManagementRulesChecker: ManagementRulesCheckerService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService) {
    }


    public onChange(newValue) {
        this._elementWriter.setValueText(this.value.Element, this.value.DicoCaracExtID, newValue);
        this._initRequiedError();
    }

    ngOnInit() {
        this.hasUnite = this._voe.hasUnite(this.caracConfig);
        this.stdValue = this.value.Value;

        // [ngClass]="classes && classes['title'] ? classes['title']: ''"
        const override_css = this._theming.getComponentConfigStyle('NpValueTextWriteViewComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }

        if (this.caracConfig && this.caracConfig.Specific && this.caracConfig.Specific.Placeholder) {
            this.placeholder = this.caracConfig.Specific.Placeholder;
        }
        /**
         * Lors de l'enregistrement de données, on vérifie si tous les champs obligaroires ont été renseignés
         */
        this._ManagementRulesChecker.isChecking()
            .subscribe(_isChecking => {
                if (_isChecking) {
                    this._initRequiedError();
                }
            });
    }

    public _initRequiedError() {
        this.displayRequiedError = !this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }

    ngOnChanges() {
    }

    showTextArea() {
        return this.caracInfo.type === TypeCode.TXTLONG;
    }

    showHtmlArea() {
        return this.caracConfig.DicoCarac.TypeCode === TypeCode.TXTHTML_HTML;
    }

    /**
     * Lorsque le champs perd le focus, on vérifie si le contenu du champs respecte bien la règle de gestion de la carac
     */
    onBlurCheck() {
        this._initRequiedError();
    }

    /**
     * Vérifie si la taille de la value saisie est supérieure à la taille max définie dans la carac en cours.
     * Si la taille max de la carac est égale à zero (traduite ici par -1), on considère qu'il n'y a pas de taille limite. Donc
     * aucune vérification ne sera faite
     */
    public checkMaxLength() {
        this.isNotMaxLength = this.maxLength() === -1 || this.stdValue.length < this.maxLength();
    }
}
