import { AdvancedSearchDTO } from "src/app/data/types";

export class AdvSearchArgs {
  public Type = 0;
  public AdvancedSearchID?: number;
  public Config?: AdvancedSearchDTO;
  public CurrentPage?: number;
  public PageSize?: number;
  public Paths: string[][];
  public DCExtIDs: string[];
  public LangID: number;
  public ContexteID = 1;
  public UILangCode = 'fr';
  public Facets: string[];
}
