import {createFeatureSelector, createSelector} from '@ngrx/store';
import {productCharTemplateFeatureKey} from './char-template.reducer';
import {CharTemplateState} from '../media-char-template';

const selectProductCharTemplateState = createFeatureSelector<CharTemplateState>(productCharTemplateFeatureKey);

export const selectProductCharTemplate = createSelector(
    selectProductCharTemplateState,
    state => state.charTemplates
);

