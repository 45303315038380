import {Component, Input, OnInit} from '@angular/core';
import {FileReaderService} from '../../services';
import {CarouselItem} from '@nextpage/np-sdk-data';

@Component({
    selector: 'lib-app-carousel',
    templateUrl: './np-carousel.component.html',
    styleUrls: ['./np-carousel.component.css']
})
export class NpCarouselComponent implements OnInit {
    @Input() items: CarouselItem[];

    public currentItem: CarouselItem = null;
    public currentIndex: number = 0;

    constructor(private fileReader: FileReaderService) {

    }

    ngOnInit() {

        if (this.items !== undefined && this.items != null && this.items.length > 0) {
            this.currentItem = this.items[this.currentIndex];
        } else {
            this.items = null;
        }
    }

    public getPrevious() {
        let indexToShow = this.currentIndex - 1;
        if (indexToShow < 0) {
            indexToShow = this.items.length - 1;
        }
        this.currentItem = this.items[indexToShow];
        this.currentIndex = indexToShow;
    }

    public getNext() {
        let indexToShow = this.currentIndex + 1;
        if (indexToShow > this.items.length - 1) {
            indexToShow = 0;
        }
        this.currentItem = this.items[indexToShow];
        this.currentIndex = indexToShow;
    }

    public getByIndex(index: number) {
        this.currentItem = this.items[index];
        this.currentIndex = index;
    }

    public openImage(index: number) {


    }

}
