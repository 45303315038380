<mat-form-field appearance='outline'>
    <mat-label>{{filtersCriteria.label}}</mat-label>
    <mat-select multiple
                [formControl]="formFilter.controls[filtersCriteria.DicoCaracID] | formControl"
                (openedChange)="onChoicesCriteriaDropdownOpened($event)"
                panelClass="filter-form-field__container">
        <mat-select-trigger>
            {{ selectedValues }}
        </mat-select-trigger>

        <mat-option>
            <ngx-mat-select-search
                    [formControl]="searchFormControl"
                    [clearSearchInput]="true"
                    [noEntriesFoundLabel]="'common.no-entries-foundLabel'| translate"
                    [placeholderLabel]="'common.search'| translate">
            </ngx-mat-select-search>
        </mat-option>

        <div class="options-list">
            <mat-option *ngFor="let facet of searchResultFacets" [value]="facet">
                {{facet.Value}} ({{facet.Count}})
            </mat-option>
        </div>

        <div class="d-flex filter__actions">

            <button mat-stroked-button
                    [disabled]="formFilter.controls[filtersCriteria.DicoCaracID].value.length == 0"
                    (click)="this.filterBySelectedFacets.emit(this.filtersCriteria)"
                    class="single-filter-btn">
                <mat-icon>filter_list</mat-icon>
                {{ 'products.filter' | translate }}
            </button>

            <button
                    [disabled]="formFilter.controls[filtersCriteria.DicoCaracID].value.length == 0"
                    (click)="resetFilter.emit(this.filtersCriteria.DicoCaracID)"
                    mat-stroked-button
                    class="single-reset-btn">
                <mat-icon>clear</mat-icon>
                {{ 'products.reset' | translate }}
            </button>
        </div>
    </mat-select>
</mat-form-field>
