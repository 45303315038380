export class HtmlEditorHelper {
    public static getCkeConfig() {
        return {
            toolbar: {
                items: [
                    'heading', '|',
                    'fontfamily', 'fontsize', '|',
                    'alignment', '|',
                    'fontColor', 'fontBackgroundColor', '|',
                    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                    'link', '|',
                    'outdent', 'indent', '|',
                    'bulletedList', 'numberedList', 'todoList', '|',
                    'code', 'codeBlock', '|',
                    'insertTable', '|',
                    'uploadImage', 'blockQuote', '|',
                    'undo', 'redo'
                ],
            },
            link: {
                decorators: {
                    addTargetToExternalLinks: {
                        mode: 'automatic',
                        callback: url => /^(https?:)?\/\//.test( url ),
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                        }
                    },
                    openInNewTab: {
                        mode: 'manual',
                        label: 'Open in a new tab',
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                        }
                    },
                    detectDownloadable: {
                        mode: 'automatic',
                        callback: url => url.endsWith( '.pdf' ),
                        attributes: {
                            download: 'file.pdf'
                        }
                    }
                }
            },
        };
    }
}
