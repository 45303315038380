import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    FetchPublicationChoiceCriteriasSuccess,
    FetchPublicationChoiceCriterias,
    FetchPublicationChoiceCriteriasFailure
} from '@store/publication';
import {ProductsFacade} from 'src/app/data/facades';
import {ParamsFilterBuilder} from 'src/app/data/builders';
import { CharTemplateDto } from 'src/app/data/models';
import { ChoiceCriteria } from 'src/app/data/types';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Injectable()
export class PublicationChoiceCriteriasEffect {

    constructor(
        private actions$: Actions,
        private productsFacade: ProductsFacade
    ) {
    }

    fetchPublicationChoiceCriterias$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchPublicationChoiceCriterias),
            mergeMap(result => {
                    const paramFilterBuilder = new ParamsFilterBuilder()
                        .withElementTypes([environment.instance.elementType])
                        .withChannelScope(result?.categoryId ?? 0)
                        .build();
                     
                    if(result?.categoryId !== 0) {
                        return this.productsFacade.getCharTemplatesByAdvSearch(paramFilterBuilder)
                            .pipe(
                                map((charTemplates : CharTemplateDto[]) => {
                                    const flattenedChoiceCriterias = charTemplates.reduce((acc, charTemplate) => {
                                        return acc.concat(charTemplate.CharTemplateChoiceCriterias);
                                    }, []);

                                    const uniqueChoiceCriterias: ChoiceCriteria[] = _.uniqBy(flattenedChoiceCriterias, 'DicoCaracID');

                                    return FetchPublicationChoiceCriteriasSuccess({ choiceCriterias : uniqueChoiceCriterias });
                                }),
                            );
                    } else {
                        return [];
                    }
                }
            ),
            catchError(error => of(FetchPublicationChoiceCriteriasFailure({message: error.toString()})))
        );
    });
}
