<div class="supplier-card">
  <h1>Fiche fournisseur</h1>
  <div *ngIf="supplierData">
    <div
      *ngFor="let data of supplierData"
      class="col-12 col-md-8 card-content p-2"
    >
      <mat-card>
        <mat-card-title>{{ data.label }}</mat-card-title>
        <mat-card-content>
          <p>{{ data.value }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
