<div id="homepage-container" [ngStyle]="instanceBg$ | async">
  <mat-card class='homepage-card mx-auto'>
    <mat-card-content class="d-flex homepage-card__content">
      <div class="homepage-card__new-catalogue">
        <p class="mb-4 fw-600">{{ 'publication.homepage.catalogue-creation' | translate }}</p>
        <app-publication-catalogue-creation></app-publication-catalogue-creation>
      </div>

      <mat-divider [vertical]="true"></mat-divider>

      <div class="homepage-card__catalogues-list-container">
        <p class="mb-4 fw-600">{{ 'publication.homepage.select-catalogue' | translate }}</p>
          <app-catalogues></app-catalogues>
      </div>
    </mat-card-content>
  </mat-card>
</div>
