import {createReducer, on} from '@ngrx/store';
import {NpLanguageDto} from 'src/lib/data/model/np-language-dto';
import {DataLanguageActions} from './data-language.action';

export const dataLanguageFeatureKey = 'dataLanguage';

export interface DataLanguageState {
    selectedDataLang: NpLanguageDto;
    selectedDataLanguage: NpLanguageDto;
    dataLanguages: NpLanguageDto[];
    errorMessage: string;
    loading: boolean;
    hasError: boolean;
}

export const initialState: DataLanguageState = {
    selectedDataLang: null,
    selectedDataLanguage: null,
    dataLanguages: [],
    errorMessage: null,
    loading: false,
    hasError: false
};


export const dataLanguageReducer = createReducer(
    initialState,
    on(
        DataLanguageActions.fetchDataLanguagesSuccess, (state, {params}): DataLanguageState => ({
            ...state,
            ...params,
            loading: true,
            hasError: false,
            errorMessage: null
        })
    )
);
