import {Injectable} from '@angular/core';
import {LangCode} from '../../data/constants/languages.constants';
import {BehaviorSubject} from 'rxjs';
import {NpLanguageDto} from 'src/lib/data/model/np-language-dto';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private _selectedLang = new BehaviorSubject<string>(this.getUiLanguage());

    private _selectedDataLang = new BehaviorSubject<NpLanguageDto>(this.getDataLanguage());
    public getDataLanguage$ = () => this._selectedDataLang.asObservable();
    public getUiLanguage$ = () => this._selectedLang.asObservable();

    saveUiLanguage(language: string): void {
        localStorage.setItem('selectedUiLanguage', language);
        this._selectedLang.next(language);
    }

    getUiLanguage(): string | null {
        const lang = localStorage.getItem('selectedUiLanguage');
        return !!lang && lang !== 'undefined' ? lang : LangCode.FR;
    }

    saveDataLanguage(dataLanguage: NpLanguageDto): void {
        localStorage.setItem('selectedDataLanguage', JSON.stringify(dataLanguage));
        this._selectedDataLang.next(dataLanguage);
    }

    getDataLanguage(): NpLanguageDto | null {
        return JSON.parse(localStorage.getItem('selectedDataLanguage')) as NpLanguageDto;
    }

    removeDataLanguage(): void {
        if (!!localStorage.getItem('selectedDataLanguage')) {
            localStorage.removeItem('selectedDataLanguage');
        }
    }
}
