import {Injectable} from '@angular/core';
import {CaracConfig, CaracConfigRepository, DicocaracRepository} from '@nextpage/np-sdk-data';
import {BehaviorSubject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {NpElementHelper} from '../helpers/np-element-helper';
import {filter} from 'rxjs/internal/operators';

@Injectable({
    providedIn: 'root',
})
export class NpCaracConfigService {
  private _currentCaracConfigs$ = new BehaviorSubject<CaracConfig[]>([]);
  constructor(
    private _caracConfig: CaracConfigRepository,
    private _dico: DicocaracRepository
  ) {}

  public getAll() {
    return this._dico.getAll().pipe(
      take(1),
      map((data) => {
        const response = NpElementHelper.rebuildConfigs(data);
        this._currentCaracConfigs$.next(response.data as CaracConfig[]);
        return response;
      })
    );
  }

  public getCurrentCaracConfigs() {
    return this._currentCaracConfigs$.asObservable();
  }

  public getCaracConfigs(dicoExtIDs: string[]) {
    return this._currentCaracConfigs$.asObservable().pipe(
      filter((response) => response && response.length > 0),
      map((response: CaracConfig[]) => {
        return response.filter((x) =>
          dicoExtIDs.some((z) => z === x.DicoCaracExtID)
        );
      })
    );
  }
}
