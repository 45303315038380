import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {saveAs} from 'file-saver';
import {MatDialog} from '@angular/material/dialog';
import { CaracInfo, PhotosInfos } from '../np-value/Model';
import { Access, CaracConfig, DicoCarac, NPCaracLien, NPCaracValeur, NPElement, TypeCode } from 'src/lib/data/model';
import { ElementWriterService, NpDownloadService } from 'src/lib/data/services';
import { FileReaderService, MediaLibraryService } from 'src/lib/services';
import { VisualMediaGalleryComponent } from '../visual-media-v2/visual-media-gallery/visual-media-gallery.component';
import { DialogFilePreviewComponent } from '../dialog-file-preview/dialog-file-preview.component';
import * as _ from 'lodash';

@Component({
    selector: 'lib-np-file-upload',
    templateUrl: './np-file-upload.component.html',
    styleUrls: ['./np-file-upload.component.scss']
})
export class NpFileUploadComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public isImage: boolean;
    public currentPhoto: PhotosInfos;
    public readOnlyAccess: boolean;
    public isDisplayFullImage: boolean;

    private _linkedElement: NPElement;
    private _fileSource: SafeResourceUrl;
    private _currentMedia: NPCaracValeur;
    private _isMediaLink: boolean;

    constructor(private _dialog: MatDialog,
                private _elementWriter: ElementWriterService,
                private _fileReader: FileReaderService,
                private _sanitizer: DomSanitizer,
                private _downloadService: NpDownloadService,
                @Inject(forwardRef(() => MediaLibraryService)) private _mediaLibraryService: MediaLibraryService) {
    }

    ngOnInit() {
        this._update();
        this.readOnlyAccess = this.caracInfo.authorization === Access.LECTURESEULE;
    }

    addFile() {
        this._mediaLibraryService.open(this.value.DicoCaracExtID, -1, {
            panelClass: 'file-preview-custom-dialog'
        })
            .subscribe((result: NPElement) => {
                this._currentMedia = result.getValue(DicoCarac.MEDIA_FILE);
                if (this._isMediaLink) {
                    this._linkedElement = result;
                    this._elementWriter.setValueLink(this.value.Element, this.value.DicoCaracExtID, [result]);
                    this._update(this._linkedElement);
                } else {
                    this._elementWriter.setValueText(this.value.Element, this.value.DicoCaracExtID, this._currentMedia.Value);
                    this._update();
                }
            });
    }

    hasFile() {
        return this._currentMedia && this._currentMedia.Value !== '';
    }

    remove() {
        this._elementWriter.setValueText(this.value.Element, this.value.DicoCaracExtID, '');
        this._update();
    }

    private _update(linkedElement?: NPElement) {

        if (this.caracConfig?.DicoCarac && this.value.Element) {

            this.readOnlyAccess = Number(this.caracConfig.DicoCarac.Editable) === Access.LECTURESEULE;
            this.isDisplayFullImage = this.caracConfig.Specific?.isDisplayFullImage;
            this._isMediaLink = (this.caracConfig?.DicoCarac) && (this.caracConfig?.DicoCarac.TypeCode === TypeCode.LIEN);
            this._isMediaLink ? this._handleCaracMediaLink(linkedElement) : this._handleCaracStandardMedia();

        }
    }

    private _handleCaracMediaLink(linkedElement?: NPElement) {
        // Récupère la valeur des caracs de type lien média
        const currentMedia  = this.value.Element.getValueLien(this.caracConfig?.DicoCaracExtID);
        this._currentMedia = currentMedia;
        this._linkedElement =  linkedElement || currentMedia?.RebuildLinkedElements[0]?.Element;
        this.isImage = true;

        if (this._currentMedia) {
            const urlMedia = this._fileReader.toUrlMedia(this._linkedElement);
            this.initPhotosInfos(
                urlMedia,
                urlMedia,
                this._linkedElement.Label,
                true
            );
        }
    }

    private _handleCaracStandardMedia() {
        // Récupère la valeur des caracs médias de type standard
        this._currentMedia = this.value.Element.getValue(this.caracConfig?.DicoCaracExtID);
        this.isImage = this.caracConfig?.DicoCarac.TypeCode === TypeCode.IMG_NP;

        if (!_.isEmpty(this._currentMedia?.Value)) {
            const originalFilePath = this._fileReader.toOriginalFilePath(this._currentMedia.Value);

            this.initPhotosInfos(
                originalFilePath,
                originalFilePath,
                this._currentMedia.Value,
                this.isImage
            );

        } else {
            this.currentPhoto = null;
        }
    }

    private initPhotosInfos(src: string, thumbSrc: string, alt: string, isSanitized: boolean): void {
        this.currentPhoto = new PhotosInfos(src, thumbSrc, alt);
        this._fileSource = isSanitized ? this._sanitizer.bypassSecurityTrustResourceUrl(src) : undefined;
    }

    public openFile() {
        // Si carac IMG
        if (this.isImage) {
            this._dialog.open(VisualMediaGalleryComponent, {
                minWidth: '500px',
                data: {photos: this._currentMedia, value: this.value, caracConfig: this.caracConfig, isImage: true},
            }).afterClosed()
                .subscribe(() => {
                    this._update();
                });
        } else { // Ss carac Fichier
            this._openFilePreview();
        }

    }

    downloadImage() {
        if (this.isImage) {
            this._downloadService.getBase64ImageFromURL(this.currentPhoto.src)
                .subscribe(base64data => {
                    const blob = this._downloadService.getDownload(base64data);
                    saveAs(blob, this.currentPhoto.src);
                });
        } else {
            this._openFilePreview();
        }
    }

    private _openFilePreview() {
        this._dialog.open(DialogFilePreviewComponent, {
            height: '70%',
            width: '50%',
            data: {currentMedia: this._currentMedia},
        });
    }
}
