import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
    importMediaFeatureKey,
    ImportMediaState,
} from '@store/import-media';

const selectState = createFeatureSelector<ImportMediaState>(
    importMediaFeatureKey
);
export const selectIsUploaded = createSelector(
    selectState,
    (state) => state.loading
);
export const selectUploadImages = createSelector(
    selectState,
    (state) => state.medias
);



