import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {CaracInfo} from '../Model';
import {DicocaracRepository, ElementWriterService, NPCaracListe, NPListeValues} from '@nextpage/np-sdk-data';


@Component({
    selector: 'lib-app-value-link-write-view',
    templateUrl: './np-value-list-write-view.component.html',
    styleUrls: ['./np-value-list-write-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueListWriteViewComponent implements OnInit, OnChanges {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracListe;
    /**
     * dicocarac.Values Valeurs disponibles
     */
    displayValues: NPListeValues[];

    constructor(private _dicoCaracRep: DicocaracRepository, private _eltWrSrv: ElementWriterService) {
    }

    ngOnInit() {
    }

    public initValues() {
        if (this.value != null) {
            if (this.value.Element) {
                this.value.ElementExtID = this.value.Element.ExtID;
            }
            this._dicoCaracRep.getDicoCarac(this.value.DicoCaracExtID).subscribe(dc => {
                if (dc.Values != null && (<NPCaracListe>this.value).Values != null) {
                    this.displayValues = dc.Values;
                }
            });
        } else {
            this.displayValues = [];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initValues();
    }

    /**
     * Genere une chaine unique à utiliser pour identifier les elements html
     */
    getHtmlName() {
        return this.value.Element ? this.value.Element.ExtID + '/' + this.value.DicoCaracExtID : this.value.DicoCaracExtID;
    }

    /**
     * Indique si la valeur est sélectionnée
     */
    isChecked(valeurId: number) {
        return this.value.Values.filter((d) => d.ValueID === valeurId).length > 0;
    }

    /**
     * Coche la case (ou la décoche)
     */
    checkRadio(valeurId: number) {
        if (this.isChecked(valeurId)) {
            // On le retire en récupérant d'abord la liste des valeurs déjà choisies
            const ValuesID = (<NPCaracListe>this.value.Element.getValue(this.value.DicoCaracExtID)).Values.filter((v) => v.ValueID !== valeurId).map((v) => v.ValueID);
            this._eltWrSrv.setValueListeByIDValues(this.value.Element, this.value.DicoCaracExtID, ValuesID);
        } else {
            // On l'ajoute
            this._eltWrSrv.setValueListeByIDValues(this.value.Element, this.value.DicoCaracExtID, [valeurId]);
        }
    }
}
