import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    createJsonString(name, idSearch) {
        return `{"ApiName" : "","Param" : {"idSearch" : "${idSearch}"},"Title" : "${name}"}`;
    }

    getTitleOfAdvancedSearch(configuration) {
        if (configuration !== 'default') {
            const obj = JSON.parse(configuration);
            return obj.Title;
        }
        return 'Mes dernières modifications';
    }

    getIdOfAdvancedSearch(configuration) {
        if (configuration !== 'default') {
            const obj = JSON.parse(configuration);
            return obj.Param.idSearch;
        }
    }

}
