import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NpLanguageDto } from 'src/lib/data/model/np-language-dto';

export const DataLanguageActions = createActionGroup({
  source: 'dataLanguage',
  events: {
      'fetch data languages': emptyProps(),
      'fetch data languages success': props<{ params: {selectedDataLanguage: NpLanguageDto, dataLanguages: NpLanguageDto[]} }>()
  },
});
