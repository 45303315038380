import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {NgForOf, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {ChoiceCriteria, Facet} from 'src/app/data/types';
import {searchInFacets} from 'src/app/utils';
import {FormControlPipe} from '../../../../data/pipes/form-control.pipe';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

@Component({
    selector: 'app-searchable-select',
    templateUrl: './searchable-select.component.html',
    imports: [
        MatInputModule,
        MatSelectModule,
        NgForOf,
        MatButtonModule,
        MatIconModule,
        NgIf,
        TranslateModule,
        ReactiveFormsModule,
        FormControlPipe,
        NgxMatSelectSearchModule
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchableSelectComponent implements OnInit, AfterViewInit {

    @Input() formFilter: FormGroup;
    @Input()
    set filtersCriteria(value: ChoiceCriteria) {
        this._filtersCriteria = value;
        this.searchResultFacets = [...this._filtersCriteria.facets || []];
    }
    get filtersCriteria(): ChoiceCriteria {
        return this._filtersCriteria;
    }

    @Output() resetFilter = new EventEmitter<number>();
    @Output() selectedFacets = new EventEmitter<Facet[]>();
    @Output() filterBySelectedFacets = new EventEmitter<ChoiceCriteria>();
    @Output() dropDownOpened = new EventEmitter<{ dropdownOpened: boolean, filtersCriteria: ChoiceCriteria }>();

    public selectedValues: string;
    public searchResultFacets: Facet[] = [];
    public searchFormControl = new FormControl<string>('');

    private _filtersCriteria: ChoiceCriteria;

    ngOnInit(): void {

        this.searchFormControl.valueChanges.subscribe((searchValue) => {
            this._searchInFacets(searchValue);
        });

        this.formFilter.controls[this.filtersCriteria.DicoCaracID].valueChanges
            .subscribe((values: Facet[]) => {
                this._buildSearchedValuesLabel(values);

                this.selectedFacets.emit(values);
            });

    }

    ngAfterViewInit(): void {
        this._buildSearchedValuesLabel(this.formFilter.controls[this.filtersCriteria.DicoCaracID].value);
    }

    public onChoicesCriteriaDropdownOpened($event: boolean) {
        this.dropDownOpened.emit({dropdownOpened: $event, filtersCriteria: this.filtersCriteria});
    }

    private _searchInFacets(searchedValue: string) {
        this.searchResultFacets = searchInFacets(searchedValue, this.filtersCriteria);
    }

    private _buildSearchedValuesLabel(facets: Facet[]) {
        this.selectedValues = facets
            ?.map((facet: Facet) => `${facet.Value}(${facet.Count})`)
            ?.join(',');
    }
}
