import {NPCaracLien} from '../model/np-carac-valeur';
import {NPAPIElementLinksResult} from '../model/NPAPIElementLinksResult';
import {NPElement} from '../model/np-element';


export class ModelHelper {

  myMap = new Map();

  constructor(private _resultat: NPAPIElementLinksResult) {
  }

  rebuildData() {
    // 1. Chargement des données dans un dictionnaire pour optimiser les perfs
    /*for (let e of Array.from(this._resultat.Elements.values())) {
      if (this.myMap.has(e.ExtID) === false) {
        this.myMap.set(e.ExtID, e);
      }
    }*/

    // 1.bis
    if (this._resultat == null || this._resultat.Elements == null) {
      return;
    }
    this._resultat.Elements.forEach((v, k) => {
      if (this.myMap.has(k) === false) {
        this.myMap.set(k, v);
      }
    });

    // rebuild des liens et des caracs
    /*for (let e of Array.from(this._resultat.Elements.values()))
      this.rebuild(e);*/
    this._resultat.Elements.forEach((v) => {
      this.rebuild(v);
    });
  }

  private rebuild(element: NPElement) {
    element.Values.forEach((NpCarac) => {

      // Si on a à faire à un type Std
      element.setValue(NpCarac.DicoCaracExtID, NpCarac);

      // Si on a à faire à un type Lien
      if (NpCarac instanceof NPCaracLien) {
        // on utilise les ExtID dans sa liste LinkedElements
        NpCarac.RebuildLinkedElements = [];
        for (const ExtID of NpCarac.LinkedElements) {
          // comme clés pour reconstruire RebuildLinkedELements depuis le Map
          if (this.myMap.has(ExtID)) {
            NpCarac.RebuildLinkedElements.push(this.myMap.get(ExtID));
          }
        }
      }

    });
  }
}
