import {Injectable} from '@angular/core';
import {USER_TOKEN} from '@data/constants';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private _token: string;

  static deleteToken() {
    localStorage.removeItem(USER_TOKEN);
  }

  setToken(token: string) {
    this._token = token;
    localStorage.setItem(USER_TOKEN, this._token);
  }
  getToken() {
    return this._token && this._token.trim() !== '' ? this._token : localStorage.getItem(USER_TOKEN);
  }
  getToken$() {
    return of(this.getToken());
  }
}
