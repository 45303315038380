import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ElementSummary } from '@app/models';
import { DialogElementWriterComponent } from '../components/dialog-element-writer/dialog-element-writer.component';
import { DialogElementsExportComponent } from '../components/dialog-elements-export/dialog-elements-export.component';
import { map } from 'rxjs/operators';
import { DialogMessageViewComponent } from '../components/dialog-message-view/dialog-message-view.component';
import { MessageModel } from '@data/core';
import { ExportParams } from 'src/app/data/models/export.params.model';
import { ProductSummary } from 'src/app/data/models';
import { DialogProductSheetComponent } from '../components/dialog-product-sheet/dialog-product-sheet.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RouteResolverService } from 'src/app/data/services/routes/route-resolver.service';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private _dialog: MatDialogRef<DialogElementWriterComponent>;

    constructor(
        public dialogRef: MatDialog,
        private _snackBar: MatSnackBar,
        private _translate: TranslateService,
        private _routeResolverService: RouteResolverService
    ) {
    }

    public openElementWriterDialog(element: ElementSummary) {
        this._dialog = this.dialogRef.open(DialogElementWriterComponent, {
            data: { elementSummary: element },
            hasBackdrop: true,
            disableClose: true,
            height: '85%',
            width: '90%',
        });
    }

    openExportDialog() {
        return this.dialogRef.open(DialogElementsExportComponent, {})
            .afterClosed()
            .pipe(map((exportParams: ExportParams) => exportParams));

    }

    openErrorDialog(message: MessageModel, panelClass = '') {
        return this.dialogRef.open(DialogMessageViewComponent, {
            data: message,
            closeOnNavigation: false,
            disableClose: true,
            panelClass: panelClass
        });
    }

    closeAll() {
        this.dialogRef.closeAll();
    }

    openProductDialog(product: ProductSummary) {
        return this.dialogRef.open(DialogProductSheetComponent, {
            height: '95%',
            width: '80%',
            data: product
        });

    }

    public showSuccessMessage(
        messageKey: string, 
        alertMessage?: string,
        goToHomepage?: boolean,
        duration?: number): void {
        this._snackBar.open(this._translate.instant(messageKey), '', {
            duration: duration ?? 3000,
            verticalPosition: 'bottom',
            panelClass: 'catalogue-confirm-message'
        }).afterDismissed().subscribe(() => {
            if(!!alertMessage) {
                this.showAlertMessage(alertMessage, goToHomepage);
            }
            if(!alertMessage && goToHomepage) {
                this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.HOME)
            }
        })
    }

    public showAlertMessage(messageKey: string, goToHomepage: boolean): void {
        this._snackBar.open(this._translate.instant(messageKey), '', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: 'catalogue-alert-message'
        }).afterDismissed().subscribe(() => {
            if(goToHomepage) {
                this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.HOME)
            }
        });
    }

    public showErrorMessage(messageKey: string, duration?: number): void {
        this._snackBar.open(this._translate.instant(messageKey), '', {
            duration: duration ?? 3000,
            verticalPosition: 'bottom',
            panelClass: 'catalogue-alert-message'
        });
    }
}
