export class CatalogCreation {
    name: string;
    description?: string | null;
    selectedChapters?: string[];
}

export class CatalogConfigPortal {
    CatalogId: number;
    Structure: CatalogPortal;
    SelectedChapters: string[] | [];
}

export class CatalogPortal {
    Id: string;
    Name: string;
    Description?: string;
    Chapters: ChapterPortal[];
}

export class ChapterPortal {
    Name: string;
    Id?: string;
    ChannelCategoryID?: number;
    ChannelCategoryExtID?: string;
    Pages?: PagePortal[];
}

export class PagePortal {
    Id: string;
    Name: string;
    Description?: string;
    Template?: TemplatePortal;
    Products?: ProductPortal[] | [];
}

export class ProductPortal {
    Id: string;
    Name: string;
    ProductId: number;
}

export class TemplatePortal {
    Id: number;
    Path: string;
    NumberOfElements?: number;
    MaxCharactersLength?: number;
}
