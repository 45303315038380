import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';


import {CaracIdStateValue} from '../../np-value/Model';
import {ElementWriterService, NPCaracStd} from '@nextpage/np-sdk-data';

@Component({
    selector: 'lib-value-video-view',
    templateUrl: './value-video-view.component.html',
    styleUrls: ['./value-video-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ValueVideoViewComponent implements OnInit, OnChanges {
    @Input() caracIdStateValue: CaracIdStateValue;

    videos: string[];
    newVideo: string;

    constructor(private _sanitizer: DomSanitizer, private _elementWriter: ElementWriterService) {
        this.videos = [];
        this.newVideo = '';
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes) {
        this.init();
    }

    /**
     * Initialisation
     */
    private init() {
        if (this.caracIdStateValue && this.caracIdStateValue.config.Component) {
            if (!this.caracIdStateValue.value) {
                this.videos = [];
                return;
            }
            this.videos = (<NPCaracStd>this.caracIdStateValue.value).Value.split(';')
                .filter(video => video.length > 0);
        }
    }

    videoUrl(url) {
        let newurl = url.toString();
        // pour permettre d'afficher les liens de partage
        if (newurl.includes('https://youtu.be/')) {
            newurl = newurl.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
        } else if (newurl.includes('https://vimeo.com/')) {
            newurl = newurl.replace('https://vimeo.com/', 'https://player.vimeo.com/video/');
        }
        return this._sanitizer.bypassSecurityTrustResourceUrl(newurl).toString();
    }

    /**
     * Vérifie s'il y a droit en écriture
     */
    isWriteMode() {
        if (this.caracIdStateValue === undefined) {
            return false;
        }
        const config = this.caracIdStateValue.config;
        // On vérifie d'abord le forced state
        if (config.ForcedState !== undefined) {
            return Number(config.ForcedState) < 2;
        }

        return config.DicoCarac.Access < 2;
    }

    /**
     * Supprime la video de la liste et enregistre
     */
    deleteVideo(index: number) {
        if (!this.isWriteMode()) {
            return;
        }
        this.videos.splice(index, 1);
        this.saveVideos();
    }

    /**
     * Ajoute une nouvelle video à la liste
     */
    addVideo() {
        if (this.newVideo.length === 0 || !this.isWriteMode()) {
            return;
        }
        if (this.videos.filter(video => video === this.newVideo).length === 0) {
            this.videos.push(this.newVideo);
            this.saveVideos();
            this.newVideo = '';
        }
    }

    /**
     * Enregistre la liste des videos
     */
    saveVideos() {
        this._elementWriter.setValueText(this.caracIdStateValue.value.Element, this.caracIdStateValue.config.DicoCaracExtID, this.videos.join(';'));
    }
}
