import { SelectedDico } from '../types';

export class SelectedDicoBuilder {
  private _ID: number;
  private _TypeCode: string;
  private _ElType: number;
  private _DicoCaracExtId: string;

  withID(value: number): SelectedDicoBuilder {
    this._ID = value;
    return this;
  }

  withTypeCode(value: string): SelectedDicoBuilder {
    this._TypeCode = value;
    return this;
  }

  withDicoCaracExtId(value: string): SelectedDicoBuilder {
    this._DicoCaracExtId = value;
    return this;
  }

  withElType(value: number): SelectedDicoBuilder {
    this._ElType = value;
    return this;
  }

  build(): SelectedDico {
    return {
      ID: this._ID,
      TypeCode: this._TypeCode ?? '',
      ElType: this._ElType ?? 2,
      DicoCaracExtId: this._DicoCaracExtId ?? '',
    };
  }
}
