import {Component, OnInit} from '@angular/core';
import {NpUser} from '@nextpage/np-sdk-data';
import {filter, flatMap, map, shareReplay} from 'rxjs/internal/operators';
import {SpAuthenticationService} from '../../../data/services/sp-authentication.service';
import {NpInstanceService, SpUserInfoService} from '@data/services';
import {Router} from '@angular/router';
import {RouteResolverService} from '../../../data/services/routes/route-resolver.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {MatDialog} from '@angular/material/dialog';
import {
    DialogCataloguesManagementComponent
} from '../publication/dialog-catalogues-management/dialog-catalogues-management.component';
import {RoutePagesName} from 'src/app/data/constants/route-pages.constants';

@Component({
    selector: 'app-user-name',
    templateUrl: './user-name.component.html',
    styleUrls: ['./user-name.component.scss'],
})
export class UserNameComponent implements OnInit {
    public currentUser: NpUser;
    public isCataloguePage$ = this._instanceService.getCurrentInstancePage()
        .pipe(map(pageUrl => this._instanceService.isPublicationPage() && pageUrl.page === RoutePagesName.CATALOGUE), shareReplay());
    public isPublicationEntity = this._instanceService.isPublicationPage();

    constructor(
        private _userInfoService: SpUserInfoService,
        private _authenticationService: SpAuthenticationService,
        private _router: Router,
        private _routeResolverService: RouteResolverService,
        private _localStorageService: LocalStorageService,
        private _instanceService: NpInstanceService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this._fetchUserInfos();
    }

    onLogout() {
        this._authenticationService.logout();
        const dataLanguage = this._localStorageService.getDataLanguage();
        if (!!dataLanguage) {
            this._localStorageService.removeDataLanguage();
        }
        return this._routeResolverService.navigate(RoutePagesName.LOGIN);
    }

    goToAccount() {
        return this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.ACCOUNT);
    }

    public openCataloguesManagement(catalogue: any): void {
        this.dialog.open(DialogCataloguesManagementComponent, {
            width: '750px',
            data: catalogue,
            panelClass: 'catalogues-management-dialog'
        });
    }

    private _fetchUserInfos() {
        this._authenticationService
            .afterAuth()
            .pipe(
                filter((response) => {
                    return response;
                }),
                flatMap(() => {
                    return this._userInfoService.getCurrentUser();
                })
            )
            .subscribe((response) => {
                this.currentUser = response;
            });

        this._userInfoService.getCurrentUser().subscribe((response) => {
            this.currentUser = response;
        });
    }
}
