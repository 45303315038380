import {createAction, props} from '@ngrx/store';
import {CharTemplateDto} from 'src/app/data/models';

export const FetchProductCharTemplates = createAction(
    '[Product Char Templates] Fetch Products Char Templates'
);

export const FetchCharTemplatesSuccess = createAction(
    '[Product Char Templates] Fetch Success',
    props<{ charTemplates: CharTemplateDto[] }>()
);

export const FetchProductCharTemplatesFailure = createAction(
    '[Char Templates] Fetch Failure',
    props<{ message: string }>()
);
