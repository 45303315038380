import {PublicationTemplateDto} from '@nextpage/np-sdk-data';

export class IPublicationTemplateApiResult {
    Results: {
        Templates: PublicationTemplateDto[],
        Warnings: string[],
        Errors: string[]
    };
}

