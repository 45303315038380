import {createFeatureSelector, createSelector} from '@ngrx/store';
import {userInfoFeatureKey, UserInfoState} from '../reducers/user-info.reducer';
import {UserInfoHelper} from '../../data/helpers/user-info.helper';
import {DEFAULT_CARAC_EXTIDS, DEFAULT_SEPARATOR, SpDicoCarac} from '@data/constants';
import { DEFAULT_CARAC_EXTIDS_PUBLICATION } from 'src/app/data/constants';

const selectUseInfoState = createFeatureSelector<UserInfoState>(userInfoFeatureKey);

export const selectUserInfoForm = createSelector(
    selectUseInfoState,
    state => state.userInfo
);

export const selectUserNameForm = createSelector(
    selectUseInfoState,
    state => state.userInfo?.Name
);

export const selectUserIdForm = createSelector(
    selectUseInfoState,
    state => state.userInfo?.ID
);

export const selectUserForm = createSelector(
    selectUseInfoState,
    state => state
);

export const selectUserCustomFieldsForm = createSelector(
    selectUseInfoState,
    state => state.userInfo?.CustomFields
);

export const selectAdvancedSearchId = createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getAdvancedSearchId(state.userInfo)
);
export const selectMediaFolderId = createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getMediaFolderId(state.userInfo)
);

export const selectChannelScopeExtId = createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getChannelScopeExtId(state.userInfo)
);

export const selectFamilyScopeExtId = createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getFamilyScopeExtId(state.userInfo)
);

export const selectUserCustomFieldValue = (fieldExtId: SpDicoCarac) => createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getFieldValue(fieldExtId, state?.userInfo)?.Value
);

export const selectPublicationChannelScopeExtId = createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getPublicationChannelScopeExtId(state.userInfo)
);

export const selectPublicationCataloguesFolderId = createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getPublicationCataloguesFolderId(state.userInfo)
);

export const selectPublicationMemberLogoExtId = createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getPublicationMemberLogoExtId(state.userInfo)
);


// Pour savoir s'il y a des requêtes à afficher dans la page d'acceuil
export const selectHasDashboardQueries =  createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getFieldValue(SpDicoCarac.CP_REQUETESACCUEIL, state?.userInfo)
        ?.Value
        ?.split(DEFAULT_SEPARATOR)
        ?.length > 0
);

export const selectDashboardQueryIds =  createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getFieldValue(SpDicoCarac.CP_REQUETESACCUEIL, state?.userInfo)
        ?.Value
        ?.split(DEFAULT_SEPARATOR)
);


export const selectUVisualCaracValue = createSelector(
    selectUseInfoState,
    state =>
        UserInfoHelper.getMainVisualCaracExtId(state?.userInfo)
);

export const selectElementPreviewConfigs = createSelector(
    selectUseInfoState,
    state =>
        ({
            mainVisualCaracExtId: UserInfoHelper.getMainVisualCaracExtId(state?.userInfo),
            dashBoardQueries: UserInfoHelper.getDashboardQueries(state?.userInfo),
            previewCaracs: [
                ...UserInfoHelper.getPreviewCaracs(state?.userInfo),
                ...DEFAULT_CARAC_EXTIDS,
                UserInfoHelper.getMainVisualCaracExtId(state?.userInfo)
            ] as string[]
        })
);

export const selectMediaPreviewConfigs = createSelector(
    selectUseInfoState,
    state =>
        ({
            mainVisualCaracExtId: UserInfoHelper.getMainVisualCaracExtId(state?.userInfo),
            dashBoardQueries: UserInfoHelper.getDashboardQueries(state?.userInfo),
            previewCaracs: [
                ...UserInfoHelper.getMediaPreviewCaracs(state?.userInfo),
                ...DEFAULT_CARAC_EXTIDS,
                UserInfoHelper.getMainVisualCaracExtId(state?.userInfo)
            ] as string[]
        })
);

export const selectPublicationElementPreviewConfigs = createSelector(
    selectUseInfoState,
    state =>
        ({
            mainVisualCaracExtId: UserInfoHelper.getMainVisualCaracExtId(state?.userInfo),
            dashBoardQueries: UserInfoHelper.getDashboardQueries(state?.userInfo),
            previewCaracs: [
                ...UserInfoHelper.getPreviewCaracs(state?.userInfo),
                ...DEFAULT_CARAC_EXTIDS_PUBLICATION,
                UserInfoHelper.getMainVisualCaracExtId(state?.userInfo)
            ] as string[]
        })
);

export const selectCaracsExtIds = createSelector(
    selectUseInfoState,
    state => UserInfoHelper.getPreviewCaracs(state?.userInfo)
);


/**
 * Returns the characteristics to display in the preview of a product
 */
export const selectPreviewCaracs = createSelector(
    selectUseInfoState,
    state =>
        [
            ...UserInfoHelper.getPreviewCaracs(state?.userInfo),
            UserInfoHelper.getMainVisualCaracExtId(state?.userInfo),
            ...DEFAULT_CARAC_EXTIDS
        ] as string[]
);


