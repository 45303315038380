import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    CaracConfig,
    Components,
    NPCaracValeur,
    NPCaracValeurTypeValue,
    NPElement,
} from '@nextpage/np-sdk-data';
import {SpValueHelper} from '../../../data/helpers/sp-value-helper';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';
import {ProductSummary} from '@app/models';
import {NpInstanceService, SpUserInfoService} from '@data/services';
import {SpElementWriterService} from '../../../data/services/sp-element-writer.service';
import {SpLoaderService} from '../../services/sp-loader.service';
import {ProductsFacade} from 'src/app/data/facades/products-facade';
import {Field} from '@data/types';
import {ValueOneElementHelper} from 'src/lib';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-dialog-element-writer',
    templateUrl: './dialog-element-writer.component.html',
    styleUrls: [
        '../dialog-product-sheet/dialog-product-sheet.component.scss',
        './dialog-element-writer.component.scss'
    ],
})
export class DialogElementWriterComponent implements OnInit {

    public menuItems: MenuItem[] = [];
    public element: NPElement;
    public caracValues: CaracIdStateValue[] = [];
    public imageByDefault$ =  this._instanceService.getImageByDefault();
    public currentMedia: NPCaracValeur;
    public fields: Field[];
    public mainVisualCaracValue: CaracIdStateValue;
    public saveSuccessMessage = this._translateService.instant('products.save-success');
    public saveFailureMessage = this._translateService.instant('products.save-failure');
    public saveNothingMessage = this._translateService.instant('products.save-nothing');

    constructor(
        public _dialogRef: MatDialogRef<DialogElementWriterComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProductSummary,
        private _userInfoService: SpUserInfoService,
        private _spElementWriterService: SpElementWriterService,
        private _loaderService: SpLoaderService,
        private _instanceService: NpInstanceService,
        private _productFacade: ProductsFacade,
        private _translateService: TranslateService,
        ) {
    }

    ngOnInit(): void {
        if (this.data) {
            this.element = this.data.element;
        }

        this._userInfoService
            .getConfigByUserField()
            .subscribe((configs) => {
                if (configs && configs.length > 0) {
                    configs.forEach((config) => {
                        this._addToTree(config);
                    });
                }
                if (this.menuItems && this.menuItems.length > 0) {
                    this.menuItems.forEach(menuItem => this.showCaracValues(menuItem));
                }
            });

        this._userInfoService.getMainVisualCaracConfig().subscribe(mainVisualConfig => {
            mainVisualConfig.Component = Components.FILE_UPLOAD;
            mainVisualConfig.Specific = {...mainVisualConfig.Specific, isDisplayFullImage: true};
            this.mainVisualCaracValue = SpValueHelper.toBlock(this.element, mainVisualConfig);
        });
    }

    private _addToTree(config: CaracConfig) {
        const stdCaracValue = new ValueOneElementHelper().getCaracValue(this.element, config);
        if (stdCaracValue?.TypeValue === NPCaracValeurTypeValue.Standard
        || stdCaracValue?.TypeValue === NPCaracValeurTypeValue.Multivalo
        ) {
            let item = this.menuItems.find(
                (x) => x.id === config.DicoCarac.LibelleDossier
            );
            if (item) {
                item.add(config);
            } else {
                item = new MenuItem();
                item.id = config.DicoCarac.LibelleDossier;
                item.add(config);
                this.menuItems.push(item);
            }
        }
    }

    showCaracValues(item: MenuItem) {
        if (this.element) {
            item.configs.map((config) =>
                this.caracValues.push(SpValueHelper.toBlock(this.element, config))
            );
        }
    }

    save(close = false) {
        if (this._spElementWriterService.hasModifications()) {
            this._loaderService.openPreloader();
            this._spElementWriterService.pushToNextPage()
                .subscribe(() => {
                    this._productFacade.updateMainVisual(this.data, this.mainVisualCaracValue.config.DicoCarac);
                    this._loaderService.openSnackBar(this.saveSuccessMessage);
                    this._loaderService.close();
                    if (close) {
                        this._dialogRef.close();
                    }
                },
                (error) => {
                    this._loaderService.openSnackBar(
                        error
                            ? error.toString()
                            : this.saveFailureMessage
                    );
                    this._dialogRef.close();
                }
            );
        } else {
            this._loaderService.openSnackBar(this.saveNothingMessage);
        }
    }
}

class MenuItem {
    id: string;
    configs: CaracConfig[] = [];

    add(config: CaracConfig) {
        if (config.DicoCarac.LibelleDossier === this.id) {
            this.configs.push(config);
        }
    }
}
