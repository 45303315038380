import {Component, Input} from '@angular/core';
import {ElementSummary} from '@app/models';
import {DialogService} from '../../../services/dialog.service';
import {SpLoaderService} from '../../../services/sp-loader.service';

@Component({
  selector: 'app-product-table-cell-view',
  templateUrl: './product-table-cell-view.component.html',
  styleUrls: ['./product-table-cell-view.component.scss'],
})
export class ProductTableCellViewComponent {
  @Input() column: string;
  @Input() element: ElementSummary;
  public role = false;
  public elementLabel: string;
  constructor(
    private _loaderService: SpLoaderService,
    private _dialogService: DialogService
  ) {
  }

  edit() {
    this._dialogService.openElementWriterDialog(this.element);
  }
}
