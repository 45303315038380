<ng-container *ngIf="isReady()">
    <div class="table-block">
        <table>
            <thead>
            <tr>
                <th *ngFor="let header of headers">{{header.DicoCarac ? header.DicoCarac.Name : ''}}</th>
                <th>Pourcentage de l'apport de référence journalier par portion</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of rows">
                <td *ngFor="let header of headers">
                    <ng-container *ngIf="!header.DicoCarac; else dataBlock">
                        {{getData(row, header)}}
                    </ng-container>
                    <ng-template #dataBlock>
                        <lib-app-value-view [element]="value.Element"
                                            [caracIdStateValue]="getCISV(value.Element, getData(row, header))"></lib-app-value-view>
                    </ng-template>

                </td>
                <td></td>
            </tr>
            </tbody>
        </table>
    </div>
</ng-container>

