<div class="toggable-content" *ngIf="contentConfigs && element">
  <div *ngIf="hasInformation; else noCaraBlock">
             <span *ngFor="let config of contentConfigs">
                <lib-app-value-view *ngIf="config.DicoCarac &&  getCISV(config) !== null" [element]="element"
                                    [caracIdStateValue]="getCISV(config)"></lib-app-value-view>
             </span>
  </div>
  <ng-template #noCaraBlock>
    <span class="noCaraText">Aucune information disponible pour ce produit.</span>
  </ng-template>
</div>
