<div class="d-flex justify-content-between align-items-center">
  <h2 mat-dialog-title>{{ data?.nameWithoutTag }}</h2>
  <button mat-dialog-close class="close-btn">
    <fa-icon class="d-flex" [icon]="['far', 'xmark']"></fa-icon>
  </button>
</div>
<mat-dialog-content class="dialog-content">
  <span>
    <img [src]="data?.template?.completePath || imageTemplateByDefault" [alt]="data?.nameWithoutTag">
  </span>
</mat-dialog-content>