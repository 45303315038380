<div>
  <button mat-button class="menu-button" (click)="goToFavorite()">
    <mat-icon [matBadge]="favoritesState | async"
              matBadgePosition="above after"
              matBadgeSize="small"
              matBadgeColor="accent">favorite_border
    </mat-icon>
    <span style="margin-left: 4px;">{{ 'favorites.title' | translate }}</span>
  </button>
</div>
