import {createReducer, on} from '@ngrx/store';
import {FetchDashboardAdvancedSearchesFailure, FetchDashboardAdvancedSearchesLoader, FetchDashboardAdvancedSearchesSuccess} from './dashboard.actions';
import * as _ from 'lodash';
import {AdvSearchArgs} from '@nextpage/np-sdk-data';

export const dashboardPageFeatureKey = 'dashboardPage';

export interface DashboardPageState {
    advancedSearches: AdvSearchArgs[];
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
    hasAdvancedSearches: boolean;
}

export const initialItem: DashboardPageState = {
    advancedSearches: [],
    errorMessage: null,
    loading: true,
    hasError: false,
    hasAdvancedSearches: false
};

export const dashboardReducer = createReducer(
    initialItem,
    on(
        FetchDashboardAdvancedSearchesLoader,
        (state, {}): DashboardPageState => ({
            ...state,
            advancedSearches: [],
            loading: true, // To maintain the loader
            errorMessage: null
        })
    ),
    on(
        FetchDashboardAdvancedSearchesSuccess,
        (state, {advancedSearches}): DashboardPageState => {
            return ({
                ...state,
                advancedSearches: advancedSearches,
                loading: false,
                errorMessage: null,
                hasAdvancedSearches: !_.isEmpty(advancedSearches)
            });
        }
    ),
    on(FetchDashboardAdvancedSearchesFailure,
        (state, {message}) => ({
            ...state,
            advancedSearches: [],
            errorMessage: message,
            loading: false,
            hasError: true,
            hasAdvancedSearches: false
        })
    )
);
