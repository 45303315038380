import {Component, Input} from '@angular/core';
import {FilePreview} from 'src/app/data/models/file-preview.model';

@Component({
  selector: 'app-file-item-view',
  templateUrl: './file-item-view.component.html',
  styleUrls: ['./file-item-view.component.scss']
})
export class FileItemViewComponent {
  @Input() customClass: string;
  @Input() file: FilePreview;
}
