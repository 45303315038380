import {ModuleWithProviders, NgModule} from '@angular/core';
import {FileDropDirective} from './file-drop.directive';

@NgModule({
  declarations: [FileDropDirective],
  exports: [FileDropDirective],
  providers: []
})
export class FileDropModule {
  static forRoot(): ModuleWithProviders<FileDropModule> {
    return {
      ngModule: FileDropModule,
      providers: [FileDropDirective]
    }
  }
}
