import { NPElementType } from '../../../lib';
import { ChoiceCriteria } from '../types/choice-criteria';

export class CharTemplateDto {
  ID: number;
  ExtID: string;
  Name: string;
  ElementType: NPElementType;
  ParentID: number;
  CharTemplateChoiceCriterias: ChoiceCriteria[];
}
