export class NpwPagination {
    public static readonly defaultTake = 30;
    public take;
    public skip = 0;
    public orderby = '';
    public orderbydirection: NpwOrderByDirection = NpwOrderByDirection.asc;

    constructor() {
        this.take = NpwPagination.defaultTake;
    }
}

export enum NpwOrderByDirection {
    asc = 'ASC',
    desc = 'DESC'
}
