import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { CharsBuilder, ItemBuilder, ParamsFilterBuilder, SelectedDicoBuilder } from "src/app/data/builders";
import { ParamsFilter } from "src/app/data/models";
import { FetchPublicationChoiceCriterias as FetchPublicationChoiceCriterias, FetchPublicationChoiceCriteriasSuccess, selectPublicationChoiceCriterias } from "@store/publication";
import { NpwFilterLogicalOperator, NpwFilterOperator } from "src/lib";
import { Chars, ChoiceCriteria, Facet, Item, SelectedDico } from "src/app/data/types";
import { combineLatest, Subscription } from "rxjs";
import { ProductsFacade } from "src/app/data/facades";
import { selectDataLanguage } from "src/app/store/data-languages";
import { convertFacetMapToItems, getFacetValueByType } from "src/app/core";
import * as _ from "lodash";
import { PUBLICATION_DEFAULT_PAGE_INDEX } from "src/app/data/constants/products-page-size.constants";
import { SearchExtensionComponent } from "src/app/graphics/utils/search-extension.component";
import { Chapter } from "@app/models";
import {swapFacets} from "@np/utils";

@Component({
  selector: 'app-products-selection-filters',
  templateUrl: './products-selection-filters.component.html',
  styleUrls: ['./products-selection-filters.component.scss'],
})

export class ProductsSelectionFiltersComponent extends SearchExtensionComponent implements OnInit, OnDestroy {
  @ViewChild('searchFilter') searchFilter!: ElementRef;

  @Input() public chapter: Chapter;
  @Input() public paramFilterBuilder: ParamsFilterBuilder;

  @Output() filterElements = new EventEmitter<ParamsFilter>();
  @Output() firstPage = new EventEmitter<void>();

  public choiceCriteriasView$ = this._store.select(selectPublicationChoiceCriterias);
  public dataLanguageView$ = this._store.select(selectDataLanguage);
  public productsFiltersForm: FormGroup;
  public choiceCriteriasList: ChoiceCriteria[];
  public choiceCriteriasSelected = new Map<number, Facet[]>();

  private _filtersCriteriaSub: Subscription;
  private _initFiltersSub: Subscription;
  private _selectedDataLanguageID: number;
  private _currentFacet: ChoiceCriteria;

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _productsFacade: ProductsFacade
  ) {
    super();
    this.productsFiltersForm = this._fb.group({});
  }

  ngOnInit(): void {
    this._initFilters();

    this.searchFormControlSub = this.resetSearchField
      .subscribe(() => {
        this.searchProducts('');
      });
  }

  public searchProducts(searchedValue: string): void {
    const paramsFilter = this.paramFilterBuilder.withKeyword(searchedValue ?? '');
    paramsFilter.withPage(PUBLICATION_DEFAULT_PAGE_INDEX);

    this.filterElements.emit(paramsFilter.build());
    this.firstPage.emit();
  }

  public resetSearch(event: Event): void {
    this.searchFilter.nativeElement.value = '';
    this.searchProducts(event.target['value']);
  }

  public clearAllFilters(): void {
    this._resetAllSelectedFilters();
    this._resetProductFilters();
    this._store.dispatch(FetchPublicationChoiceCriterias({ categoryId: this.chapter?.channelCategoryID }));
  }

  public filterByFacet(filtersCriteria: ChoiceCriteria): void {
    const choiceCriteriasSelected = this.productsFiltersForm.get(filtersCriteria.DicoCaracID.toString())?.value;
    if (choiceCriteriasSelected.length > 0) {
      this.choiceCriteriasSelected.set(filtersCriteria.DicoCaracID, choiceCriteriasSelected);
    } else {
      this.choiceCriteriasSelected.delete(filtersCriteria.DicoCaracID);
    }

    this._initializeFilters(filtersCriteria.DicoCaracID);
  }

  private _initFilters(): void {
    this._store.dispatch(FetchPublicationChoiceCriterias({ categoryId: this.chapter?.channelCategoryID }));

    this._initFiltersSub = combineLatest([
      this.choiceCriteriasView$,
      this.dataLanguageView$
    ]).subscribe(([choiceCriterias, dataLanguage]) => {
      this.choiceCriteriasList = choiceCriterias;
      this._buildForm(choiceCriterias);
      this._selectedDataLanguageID = dataLanguage.ID;
      this._filtersFacets(this.choiceCriteriasList);
    });
  }

  private _initializeFilters(dicoCaracID: number): void {
    this._currentFacet = this.choiceCriteriasList.find(criteria => criteria.DicoCaracID === dicoCaracID);

    const chars: Chars = new CharsBuilder()
      .withLogicalOperator(NpwFilterLogicalOperator.AND.toLowerCase())
      .withItems(convertFacetMapToItems(this.choiceCriteriasSelected)).build();

    const indexOfCurrentFacet = this.choiceCriteriasList
      ?.map(criteria => criteria?.DicoCaracID)
      ?.indexOf(this._currentFacet.DicoCaracID);

    this._filtersFacets(this.choiceCriteriasList, indexOfCurrentFacet, chars);

    this._initFilterData();
  }

  private _initFilterData(): void {
    if (!!this.paramFilterBuilder) {
      const items = this._buildItems();
      const chars: Chars = new CharsBuilder()
        .withLogicalOperator(NpwFilterLogicalOperator.AND.toLowerCase())
        .withItems(items).build();

      this.paramFilterBuilder
        .withPage(PUBLICATION_DEFAULT_PAGE_INDEX)
        .withChars(chars);
    }
    const paramsFilter = this.paramFilterBuilder.build();
    this.filterElements.emit(paramsFilter);
    this.firstPage.emit();
  }

  private _filtersFacets(filtersList: ChoiceCriteria[], index?: number, chars?: Chars): void {
    if (this._currentFacet
      && this.choiceCriteriasSelected.has(this._currentFacet.DicoCaracID)
      && !isNaN(index)) {
      filtersList.splice(index, 1);
    }

    this._filtersCriteriaSub = this._productsFacade
      .completeChoiceCriteriaWithFacets(
        filtersList,
        null,
        this._selectedDataLanguageID,
        chars,
        {
          scopeId: this.chapter?.channelCategoryID,
          isChannel: true
        },
        null
      ).subscribe((criteriasChoice: ChoiceCriteria[]) => {
        this.choiceCriteriasList = criteriasChoice;
        this.choiceCriteriasList.map(criteria => {

          if (_.isEmpty(criteria.initialFacets)) {
            criteria.initialFacets = Object.assign([], criteria.facets);
          } else {
            swapFacets(criteria);
          }

          if (this.choiceCriteriasSelected.size === 1 && this.choiceCriteriasSelected.keys().next().value === criteria.DicoCaracID) {
            criteria.facets = Object.assign([], criteria.initialFacets);
          }
        });

        if (this._currentFacet
          && this.choiceCriteriasSelected.has(this._currentFacet.DicoCaracID)
          && !isNaN(index)) {
          this.choiceCriteriasList.unshift(this._currentFacet);
        }

        this.choiceCriteriasSelected.forEach((values, key) => {
          const dropdownChoiceCriteriasSelectedFacets = this.choiceCriteriasList.find(criteria => criteria.DicoCaracID === key)?.facets || [];
          const intersections = _.intersectionBy(dropdownChoiceCriteriasSelectedFacets, values, 'Value');
          this.productsFiltersForm.get(key.toString())?.setValue(intersections);
        });


        if (this._currentFacet?.DicoCaracID
          && this.productsFiltersForm.get(this._currentFacet.DicoCaracID.toString())?.value.length === 0) {
          const facetToMove = _.pullAt(this.choiceCriteriasList, index)[0];
          this.choiceCriteriasList.push(facetToMove);
        }
      });
  }


  private _resetAllSelectedFilters() {
    this.choiceCriteriasList = [];
    this._currentFacet = null;
    this.productsFiltersForm.reset();
    this.choiceCriteriasSelected.clear();
  }

  private _resetProductFilters() {
    this.paramFilterBuilder
      .withChars(new Chars())
      .withPage(PUBLICATION_DEFAULT_PAGE_INDEX);

    const paramsFilter = this.paramFilterBuilder.build();
    this.filterElements.emit(paramsFilter);
    this.firstPage.emit();
  }

  private _buildForm(filtersCriteriaList: ChoiceCriteria[]): void {
    filtersCriteriaList.forEach(criteria =>
      this.productsFiltersForm.addControl(criteria.DicoCaracID.toString(), this._fb.control([]))
    );
  }

  private _buildItems(): Item[] {
    const items: Item[] = [];
    if (!!this.choiceCriteriasSelected && this.choiceCriteriasSelected.size > 0) {
      Array.from(this.choiceCriteriasSelected.values())
        .reduce((acc, value) => [...acc, ...value])
        .map((fieldFilters: Facet) => {
          const selectedDico: SelectedDico = new SelectedDicoBuilder()
            .withID(fieldFilters.DicoCaracID)
            .withTypeCode(fieldFilters.typeCode)
            .build();

          items.push(
            new ItemBuilder()
              .withOperatorValue(NpwFilterOperator.Equal)
              .withValue(getFacetValueByType(fieldFilters))
              .withSelectedDico(selectedDico).build()
          );
        });
    }
    return items;
  }

  ngOnDestroy() {
    this._filtersCriteriaSub?.unsubscribe();
    this._initFiltersSub?.unsubscribe();
    this._store.dispatch(FetchPublicationChoiceCriteriasSuccess({ choiceCriterias: [] }));
  }
}
