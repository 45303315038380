import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {ExportExcelSummary} from '../models/export-models';
import {EXPORT_OBJECT, ExportDto} from '../constants/export';
import {ExportMediaType} from '../models/export-medias.param.model';
import {NpUser} from '@nextpage/np-sdk-data';
import {ExportHelper} from '../helpers/export.helper';
import {Messages} from '@data/core';
import {ExportParams} from '../models/export.params.model';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ElementsExportService {
    private _objectToSend: ExportDto;
    private _url = '/api/sdk/exportdata/add';
    private _errMessage = `Une erreur s'est produite lors de la génération du fichier de l'export`;

    constructor(private _http: HttpClient,
                private _translate: TranslateService,
                private _router: Router,
                ) {

    }

    buildAndExecuteExport(
        payload: {
            elementIds: number[],
            exportConfig: ExportParams,
            fileName: string,
            currentUser: NpUser,
            exportProfile: string
        }) {

        return !!payload.exportProfile ?
            this.execute(
                ExportHelper.buildExportExcelSummary(
                    payload.elementIds,
                    payload.exportProfile,
                    payload.fileName,
                    payload.exportConfig,
                    payload.currentUser
                )
            ) :
            throwError(
                {
                    summary: this._translate.instant(Messages.EXPORT_PROFILE_EMPTY),
                    detail: this._translate.instant(Messages.EXPORT_PROFILE_DETAIL),
                    severity: ''
                }
            );
    }

    execute(data: ExportExcelSummary) {
        const urlMediasExport = this._router.url.includes('medias');
        if (data) {
            this._objectToSend = Object.assign({}, EXPORT_OBJECT) as ExportDto;
            this._objectToSend.Name = !!data.fileName ? `EXPORT_${data.fileName}` : urlMediasExport ? 'EXPORT_MEDIAS' : 'EXPORT_PRODUCT';
            this._objectToSend.Config.OutFileName = !!data.fileName ? `EXPORT_${data.fileName}` : urlMediasExport ? 'EXPORT_MEDIAS' : 'EXPORT_PRODUCT';

            if (urlMediasExport) {
                Object.assign(this._objectToSend.Config, {
                    Produits: [],
                    Medias: data.mediasId,
                    JobImportExportConfigExtID: '',
                    DicoCaracteristiques: [10000211], // ID de la caractéristique MediaFile (##MediaFile) attendue par Nextpage
                });
            } else {
                Object.assign(this._objectToSend.Config, {
                    Medias: [],
                    Produits: data.productsId,
                    JobImportExportConfigExtID: data.profileExportName,
                    DicoCaracteristiques: [],
                });
            }

            this._objectToSend.Config.User.Email = data.userEmail;
            this._objectToSend.Config.User.ID = data.userID;
            this._objectToSend.Config.SendTo = data.userEmail;

            // Medias export
            if (data.exportConfig?.mediasParams?.checked) {

                this._objectToSend.Config.Options.OutPut.ExportMedia = true;

                this._objectToSend.Config.Options.OutPut.ExportMediaThumb = data.exportConfig.mediasParams
                    .parameters.find(item => item.type === ExportMediaType.EXPORT_MEDIA_THUMB)?.checked;

                this._objectToSend.Config.Options.OutPut.ExportMediaFormats = data.exportConfig.mediasParams
                    .parameters
                    .filter(item => item.type === ExportMediaType.EXPORT_MEDIA_FORMAT && item.checked)
                    .map(item => item.value);
            } else {
                this._objectToSend.Config.Options = null;
            }

            if (!!data.exportConfig?.emailParam) {
                this._objectToSend.Config.User.Email = `${data.userEmail},${data.exportConfig.emailParam}`;
                this._objectToSend.Config.SendTo = data.exportConfig.emailParam;
            }

            return this._sendToNP();
        } else {
            return throwError(this._errMessage);
        }
    }

    private _sendToNP = () => this._http.post<boolean>(this._url, this._objectToSend)
        .pipe(
            catchError(() => throwError(this._errMessage)),
            mergeMap((response) => {
                return response ? of(response) : throwError(this._errMessage);
            })
        )
}
