import {Component, Input, OnInit} from '@angular/core';
import {CaracConfig, NPElement} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '../np-value/Model';
import {TreeviewService, TreeviewItem} from '../../services';

@Component({
    selector: 'lib-np-treeview-item',
    templateUrl: './np-treeview-item.component.html',
    styleUrls: ['./np-treeview-item.component.css']
})
export class NpTreeviewItemComponent implements OnInit {

    @Input() treeviewData: TreeviewItem[] = [];
    @Input() caracConfig = [];

    constructor(private _treeviewService: TreeviewService) {
    }

    ngOnInit() {
    }

    getCISV(caracConfig: CaracConfig, element: NPElement): CaracIdStateValue {
        return this._treeviewService.getCISV(caracConfig, element);
    }
}
