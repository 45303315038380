import {Injectable} from '@angular/core';
import {first} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MailServiceArgs} from '../model/send-mail-args';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private readonly _urlSendMail = '/api/sdk/mail/sendmail';

  constructor(private _http: HttpClient) {
  }

  public sendMail(mailPrm: MailServiceArgs) {
    return this._http.post(this._urlSendMail, mailPrm).pipe(
      first()
    );
  }
}
