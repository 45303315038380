import { Component } from "@angular/core";

@Component({
    selector: 'app-pdf-guide',
    templateUrl: './pdf-guide.component.html',
    styleUrls: ['./pdf-guide.component.scss'],
})
export class PdfGuideComponent {
    constructor() {}

}
