import {Injectable} from '@angular/core';

import {Constantes, NPElement} from '@nextpage/np-sdk-data';
import {FileReaderService} from './file-reader.service';
import {TokenService} from "../../app/data/services/token.service";


@Injectable()
export class FileReaderWebapiService extends FileReaderService {

    constructor(private _constantes: Constantes, private _tokenService: TokenService) {
        super(_constantes, _tokenService);
        this._urlFileHandlerMedia = _constantes.baseUrl + '/img/L/';
        this._urlFileHandlerMediaThumb = _constantes.baseUrl + '/img/Thumbs/';
    }

    //
    toUrlMedia(media: NPElement) {
        const result = super.toUrlMedia(media); // .replace('.png', '.jpg');
        return result;
    }

    toUrlThumbImage(media: NPElement) {
        const result = super.toUrlThumbImage(media); // ..replace('.png', '.jpg');
        return result;
    }

    //
    toUrlCustomImage(media: NPElement, dicoCaracExtID: string) {
        const result = super.toUrlCustomImage(media, dicoCaracExtID); // ..replace('.png', '.jpg');
        return result;
    }

    toUrlCustomImageFromPath(path: string) {
        return super.toUrlCustomImageFromPath(path); // ..replace('.png', '.jpg');
    }

    //
    // getUrlMedia(key: string) {
    //     return this[key];
    // }
}
