<ng-container *ngIf="readonlyMode; else editModeBlock">
    <div class="read-only-block" [innerHTML]="inputOuputContent"></div>
</ng-container>
<ng-template #editModeBlock>
    <ckeditor
            [editor]="Editor"
            [config]="ckeConfig" data="<p>Hello, world!</p>"
            (change)="onChange($event)"
            [(ngModel)]="inputOutputContent" ></ckeditor>
</ng-template>
