import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {CaracIdStateValue} from '../np-value/Model';
import {CaracConfig, NPCaracValeur, NPElement, ValueOneElementHelper} from '@nextpage/np-sdk-data';
import {MediaFillRateService} from '../../services/media-fill-rate.service';

import {Sheet, ThemingService} from '../../services';
import jss from 'jss';

export class Block {
    style: any;
    caracs: CaracIdStateValue[];
}

@Component({
    selector: 'lib-app-element-view',
    templateUrl: './np-element-view.component.html',
    styleUrls: ['./np-element-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NPElementViewComponent implements OnInit, OnChanges {
    /**
     * @property Elément courant
     */
    @Input() element: NPElement;
    /**
     * Configuration des caractéristiques de la fiche courante
     */
    @Input() caracConfig: CaracConfig[];
    @Input() caracConfigKey;
    @Input() oneColumn = false;
    caracIdStateValueList: Map<string, CaracIdStateValue[]>;
    public caracWithStyles = new Map<string, Block[]>();
    allBlocInfo: string[];
    public style: any;

    public classes: Object;


    /**
     * Constructeur de la classe NPElementViewComponent
     */
    constructor(private _mediaFillRateService: MediaFillRateService,
                private _theming: ThemingService) {
    }

    ngOnInit() {
        this._initialize();
        const override_css = this._theming.getComponentConfigStyle('NPElementViewComponent');
        if (override_css !== undefined && override_css !== '') {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach()
            this.classes = sheet.classes;
        }
    }

    ngOnChanges(changes) {
        if (changes.element != null) {
            this.element = changes.element.currentValue;
        }
        this._initialize();
    }

    private _initialize() {
        this.caracIdStateValueList = new Map<string, CaracIdStateValue[]>();
        this.allBlocInfo = [];
        if (this.element != null && this.caracConfig != null) {
            const valueHelper = new ValueOneElementHelper();
            let caracFilter;
            if (this.caracConfigKey != null) {
                caracFilter = this.caracConfig.filter(caracConfig => caracConfig.Bloc.startsWith(this.caracConfigKey));
            } else {
                caracFilter = this.caracConfig;
            }

            caracFilter.forEach((caracConfig) => {
                const value: NPCaracValeur = valueHelper.getCaracValue(this.element, caracConfig);
                if (caracConfig.BlocInfo === undefined) {
                    caracConfig.BlocInfo = caracConfig.Bloc;
                }

                // if (value) {
                // si le bloc info n'existe pas => on l'ajoute
                if (!this.caracIdStateValueList.has(caracConfig.BlocInfo)) {
                    this.caracIdStateValueList.set(caracConfig.BlocInfo, []);
                    this.allBlocInfo.push(caracConfig.BlocInfo);
                }
                // Rajouter config
                this.caracIdStateValueList.get(caracConfig.BlocInfo).push({
                    value: value,
                    config: caracConfig,
                    element: this.element,
                    baseElement: null // au moment de le faire, je n'ai pas su le propager partout, il faudrait rajouter baseElement à np-element-view

                });

                // }
            });

            this.caracIdStateValueList.forEach((value, key) => {
                const result: Block[] = [];
                let currentCaracIdStateValue = [];
                let currentBlockColumns = '';
                value.forEach((caracIdStateValue) => {
                    if (caracIdStateValue.config.Columns === currentBlockColumns) {
                        currentCaracIdStateValue.push(caracIdStateValue);
                    } else {
                        if (currentCaracIdStateValue.length > 0) {
                            result.push({
                                caracs: currentCaracIdStateValue,
                                style: NPElementViewComponent._getStyle(currentBlockColumns)
                            });
                        }
                        currentCaracIdStateValue = [];
                        currentCaracIdStateValue.push(caracIdStateValue);
                        currentBlockColumns = caracIdStateValue.config.Columns;
                    }
                });
                result.push({
                    caracs: currentCaracIdStateValue,
                    style: NPElementViewComponent._getStyle(currentBlockColumns)
                });
                this.caracWithStyles.set(key, result);
            });
        }
    }

    private static _getStyle(columns: string) {
        if (columns === 'Full') {
            return {
                '-moz-columns': 1,
                'webkit-columns': 1,
                'columns': 1
            };
        }
        return undefined;
    }
    getStyleOfFillRate(cValue: CaracIdStateValue) {
        if (cValue && cValue.config.DicoCarac && cValue.config.DicoCarac.TypeCode.toString() === 'LIENPP') {
            if (!this._mediaFillRateService.isNotFullFillRate(cValue) && this._mediaFillRateService.selectFillRate.some(cc => cc === cValue.value.DicoCaracExtID)) {
                return true;
            }
            return this._mediaFillRateService.isNotFullFillRate(cValue);
        }
        return this._mediaFillRateService.isNotFullFillRate(cValue);
    }
}
