import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProfilDto} from '../model/import-export-profile.model';
import {Observable, of, throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NpImportExportProfilService {

    constructor(private _http: HttpClient) {}

    public getImportProfiles(): Observable<ProfilDto[]> {
        return this._http.get<ProfilDto[]>('/api/sdk/profil/import/GetAllProfils');
    }
    public getByExtId(profileExtId: string): Observable<ProfilDto> {
        return this._http.post<ProfilDto>(`/api/sdk/profil/GetByExtId`, {ProfilExtId: profileExtId})
            .pipe(
                switchMap(profile => {
                    return !!profile ? of(profile) : throwError('Profile not found.');
                })
            );
    }

    public saveProfil(profilExtId: ProfilDto): Observable<ProfilDto> {
        return this._http.post<ProfilDto>(`/api/sdk/profil/save`, profilExtId);
    }

}
