import {createAction, props} from '@ngrx/store';
import {CharTemplateDto} from '@app/models';

export const FetchMediaCharTemplates = createAction(
    '[Media Char Templates] Fetch Media Char Templates'
);

export const FetchMediaTemplatesSuccess = createAction(
    '[Media Char Templates] Fetch Success',
    props<{ charTemplates: CharTemplateDto[] }>()
);

export const FetchMediaCharTemplatesFailure = createAction(
    '[Media Char Templates] Fetch Failure',
    props<{ message: string }>()
);

