import { ChapterPortal, PagePortal } from '@lib/models';

export class ChapterPortalBuilder {
    private _name: string;
    private _id: string;
    private _channelCategoryID: number;
    private _channelCategoryExtID: string;
    private _pages: PagePortal[];

    withId(value: string): ChapterPortalBuilder {
        this._id = value;
        return this;
    }

    withName(value: string): ChapterPortalBuilder {
        this._name = value;
        return this;
    }

    withChannelCategoryID(value: number): ChapterPortalBuilder {
        this._channelCategoryID = value;
        return this;
    }

    withChannelCategoryExtID(value: string): ChapterPortalBuilder {
        this._channelCategoryExtID = value;
        return this;
    }

    withPages(value: PagePortal[]): ChapterPortalBuilder {
        this._pages = value;
        return this;
    }

    build(): ChapterPortal {
        return {
            Id: this._id,
            Name: this._name,
            ChannelCategoryID: this._channelCategoryID,
            ChannelCategoryExtID: this._channelCategoryExtID,
            Pages: this._pages
        }
    }
}
