<mat-dialog-content id="dialog-page">
  <div class="content-information menu-sidenav-content">
    <div class="title-information">
      <h4>{{ data.label }}</h4>
      <div>
        <img mat-dialog-close="true" src="./assets/icons/x-mark.svg" class="fontawesome-icon-custom"
             alt="close">
      </div>

    </div>
    <mat-card class="mat-card-container">
      <mat-card-content>
        <div class="content-information-details">
          <div class="edit-image-container" *ngIf="mainVisualCaracValue">
              <lib-app-value-view [caracIdStateValue]="mainVisualCaracValue"></lib-app-value-view>
          </div>
          <div class="information-details">
            <div *ngFor="let value of caracValues" class="pField">
              <div class="strongField">{{value.config.Label}} :</div>
              <div class="charac-value mb-2">
                <lib-app-value-view [caracIdStateValue]="value"></lib-app-value-view>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'common.cancel' | translate }}</button>
  <button
    mat-raised-button
    cdkFocusInitial
    color="primary"
    (click)="save(true)"
  >
  {{ 'common.save' | translate }}
  </button>
</mat-dialog-actions>
