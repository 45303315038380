export enum SearchDisplayFieldType {
  ID = 1,
  ExtId = 2,
  Label = 3,
  DicoCarac = 4,
  WFStatus = 5,
  CreationDate = 6,
  ModificationDate = 7,
  ElementType = 8,
  NativeLanguageID = 9,
}
