import { Chars, Item } from '../types';

export class CharsBuilder {
  private _logicalOperator: string;
  private _items: Item[];

  withLogicalOperator(value: string): CharsBuilder {
    this._logicalOperator = value;
    return this;
  }

  withItems(value: Item[]): CharsBuilder {
    this._items = value;
    return this;
  }

  build(): Chars {
    return {
      LogicalOperator: this._logicalOperator ?? 'or',
      Items: this._items ?? [],
    };
  }
}
