import {Component, Input, OnInit} from '@angular/core';
import {CaracInfo, SummaryElement} from '../np-value/Model';
import {
    CaracConfig,
    ElementRepository,
    ElementWriterService,
    NPCaracLien,
    NPCaracStd,
    UiTranslationService,
    ValueOneElementHelper
} from '@nextpage/np-sdk-data';

@Component({
    selector: 'lib-link-carac-writer-v2',
    templateUrl: './link-carac-writer-v2.component.html',
    styleUrls: ['./link-carac-writer-v2.component.scss']
})
export class LinkCaracWriterV2Component implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracStd;
    @Input() caracConfig: CaracConfig;
    @Input() displayFullScreen = false;

    listPossiblesFiltered: SummaryElement[] = [];
    listLinked: SummaryElement[] = [];
    voe: ValueOneElementHelper = new ValueOneElementHelper();
    public isValid = true;
    private _translations = new Map<string, string>();

    constructor(private _elementRepository: ElementRepository,
                private _elementWriter: ElementWriterService,
                private _translateSrv: UiTranslationService) {
    }

    ngOnInit() {
        this._initializeListeLies();
    }

    /**
     * Initialisation de la liste des éléments liés
     */
    private _initializeListeLies() {
        const tmpRebuildLinkedElements: NPCaracLien = <NPCaracLien>this.voe.getCaracValue(this.value.Element, this.caracConfig);
        if (tmpRebuildLinkedElements && tmpRebuildLinkedElements.RebuildLinkedElements) {
            this.listLinked = tmpRebuildLinkedElements.RebuildLinkedElements
                .map(rel => {
                    return {
                        elementName: this.voe.getLabel(rel.Element),
                        elementID: rel.Element.ID,
                        elementOrder: rel.Order,
                        element: rel.Element
                    };
                });
            this._sortListLinkedByOrder();

        } else {
            this.listLinked = [];
        }
    }

    /**
     * Tri de la liste des éléments liés
     */
    private _sortListLinkedByOrder() {
        this.listLinked.sort((e1, e2) => e1.elementOrder > e2.elementOrder ? -1 : 1);
    }


    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }

}
