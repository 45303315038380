import {createFeatureSelector, createSelector} from '@ngrx/store';
import {mediaLibraryFeatureKey, SearchMediaState} from '../reducers/media-library.reducer';

const selectState = createFeatureSelector<SearchMediaState>(
    mediaLibraryFeatureKey
);

export const selectMediaSearchResult = createSelector(
    selectState, (state) => state
);
