import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {IPublicationTemplateApiResult} from '../model/np-api-result/publication-templates-result.model';
import {mergeMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {PublicationTemplateDto} from '../model';

@Injectable({
    providedIn: 'root'
})
export class NpPublicationTemplateService {

    constructor(private _http: HttpClient) {
    }

    public getTemplatesByNames(templateNames: string[]): Observable<PublicationTemplateDto[]> {
        return this._http.post<IPublicationTemplateApiResult>('/api/sdk/inddtemplate/GetByModelNames',
            {
                InddFileNames: _.compact(templateNames)
            })
            .pipe(
                mergeMap(data => {
                    if (!_.isEmpty(data?.Results?.Errors)) {
                        return throwError(data.Results.Errors);
                    }
                    return of(data?.Results?.Templates || []);
                })
            );
    }
}
