import { TemplatePortal } from '@lib/models';

export class TemplatePortalBuilder {
    private _id: number;
    private _path: string;
    private _numberOfElements: number;
    private _maxCharactersLength: number;

    withId(value: number): TemplatePortalBuilder {
        this._id = value;
        return this;
    }

    withPath(value: string): TemplatePortalBuilder {
        this._path = value;
        return this;
    }

    withNumberOfElements(value: number): TemplatePortalBuilder {
        this._numberOfElements = value;
        return this;
    }

    withMaxCharactersLength(value: number): TemplatePortalBuilder {
        this._maxCharactersLength = value;
        return this;
    }

    build(): TemplatePortal {
        return {
            Id: this._id,
            Path: this._path,
            NumberOfElements: this._numberOfElements,
            MaxCharactersLength: this._maxCharactersLength
        }
    }
}
