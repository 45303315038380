import {ParamsFilterBuilder} from '@data/builders';
import {DicoCarac} from '../../lib';
import * as _ from 'lodash';

export function cloneAdvSearchParam(
    paramsToClone: ParamsFilterBuilder,
    configs?: {
        fieldsToDisplay?: DicoCarac[],
        pageSize?: number,
        page?: number
    }): ParamsFilterBuilder {

    if (!paramsToClone) {
        throw new Error('paramToClone must be provided');
    }

    // Clone the object and apply the new configurations
    const clonedParam = _.cloneDeep(paramsToClone);

    return clonedParam
        .withFieldsToDisplay(configs.fieldsToDisplay || [])
        .withPage(configs.page || 0)
        .withNumberOfElementByPage(configs.pageSize || null);
}
