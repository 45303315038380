import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CatalogPage} from '@app/models';
import {NpInstanceService} from '@data/services';

@Component({
    selector: 'app-dialog-page-zoom',
    templateUrl: './dialog-page-zoom.component.html',
    styleUrls: ['./dialog-page-zoom.component.scss']
})
export class DialogPageZoomComponent {

    public imageTemplateByDefault = this._instanceService.getPublicationImageTemplateByDefault();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CatalogPage,
        private _instanceService: NpInstanceService) {}

}
