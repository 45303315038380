import { ExportMediasParam } from "./export-medias.param.model";

export class ExportParams {
    mediasParams: ExportMediasParam;
    emailParam: string;

    constructor(mediasParams?: ExportMediasParam, emailParam?: string) {
        this.emailParam = emailParam || '';
        this.mediasParams = mediasParams || new ExportMediasParam('');
    }
}
