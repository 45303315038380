<!--Bloc vidéos-->
<div class="videos-block">
  <!--Ajout vidéo-->
  <div class="videos-header" *ngIf="isWriteMode()">
    <div class="input-video">
      <input type="url" placeholder="Insérer votre lien vidéo" npUiTranslate="_ScreenInsererLienVideo" translateIn="placeholder" [(ngModel)]="newVideo" />
      <input type="submit" value="Ajouter" npUiTranslate="_ScreenAjouter" translateIn="value" (click)="addVideo()" />
    </div>
  </div>
  <!--Preview vidéo-->
  <div class="videos-content-wrapper" *ngIf="videos.length > 0">
    <div class="videos-content" *ngFor="let video of videos; let i = index">
      <button type="button" value="Supprimer" (click)="deleteVideo(i)" *ngIf="isWriteMode()">X</button>
      <iframe [src]="videoUrl(video)" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div>
  </div>
</div>