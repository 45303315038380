import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {
    FetchMediaCharTemplates,
    FetchMediaCharTemplatesFailure,
    FetchMediaTemplatesSuccess
} from './media-char-template.actions';
import {selectAdvancedSearch} from '../../advanced-search';
import * as _ from 'lodash';
import {ProductsFacade} from '@data/facades';
import {NPElementType} from '../../../../lib';

@Injectable()
export class MediaCharTemplateEffect {

    constructor(
        private actions$: Actions,
        private _store: Store,
        private productsFacade: ProductsFacade
    ) {
    }


    fetchMediaCharTemplates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchMediaCharTemplates),
            switchMap(() => this._store.select(selectAdvancedSearch)
                .pipe(filter(result => !!result))
            ),
            switchMap(result => {
                    if (!_.isEmpty(result.Config)) {
                        result.Config.Filters.ElementTypes = [NPElementType.Media];
                        return this.productsFacade.getCharTemplatesByAdvSearch(null, result?.Config).pipe(
                            map(charTemplates => FetchMediaTemplatesSuccess({charTemplates})),
                            catchError(error => of(FetchMediaCharTemplatesFailure({message: error.toString()}))));
                    } else {
                        throw Error('Advanced Search config null or not valid');
                    }
                }
            )
        );
    });
}
