import {Injectable} from '@angular/core';

import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {
    SearchMedia,
    SearchMediaFailure,
    SearchMediaSuccess,
} from '@store/action';
import {MediaFacade} from '@data/facades';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectMediaPreviewConfigs} from '@store/selector';
import * as _ from 'lodash';
import {
    selectExportSelection,
    selectIsNotSelectAllModel,
}
from '@data/selection';
import {AdvSearchArgs} from '@lib/models';

@Injectable()
export class MediaLibraryEffects {
    constructor(private actions$: Actions,
                private _store: Store,
                private _mediaFacade: MediaFacade,
                ) {
    }

    searchMedia$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchMedia),
            concatLatestFrom(() => [
                this._store.select(selectMediaPreviewConfigs)
                    .pipe(filter(result => !!result.mainVisualCaracExtId)),
                this._store.select(selectExportSelection),
                this._store.select(selectIsNotSelectAllModel)
            ]),
            mergeMap((
                [
                    action,
                    mediaPreviewParams,
                    selectionView
                ]) => {
                    const _advSearch = _.cloneDeep<AdvSearchArgs>(action.advancedSearch);
                    _advSearch.DCExtIDs = mediaPreviewParams.previewCaracs;

                    return this._mediaFacade.search(_advSearch, action.multiOperators, selectionView.isSelectAll)
                        .pipe(
                            map(searchResult => {
                                searchResult.cards.forEach(card => {
                                    card.selected = selectionView.isSelectAll || selectionView.elementIds.some(id => id === card.element.ID);

                                    // Element to exclude
                                    if (selectionView.isSelectAll && selectionView.excludedElementIds.some(id => id === card.element.ID)) {
                                        card.selected = false;
                                    }
                                });

                               return SearchMediaSuccess({searchResult});
                            })
                        );
                }
            ),
            catchError(error => of(SearchMediaFailure({message: error?.toString()})))
        );
    });
}
