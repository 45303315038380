import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalSkeletonComponent} from './components/portal-skeleton/portal-skeleton.component';
import {SharedModule} from './shared/shared.module';
import {HomePageFilesViewComponent} from './components/home-page-files-view/home-page-files-view.component';
import {FileItemViewComponent} from './components/home-page-files-view/file-item-view/file-item-view.component';
import {DialogElementsExportComponent} from './components/dialog-elements-export/dialog-elements-export.component';
import {DialogMessageViewComponent} from './components/dialog-message-view/dialog-message-view.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
    faCircleChevronLeft as falCircleChevronLeft,
    faCircleChevronRight as falCircleChevronRight,
    faChevronDown as falChevronDown,
    faPen as falPen
} from '@fortawesome/pro-light-svg-icons';
import {
    faUser as farUser,
    faQuestion as farQuestion,
  } from '@fortawesome/pro-regular-svg-icons';

import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faUser,
    faChevronDown,
    faQuestion,
    faPen
} from '@fortawesome/pro-solid-svg-icons';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        PortalSkeletonComponent,
        HomePageFilesViewComponent,
        FileItemViewComponent,
        DialogMessageViewComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        DialogElementsExportComponent,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        PortalSkeletonComponent,
        HomePageFilesViewComponent
    ]
})
export class GraphicsModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
          faCircleChevronLeft,
          falCircleChevronLeft,
          faCircleChevronRight,
          falCircleChevronRight,
          faUser,
          farUser,
          faChevronDown,
          falChevronDown,
          faQuestion,
          farQuestion,
            faPen,
            falPen
        );
    }
}
