<div class="d-flex justify-content-between align-items-center">
  <h2 mat-dialog-title>{{ 'publication.header.catalogue-generation-impossible' | translate }}</h2>
  <button mat-dialog-close class="close-btn">
    <fa-icon class="d-flex" [icon]="['far', 'xmark']"></fa-icon>
  </button>
</div>
<mat-dialog-content class="dialog-content">
  <ng-container *ngIf="data?.emptyPages?.size > 0">
    {{ 'publication.header.empty-pages-text' | translate }}
    <ul>
      <li *ngFor="let emptyPage of data.emptyPages | keyvalue : keepOrder" 
      [innerHTML]="'publication.header.empty-pages-list' | translate : { chapterName : emptyPage.key, pageName: emptyPage.value.join(', ') }"></li>
    </ul>
</ng-container>
<ng-container *ngIf="data?.pagesWithMissingProducts?.size > 0">
  {{ 'publication.header.pages-missing-products-text' | translate }}
  <ul>
    <li *ngFor="let pageWithMissingProducts of data.pagesWithMissingProducts | keyvalue : keepOrder" 
    [innerHTML]="'publication.header.empty-pages-list' | translate : { chapterName : pageWithMissingProducts.key, pageName: pageWithMissingProducts.value.join(', ') }"></li>
  </ul>
</ng-container>
</mat-dialog-content>
