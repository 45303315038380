import {createAction, props} from '@ngrx/store';
import { TreeNodeCatalog } from '@app/models';

export const FetchCatalogTree =
    createAction('[Catalog Tree] Fetch Catalog Tree',
        props<{ parentId: number }>());

export const FetchCatalogTreeSuccess =
    createAction('[Catalog Tree] Fetch Catalog TreeSuccess',
        props<{ catalogTree: TreeNodeCatalog[] }>());

export const FetchCatalogTreeFailure =
    createAction('[Catalog Tree] Fetch Catalog Tree Failure',
        props<{ message: any }>());

export const DuplicateCatalogTree =
    createAction('[Catalog Tree] Duplicate Catalog Tree',
        props<{ catalogId: number }>());

export const CatalogTreeUpdate =
    createAction('[Catalog Tree] Catalog Tree Update',
        props<{ catalogTree: TreeNodeCatalog }>());

export const CatalogTreeUpdateFailure =
    createAction('[Catalog Tree] Catalog Tree Update Failure',
        props<{ message: any }>());

export const DeleteCatalogTree =
    createAction('[Catalog Tree] Delete Catalog Tree',
        props<{ catalog: TreeNodeCatalog }>());
    
export const DeleteCatalogTreeSuccess =
    createAction('[Catalog Tree] Delete Catalog Tree Update',
        props<{ catalogTree: TreeNodeCatalog }>());

export const DeleteCatalogTreeFailure =
    createAction('[Catalog Tree] Delete Catalog Tree Failure',
        props<{ message: any }>());

export const GetCatalogPdf =
    createAction('[Catalog Tree] Get Catalog Pdf',
        props<{ catalog: TreeNodeCatalog }>());

export const GetCatalogSources =
    createAction('[Catalog Tree] Get Catalog Sources',
        props<{ catalog: TreeNodeCatalog }>());

export const SetCatalogSourcesDownloadProgress = 
    createAction('[Catalog Tree] Set Catalog Sources Download Progress',
        props<{ progress: number }>());

export const GetCatalogFileSuccess =
    createAction('[Catalog Tree] Get Catalog Pdf Success');

export const GetCatalogFileFailure =
    createAction('[Catalog Tree] Get Catalog Pdf Failure',
        props<{ message: any }>());

