import {Component, Input, OnInit} from '@angular/core';
import {CaracConfig, MediaCreationService, NPCaracLien, NPElement} from '@nextpage/np-sdk-data';
import {CaracInfo} from '../np-value/Model';
import {FileReaderService} from '../../services';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'lib-np-media-writer',
    templateUrl: './np-media-writer.component.html',
    styleUrls: ['./np-media-writer.component.scss']
})
export class NpMediaWriterComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() element: NPElement;
    @Input() caracConfig: CaracConfig;

    private maxSize = 50;
    public dropzoneHovered = false;
    public fileName: string;
    public existingUrl: string;

    data: any;

    public currentFile: File;

    constructor(private _mediaService: MediaCreationService,
                private _fileReader: FileReaderService,

                public dialog: MatDialog) {
        this.data = {};
    }

    ngOnInit() {
        this.fileName = this.getMediaName();
    }

    handleHover($event: boolean) {
        this.dropzoneHovered = $event;
    }

    /**
     * Gestion upload fichier depuis la zone
     */
    handleDrop(file: File, toEdit = true) {
        // Taille max 5Mo
        const currentSizeToMegaByte = Number((file.size / 1048576).toFixed(3)); // convertir Octets en Megaoctets
        if (currentSizeToMegaByte <= this.maxSize) {
            this.fileName = file.name;
            this._mediaService.setMedias(this.element, this.caracConfig.DicoCaracExtID, [{
                File: file,
                ParentDirectoryExtID: this.caracConfig.DicoCarac.ElementLinkExtID
            }]);
        } else {
            alert('Erreur : la taille du fchier ne doit pas dépasser ' + this.maxSize + 'Mo');
        }
    }

    /**
     * Event handler pour le changement de fichier par bouton Parcourir
     * event
     */
    public onFileChange(event) {
        this.handleDrop(event.target.files[0]);
        this.currentFile = event.target.files[0];
    }

    removeFile() {
        this.fileName = null;
        this._mediaService.setMedias(this.element, this.caracConfig.DicoCaracExtID, []);
        this.currentFile = undefined;
    }

    /**
     *  l'url de la carac esistant
     *  {string}
     */
    getUrl() {
        this.existingUrl = this._fileReader.toUrlCustomImage(this.element, this.caracConfig.DicoCaracExtID);
        return this.existingUrl;
    }

    /**
     * le nom de l'image de la carac en cours
     *  {string}
     */
    getMediaName() {
        const caracValue = this.element.getValueLien(this.caracConfig.DicoCaracExtID);
        if (caracValue && caracValue.RebuildLinkedElements && caracValue.RebuildLinkedElements.length > 0) {
            return caracValue.RebuildLinkedElements[0].Element.getValueTextValue('##MediaLabel');
        }
        return null;
    }
}
