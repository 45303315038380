import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductSummary} from '@app/models';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {DialogMediaSheetComponent} from '../dialog-media-sheet/dialog-media-sheet.component';
import {NpInstanceService} from '@data/services';
import {EntityName} from '../../../../lib/data/model/portal';
import {DialogElementWriterComponent} from '../dialog-element-writer/dialog-element-writer.component';
import { selectFavoritesExtIds } from 'src/app/store/selector';
import { DialogService } from '../../services/dialog.service';
import {FavoriteActions} from '@store/favorites';

@Component({
    selector: 'app-media-card',
    templateUrl: './media-cards.component.html',
    styleUrls: ['./media-cards.component.scss'],
})
export class MediaCardsComponent {
    @Input() filteredCard: ProductSummary[];
    @Input() loading = true;

    @Output() isAllSelected = new EventEmitter<boolean>();

    public favoritesState$ = this.store.select(selectFavoritesExtIds);
    public isSupplierEntityName: boolean;
    public imageByDefault$ =  this._instanceService.getImageByDefault();

    constructor(
        private dialog: MatDialog,
        private store: Store,
        private _instanceService: NpInstanceService,
        private readonly _dialogService: DialogService
    ) {
        this.isSupplierEntityName = this._instanceService.currentEntity.name === EntityName.Supplier;
    }

    moreInfo(card) {
        if (this._instanceService.currentEntity.name === EntityName.Supplier || this._instanceService.currentEntity.name === EntityName.Consultation) {
            this._dialogService.openProductDialog(card);
        } else {
            this.dialog.open(DialogMediaSheetComponent, {
                hasBackdrop: true,
                disableClose: false,
                height: '95%',
                width: '90%',
                data: card
            });
        }
    }

    toggleFavorite(elementSummary: ProductSummary, $event: Event) {
        $event.stopPropagation();
        elementSummary.isFavoriteElement = !elementSummary.isFavoriteElement;
        this.store.dispatch(
            FavoriteActions.toggleFavorite({
                favorites: {
                    extID: elementSummary.element.ExtID
                },
            })
        );
    }

    editInfos(element: ProductSummary, $event: MouseEvent) {
        if (this.isSupplierEntityName) {
            $event.stopPropagation();
            this.dialog.open(DialogElementWriterComponent, {
                height: '95%',
                width: '80%',
                data: element
            });
        }
    }
}
