<div class="d-flex justify-content-between align-items-center">
  <h2 mat-dialog-title>{{ 'publication.catalogue.delete-product-title' | translate }}</h2>
  <button mat-dialog-close class="close-btn">
    <fa-icon class="d-flex" [icon]="['far', 'xmark']"></fa-icon>
  </button>
</div>
<mat-dialog-content class="dialog-content">
  <span [innerHTML]="'publication.catalogue.confirm-product-deletion' | translate : { productName : data.product.name }"></span>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end dialog-actions">
  <button mat-stroked-button class="cancel-btn" mat-dialog-close>{{ 'common.cancel' | translate }}</button>
  <button mat-flat-button 
    color='primary' 
    class="delete-btn"
    [mat-dialog-close]="true">
    {{ 'common.remove' | translate }}</button>
</mat-dialog-actions>
