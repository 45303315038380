import {DicoCarac, NPElementType} from '@nextpage/np-sdk-data';

export enum SpRouteName {
    LOGIN = 'login',
    RESET_PASSWORD = 'resetPassword',
    SUPPLIER_VIEW = 'supplierView',
    HOME_PAGE = 'homePage',
    PRODUCTS = 'products',
}

export const DEFAULT_AUTH_ERROR = 'Identifiant / Mot de passe incorrect';
export const USER_TOKEN = '@**************UT*********--+++1020228Unb';

export const ElementLabels = {
    [NPElementType.Family]: DicoCarac.FAMILY_LABEL,
    [NPElementType.Product]: DicoCarac.PRODUCT_LABEL,
    [NPElementType.Reference]: DicoCarac.REFERENCE_LABEL,
    [NPElementType.Media]: DicoCarac.MEDIA_LABEL,
};

export enum NpComponents {
    'LinkCaracWriterV2Component' = 'LinkCaracWriterV2Component',
    'LinkCaracWriteComponent' = 'LinkCaracWriteComponent',
}

export enum SpDicoCarac {
    CP_EXT_ID_PRODUIT = 'CP_EXTIDPRODUIT',
    CP_EXT_ID_VISUEL = 'CP_EXTIDVISUEL',
    CP_PROFILE_EXPORT = 'CP_PROFILEXPORT',
    CP_EXT_ID_PDF = 'CP_EXTIDPDF',
    CP_EXT_ID_FAMILLE_FOURNISSEUR = 'CP_EXTIDFAMFOURNISSEUR',
    CP_RAISON_SOCIAL = 'DC_F_RAISONSOCIALEFOURNISSEUR',
    CP_ADRESSE = 'DC_F_ADRESSEFOURNISSEUR',
    CP_CODEPOSTAL = 'DC_F_CODEPOSTALFOURNISSEUR',
    CP_VILLE = 'DC_F_VILLEFOURNISSEUR',
    CP_LOGO = 'DC_F_LOGOFOURNISSEUR',
    CP_EXTID_CHANNEL = 'CP_EXTID_CHANNEL',
    CP_ID_MEDIAS = 'CP_ID_MEDIAS',
    CP_PROFIL_IMPORT_AVANCE = 'CP_PROFIL_IMPORT_AVANCE',
    CP_SCOPE_MEDIAS = 'CP_SCOPE_MEDIAS',
    CP_REQUETESACCUEIL = 'CP_REQUETESACCUEIL',
    CP_DOCUMENTSACCUEIL = 'CP_DOCUMENTSACCUEIL',
    CP_LISTE_EXTID_CARAC = 'CP_LISTEEXTIDCARAC',
    CP_LISTE_EXTID_CARAC_MEDIA = 'CP_LISTEEXTIDCARAC_MEDIA',
    CP_EXTID_CANAL_PUBLICATION = 'CP_EXTID_CANAL_PORTAIL_PUBLICATION',
    CP_EXTID_DESCRIPTIF_PUBLICATION = 'CP_EXTID_DESCRIPTIF_PUBLICATION',
    CP_ID_DOSSIER_CATALOGUES = 'CP_ID_DOSSIER_CATALOGUES',
    CP_EXTID_MODELE_PAGE_EDITO = 'DC_C_MODELE_DE_PAGE_EDITO',
    CP_EXTID_MODELE_PAGE_COUVERTURE = 'DC_C_MODELE_DE_PAGE_COUVERTURE',
    CP_EXTID_MODELE_PAGE_FIN_INTRO = 'DC_C_MODELES_DE_PAGE_FIN_INTRODUCTION',
    CP_EXTID_MODELE_PAGE_PRODUITS = 'DC_RC_MODELES_DE_PAGE_PRODUIT',
    CP_EXTID_ADHERENT = 'CP_EXTID_ADHERENT',
    CP_EXTID_LOGO_ADHERENT = 'CP_EXTID_LOGO_ADHERENT'
}

export const DEFAULT_CARAC_EXTIDS = [
    DicoCarac.PRODUCT_LABEL,
    DicoCarac.REFERENCE_LABEL,
    DicoCarac.DTO_SYSTEM_LAST_MODIFICATION_DATE,
    DicoCarac.MEDIA_LABEL,
    DicoCarac.MEDIA_FILE,
    DicoCarac.DTO_SYSTEM_STATUS
];

export const DEFAULT_CARAC_EXTIDS_PUBLICATION = [
    DicoCarac.PRODUCT_LABEL,
    DicoCarac.MEDIA_LABEL,
    DicoCarac.MEDIA_FILE
];

export enum AdvancedSearchSystem {
    MES_DERNIERE_MODIF = 'Mes dernières modifications',
    DERNIERE_MODIF = 'Dernières modifications',
    TACHES_WORKFLOW = 'Mes taches de workflow',
    DELTA_SYNCH = 'Delta de synchronisation',
}

export enum WidgetTypes {
    WG = 1,
    EP = 2,
    ER = 3,
    TCP = 4,
    TCR = 5,
}

export const COLORRATETAB = [
    'rgb(166, 15, 131)',
    'rgb(233, 210, 23)',
    'rgb(233, 152, 37)',
    'rgb(8, 133, 152)',
    'rgb(53, 214, 4)',
];

export const WIDGETLISTSTATIC = [
    {
        ID: 1,
        Title: 'Widget générique',
        Description:
            'widget générique permet de lancer des requetes pour la recherche avancée',
        WidgetType: 'WG',
    },
    {
        ID: 2,
        Title: 'Etat des produits',
        Description: 'Cette widget permet de présenter l\'état des produits',
        WidgetType: 'EP',
    },
    {
        ID: 3,
        Title: 'Etat des références',
        Description: 'Cette widget permet de présenter l\'état des références',
        WidgetType: 'ER',
    },
    {
        ID: 4,
        Title: 'Taux de complétude GLOBAL produits',
        Description:
            'Cette widget permet de présenter taux de complétude des produits',
        WidgetType: 'TCP',
    },
    {
        ID: 5,
        Title: 'Taux de complétude GLOBAL références',
        Description:
            'Cette widget permet de présenter taux de complétude des références',
        WidgetType: 'TCR',
    },
];

export const LISTINDICATEURSYSTEM = [
    {
        Name: 'Mes dernières modifications',
        ID: 0,
        TransalteName: '_MyLastModifications',
    },
    {
        Name: 'Dernières modifications',
        ID: 0,
        TransalteName: '_LastModifications',
    },
    {Name: 'Mes taches de workflow', ID: 0, TransalteName: '_MyWorkflowTasks'},
    {Name: 'Delta de synchronisation', ID: 0, TransalteName: '_DeltaSynchro'},
];

export const INDICATEURSYSTEMSTRING = '_SystemIndicators';

export const SAVEDSEARCHSTRING = '_SavedSearches';

export const INITSEARCHGROUP = [
    {
        name: INDICATEURSYSTEMSTRING,
        search: LISTINDICATEURSYSTEM,
    },
    {
        name: SAVEDSEARCHSTRING,
        search: [],
    },
];

export const INITFILTERGROUP = [
    {
        name: ' ',
        search: [{Name: 'Filtre ....', ID: 0, TranslateName: '_Filter'}],
    },
    {
        name: '_MySavedSearches',
        search: [],
    },
    {
        name: '_UsersSearches',
        search: [],
    },
];

export const STATICOPTIONSCHART = {
    maintainAspectRatio: false,
};

export const LISTCOLORCHART = [
    'rgba(219, 1, 125, 1)',
    'rgba(3, 169, 193, 1)',
    'rgba(14, 104, 117, 1)',
    'rgba(117, 105, 8, 1)',
    'rgba(255, 218, 0, 1)',
    'rgba(110, 114, 20, 1)',
    'rgba(118, 183, 172, 1)',
    'rgba(7, 143, 0, 1)',
    'rgba(10, 219, 0, 1)',
    'rgba(92, 0, 52, 1)',
    'rgba(194, 174, 14, 1)',
    'rgba(194, 66, 43, 1)',
    'rgba(117, 35, 20, 1)',
    'rgba(242, 211, 172, 1)',
    'rgba(115, 81, 52, 1)',
    'rgba(191, 151, 117, 1)',
    'rgba(241, 67, 100, 1)',
    'rgba(241, 103, 130, 1)',
    'rgba(241, 144, 163, 1)',
    'rgba(51, 202, 235, 1)',
    'rgba(13, 127, 140, 1)',
    'rgba(3, 104, 115, 1)',
    'rgba(156, 211, 217, 1)',
    'rgba(13, 140, 140, 1)',
];

export const DEFAULT_SEPARATOR = ';';
