<p-overlayPanel #op styleClass="edit-catalogue__overlay">
    <ng-template pTemplate="content">
        <mat-card class="edit-catalogue__container">
            <mat-card-header class="align-items-center justify-content-between edit-catalogue__header">
                <mat-card-title class="edit-catalogue__title">{{ 'publication.catalogue.catalogue' | translate }}</mat-card-title>
                <fa-icon [icon]="['far', 'xmark']" size="lg" (click)="closeOverlay()"></fa-icon>
            </mat-card-header>

            <mat-card-content class="edit-catalogue__content">
                <form class="d-flex flex-column" [formGroup]='catalogForm'>
                    <mat-form-field class="mb-2" appearance='outline'>
                      <mat-label>{{ 'common.fields.catalogue-name' | translate }}</mat-label>
                      <input matInput type='text' formControlName='catalogName' required />
                      <mat-error>{{ 'common.fields.required-field' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance='outline'>
                      <mat-label>{{ 'common.fields.catalogue-description' | translate }}</mat-label>
                      <textarea rows="2" matInput formControlName='catalogDescription'></textarea>
                    </mat-form-field>

                    <mat-label class="fw-600">{{ 'common.fields.catalogue-chapters' | translate }}</mat-label>
                    <ng-container *ngIf="(chaptersView$ | async) as chaptersView; else noResult">
                        <ul class="list-unstyled edit-catalogue__chapters-list">
                            <li *ngFor="let chapter of chaptersView.chaptersWithoutIntroAndLast">
                                <mat-checkbox class="chapter-checkbox" color="primary" checked [formControlName]="chapter.channelCategoryID">
                                    {{chapter?.nameWithoutTag || chapter?.name}}
                                </mat-checkbox>
                            </li>
                            <li *ngFor="let remainingChapter of chaptersView.remainingChapters">
                                <mat-checkbox class="chapter-checkbox" color="primary" [formControlName]="remainingChapter.channelCategoryID">
                                    {{remainingChapter?.name}}
                                </mat-checkbox>
                            </li>
                        </ul>
                    </ng-container>
                                        
                    <ng-template #noResult>
                        <div>{{'publication.homepage.no-chapter-found' | translate}}</div>
                    </ng-template>
                  </form>
            </mat-card-content>

            <mat-card-actions class="justify-content-end  edit-catalogue__actions">
                <button mat-stroked-button class="cancel-btn" (click)="closeOverlay()">{{ 'common.cancel' | translate }}</button>
                <button mat-flat-button 
                color='primary' 
                class="validate-btn"
                type='submit'
                (click)="editCatalogue()"
                [disabled]='catalogForm.invalid || (loading$ | async)'>
                <span *ngIf='(loading$ | async)'
                class='spinner-border spinner-border-sm mr-1'></span>
                {{ 'common.validate' | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </ng-template>
</p-overlayPanel>
