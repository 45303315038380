import { NPElementType } from '@nextpage/np-sdk-data';
import { Chars, Family, Filters } from '../types';

export class FiltersBuilder {
  private _ElementType: NPElementType;
  private _ElementTypes: NPElementType[];
  private _CreatorID: number;
  private _CreationDate: string;
  private _CreationDateOperator: string;
  private _ModificationDate: string;
  private _ModificationDateOperator: string;
  private _WFStatusID: number;
  private _WFStatusOperator: string;
  private _Family: Family;
  private _Channel: Family;
  private _ProductsTypeID: number;
  private _Keywords: string;
  private _Chars: Chars;
  private _Facets: string[];

  withElementType(value: NPElementType): FiltersBuilder {
    this._ElementType = value;
    return this;
  }

  withElementTypes(value: NPElementType[]): FiltersBuilder {
    this._ElementTypes = value;
    return this;
  }

  withCreatorID(value: number): FiltersBuilder {
    this._CreatorID = value;
    return this;
  }

  withCreationDate(value: string): FiltersBuilder {
    this._CreationDate = value;
    return this;
  }

  withCreationDateOperator(value: string): FiltersBuilder {
    this._CreationDateOperator = value;
    return this;
  }

  withModificationDate(value: string): FiltersBuilder {
    this._ModificationDate = value;
    return this;
  }

  withModificationDateOperator(value: string): FiltersBuilder {
    this._ModificationDateOperator = value;
    return this;
  }

  withWFStatusID(value: number): FiltersBuilder {
    this._WFStatusID = value;
    return this;
  }

  withWFStatusOperator(value: string): FiltersBuilder {
    this._WFStatusOperator = value;
    return this;
  }

  withFamily(value: Family): FiltersBuilder {
    this._Family = value;
    return this;
  }

  withChannel(value: Family): FiltersBuilder {
    this._Channel = value;
    return this;
  }

  withProductsTypeID(value: number): FiltersBuilder {
    this._ProductsTypeID = value;
    return this;
  }

  withKeywords(value: string): FiltersBuilder {
    this._Keywords = value;
    return this;
  }

  withChars(value: Chars): FiltersBuilder {
    this._Chars = value;
    return this;
  }

  withFacets(value: string[]): FiltersBuilder {
    this._Facets = value;
    return this;
  }

  build(): Filters {
    return {
      ElementType: this._ElementType ?? 0,
      ElementTypes: this._ElementTypes,
      CreatorID: this._CreatorID,
      CreationDate: this._CreationDate,
      CreationDateOperator: this._CreationDateOperator,
      ModificationDate: this._ModificationDate,
      ModificationDateOperator: this._ModificationDateOperator,
      WFStatusID: this._WFStatusID,
      WFStatusOperator: this._WFStatusOperator,
      Family: this._Family,
      ProductsTypeID: this._ProductsTypeID,
      Keywords: this._Keywords,
      Chars: this._Chars,
      Facets: this._Facets,
      Channel: this._Channel
    };
  }
}
