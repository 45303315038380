<div class="section-block">
  <span *ngIf="!isValid"  class="requied-error-message inpu-custo">Champ obligatoire</span>
  <section class="check-section">
    <mat-checkbox *ngFor="let possible of listPossiblesFiltered; index as index" class="example-margin"
                  (change)="check($event, possible)" [checked]="isChecked(possible)" color="primary" >
    <span>
      {{possible.elementName}}
    </span>
    </mat-checkbox>
  </section>
</div>
