<div id="login-container" [ngStyle]="instanceBg$ | async">
  <mat-card class='authentication-card mx-auto'>
    <mat-card-header class='authentication-card-header'>
      <mat-card-title class='header-image'>
        <img [attr.src]="logoUrl$ | async" alt='Logo Nextpage' />
      </mat-card-title>
      <mat-card-title class='header-title'>{{ 'common.portal' | translate }}</mat-card-title>
    </mat-card-header>
    <form [formGroup]='loginForm' (ngSubmit)='onSubmit()'>
      <mat-card-content class='authentication-card-content'>
        <mat-form-field appearance='outline'>
          <mat-label>{{ 'common.fields.username' | translate }}</mat-label>
          <mat-icon matSuffix>person_outline</mat-icon>
          <input matInput type='text' formControlName='login' required />
          <mat-error>{{ 'common.fields.required-field' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance='outline'>
          <mat-label>{{ 'common.fields.password' | translate }}</mat-label>
          <input
                  matInput
                  type='password'
                  formControlName='password'
                  required
                  [type]="hidePassword ? 'password' : 'text'"
          />
          <button
                  mat-icon-button
                  matSuffix
                  type='button'
                  (click)='hidePassword = !hidePassword; $event.preventDefault()'
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]='hidePassword'
          >
            <mat-icon>{{
              hidePassword ? "visibility_off" : "visibility"
              }}</mat-icon>
          </button>
          <mat-error>{{ 'common.fields.required-field' | translate }}</mat-error>
        </mat-form-field>
        <div *ngIf="currentUiLanguageView$ | async as selectedLang">
          <mat-form-field appearance='outline'>
            <mat-label>{{ 'common.fields.select-language' | translate }}</mat-label>
            <mat-select #languagesList
                        [value]="selectedLang"
                        formControlName="selectedLang">
              <ng-container *ngFor="let language of languages">
                <mat-option value="{{language.code}}">{{language.label}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-error *ngIf='(errorMessage$ | async)' class='errorMessage'>
          {{ 'authentication.wrong-credentials' | translate }}
        </mat-error>
      </mat-card-content>
      <div class='buttonForgotPassword'>
        <button
                mat-button
                type='button'
                (click)='openResetPasswordDialog(); $event.preventDefault()'>
                {{ 'authentication.forgot-password' | translate }}
        </button>
      </div>
      <mat-card-actions class='authentication-card-buttons'>
        <button type='submit'
                class='buttonConnection'
                color='primary'
                mat-raised-button
                [disabled]='loginForm.invalid || (loading$ | async)'
        >
        <span
                *ngIf='(loading$ | async)'
                class='spinner-border spinner-border-sm mr-1'
        ></span>
        {{ 'authentication.log-in' | translate }}
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
