<div id="side-page">
    <div>
        <button class="entity-btn" mat-button [matMenuTriggerFor]="menu">
            <span>{{ 'common.portal' | translate }} | </span>
            <span class="np-color">{{selectedEntity.label | translate}}</span>
            <span class="material-icons">apps</span>
        </button>
    </div>
    <mat-menu #menu="matMenu" class="entity-menu">
        <mat-list [ngClass]="customClass">
            <div mat-subheader class="entity-menu-title np-color">{{ 'sidebar.available-portals' | translate }}</div>
            <mat-selection-list [multiple]="false">
                <mat-list-option
                        *ngFor="let entity of entities"
                        [value]="entity"
                        (click)="changeEntity(entity)"
                        [selected]="entity.name === selectedEntity?.name"
                        [attr.title]="entity.description"
                        color="primary"
                >
                    <span class="material-icons" matListItemIcon>perm_media</span>
                    {{entity.label | translate}}
                </mat-list-option>
            </mat-selection-list>
        </mat-list>
    </mat-menu>
    <mat-divider class="my-3" *ngIf="!isPublicationPortal"></mat-divider>
    <ul class="list-group" *ngIf="!isPublicationPortal">
        <li *ngFor="let menuItem of menuItems$ | async"
            class="list-group-item gap-container-2 btn btn-primary"
            [ngClass]="{active: menuItem.selected}"
            (click)="menuItem.action();
             setActiveItem(menuItem)">
            <span class="material-icons" matListItemIcon>{{menuItem.iconName}}</span>
            <span>{{menuItem.label | translate }}</span>
        </li>
    </ul>
</div>
