import { Component } from "@angular/core";

@Component({
    selector: 'app-logo-headline',
    templateUrl: './logo-headline.component.html',
    styleUrls: ['./logo-headline.component.scss'],
})
export class  LogoHeadlineComponent {

    constructor() {}

}
