import { TemplateDescription } from "@app/models";

export class PublicationTemplate {
    id: number;
    inddFile: string;
    xmlFile: string;
    jpgFile: string;
    description: string;
    name: string;
    numberOfElements: number;

    constructor(params: Partial<PublicationTemplate>) {
        let descriptionJSONParsed: TemplateDescription  | null = null;
        if (params.description) {
            try {
                descriptionJSONParsed = JSON.parse(params.description);
            } catch (e) {}
        }
        
        this.id = params.id;
        this.inddFile = params.inddFile;
        this.xmlFile = params.xmlFile;
        this.jpgFile = params.jpgFile;
        this.description = params.description;
        this.name = descriptionJSONParsed?.Name || '';
        this.numberOfElements = params.numberOfElements;
    }
}
