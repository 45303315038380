/**
 * Ce composant permet d'afficher une carac de type "LIEN ou LIENPP" dans le bon composant selon sa caradinalité
 * Si la cardinalité est de 1, les données sont affichées dans le composant Select-value-lien, sinon dans LinkCaracWriteComponent
 */
import {Component, Input, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {CaracConfig, NPCaracStd} from '@nextpage/np-sdk-data';
import { NPElement } from '@nextpage/np-sdk-data';

@Component({
    selector: 'lib-link-router',
    templateUrl: './link-router.component.html',
    styleUrls: ['./link-router.component.css']
})
export class LinkRouterComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracStd;
    @Input() caracConfig: CaracConfig;
    @Input() baseElement: NPElement;

    public isCardinalityOne: boolean;

    constructor() {
    }

    ngOnInit() {
        this.isCardinalityOne = this.caracConfig &&
            this.caracConfig.DicoCarac &&
            this.caracConfig.DicoCarac.Cardinality === 1;
    }
}
