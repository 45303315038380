export class NpApiResult {
  public Errors: string[];
  public Results: any;
  public Warnings: string[];
}

export class ApiResult<T> {
  public Errors: string[];
  public Results: T;
  public Warnings: string[];
}
