import { Injectable, OnDestroy } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()

export class MatPaginatorIntlFrService extends MatPaginatorIntl implements OnDestroy {

  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;

  private translateSub: Subscription;

  constructor(private _translate: TranslateService) {
    super();

    const translationKeys = [
      'enums.pagination.items-per-page',
      'enums.pagination.next-page',
      'enums.pagination.previous-page',
      'enums.pagination.first-page',
      'enums.pagination.last-page'
    ];

    this.translateSub = this._translate.get(translationKeys).subscribe((translated: { [key: string]: string }) => {
      this.itemsPerPageLabel = translated['enums.pagination.items-per-page'];
      this.nextPageLabel = translated['enums.pagination.next-page'];
      this.previousPageLabel = translated['enums.pagination.previous-page'];
      this.firstPageLabel = translated['enums.pagination.first-page'];
      this.lastPageLabel = translated['enums.pagination.last-page'];
    });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) =>
    length === 0 || pageSize === 0 ?
      `${length}` :
      Math.max(length, 0) + ' ' + this._translate.instant('enums.pagination.total-products')

      ngOnDestroy(): void {
        this.translateSub?.unsubscribe();
      }
}

