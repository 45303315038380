import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {
    CaracConfig,
    DicocaracRepository,
    ElementWriterService,
    NPCaracListe,
    NPListeValues
} from '@nextpage/np-sdk-data';
import {filter, map} from 'rxjs/operators';
import {Sheet, ThemingService} from '../../services';
import jss from 'jss';

@Component({
    selector: 'lib-np-combobox',
    templateUrl: './np-combobox.component.html',
    styleUrls: ['./np-combobox.component.css']
})
export class NpComboboxComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracListe;
    @Input() caracConfig: CaracConfig;

    public displayValues: NPListeValues[] = [];
    public selectedValue: NPListeValues;
    public dropDownControl = new UntypedFormControl();
    private _formCtrlSub: Subscription;
    public displayRequiedError = false;
    public classes: Object;

    private _currValue = (valueID) => this.displayValues.find(value => value.ValeurID === valueID);

    constructor(private _dicoCaracRep: DicocaracRepository,
                private _eltWrSrv: ElementWriterService,
                @Inject(forwardRef(() => ThemingService)) private _theming: ThemingService) {
    }

    ngOnInit() {
        this.initValues();
        this._saveChanges();

        const override_css = this._theming.getComponentConfigStyle(this.constructor.name);
        if (override_css !== undefined) {
            const sheet: Sheet = jss.createStyleSheet(override_css, {link: true}).attach();
            this.classes = sheet.classes;
        }
    }

    private _saveChanges() {
        this._formCtrlSub = this.dropDownControl.valueChanges
            .pipe(
                filter(data => data !== undefined && data !== null),
                map(data => Number(data))
            )
            .subscribe(selectedValueID => {
                this.selectOption(this._currValue(selectedValueID));
            });
    }

    public initValues() {
        if (this.value != null) {
            if (this.value.Element) {
                this.value.ElementExtID = this.value.Element.ExtID;
            }
            // Initialisation des valeurs à afficher
            this.displayValues = this.caracConfig.DicoCarac.Values;
            // Initialisation de la valeur enregistrée (si présente)
            if (this.value.Values != null && this.value.Values.length > 0) {
                this.selectedValue = this.displayValues.find(value => value.ValeurID === this.value.Values[0].ValueID);
                this.dropDownControl.setValue(this.selectedValue.ValeurID);
            }
        } else {
            this.displayValues = [];
        }
    }

    /**
     * Sélectionne l'option
     */
    private selectOption(value: NPListeValues) {
        this.selectedValue = value;
        this._eltWrSrv.setValueListeByIDValues(this.value.Element, this.value.DicoCaracExtID, value ? [value.ValeurID] : []);
    }
}
