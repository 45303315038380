<div class="treeview-content">
    <mat-expansion-panel *ngFor="let node of treeviewData" class="mat-elevation-z0">
        <mat-expansion-panel-header [ngClass]="node.indexStyle" (click)="node.opened = !node.opened ">
            <mat-panel-title>
                <div class="tree-item-icon">
                    <mat-icon>{{node.opened ? 'remove' : 'add'}}</mat-icon>
                </div>
                <span class="treeview-label">{{node.title}}</span>
                <ng-container *ngIf="node.children && node.children.length > 0">
                    <span title="Nombre de sous engrédients" class="treeview-badge">({{node.children.length}})</span>
                </ng-container>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngIf="node.hasContent && node.opened">
            <div class="tree-">
                <lib-np-content-block [element]="node.element" [contentConfigs]="caracConfig"></lib-np-content-block>
            </div>
        </ng-container>

        <ng-container *ngIf="node.children">
            <lib-np-treeview-item [treeviewData]="node.children" [caracConfig]="caracConfig"></lib-np-treeview-item>
        </ng-container>
    </mat-expansion-panel>
</div>

