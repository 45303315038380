import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {TABLE_HEADER_LABELS} from '@data/constants';
import {combineLatest, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {selectAdvancedSearch} from 'src/app/store/selector/advanced-search.selector';
import {selectMediaSearchResult} from 'src/app/store/selector/media-library.selector';
import {SearchMedia} from '@store/action';
import {FormControl} from '@angular/forms';
import {LIBRARY_MEDIA_MEDIA_PAGE_SIZE} from 'src/app/data/constants/advanced-search.constants';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {selectDataLanguage} from 'src/app/store/data-languages/data-language.selector';
import {AdvSearchArgs, NPElementType} from 'src/lib';
import {MatSelect} from '@angular/material/select';
import {CharTemplateDto} from '@app/models';
import * as _ from 'lodash';
import {FetchMediaCharTemplates, selectMediaCharTemplate} from '@store/media-char-template';
import {FilterInterface} from 'src/app/core/interfaces/filter-interface';
import {Store} from '@ngrx/store';
import {MatPaginator} from '@angular/material/paginator';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ParamsFilterBuilder} from '@data/builders';
import {ExportBaseComponent} from '../../../graphics/components/base/export-base.component';
import {ElementSelectionService} from '@data/services';
import {selectIsNotSelectAllModel} from '@data/selection';
import {environment} from '@env';
import {Router} from '@angular/router';

@Component({
  selector: 'app-medias-page',
  templateUrl: './medias-page.component.html',
  styleUrls: ['./medias-page.component.scss']
})
export class MediasPageComponent extends ExportBaseComponent implements OnInit, OnDestroy, FilterInterface {
  @ViewChild('searchFilter') searchFilter!: ElementRef;
  @ViewChildren('filtersCriteriaList', {read: MatSelect}) filtersCriteriaSelect: QueryList<MatSelect>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public paramFilterBuilder: ParamsFilterBuilder;
  public readonly charTemplatesView$ = this._store.select(selectMediaCharTemplate);

  public selectedDataLanguageID: number;
  public defaultAdvancedSearch: AdvSearchArgs;
  public advancedSearch: AdvSearchArgs;

  public mediaView$ = this._store.select(selectMediaSearchResult)
      .pipe(filter(result => !!result.data));

  public columnsToDisplay: string[];
  public headerLabels = TABLE_HEADER_LABELS;
  public pagination: PageEvent;
  public searchFormControl = new FormControl();
  public toggle = true;
  public exportBtnLabelKey$ = this._store.select(selectIsNotSelectAllModel)
      .pipe(map(isSelectNotAll => isSelectNotAll ? 'products.export-selection' : 'products.export-all'));

  private _storeSub: Subscription;
  private _dataExportSub: Subscription;

  constructor(
      public dialog: MatDialog,
      public _store: Store,
      private readonly _selectionService: ElementSelectionService,
      private _router: Router,

  ) {
    super();
    this.columnsToDisplay = ['select', 'overview', 'label', 'modificationDate', 'actions'];
    this.pagination = new PageEvent();
    this.pagination.pageIndex = 0;
  }

  ngOnInit(): void {
    this._store.dispatch(FetchMediaCharTemplates());

    this.getMediasList();

    this.searchFormControl.valueChanges
      .subscribe(valueToSearch => {
        // Reset data if value to search is empty
        if (!valueToSearch && this.advancedSearch) {
          this.advancedSearch.Config.Filters.Keywords = '';
          this._store.dispatch(SearchMedia({advancedSearch: this.advancedSearch, multiOperators: true}));
        }
      });
  }

  public toggleView(change: MatButtonToggleChange) {
    this.toggle = change.value;
  }

  public exportAllProducts() {
    this._dataExportSub?.unsubscribe();

    this._dataExportSub = this._selectionService
        .getSelectedMediaIds(this.advancedSearch)
        .pipe(
            mergeMap(elementIds => this.exportElements$(elementIds as number[], this._router))
        )
        .subscribe({
          complete: () => this._dataExportSub?.unsubscribe()
        });
  }

  public searchByKeyword(value: string): void {
    if (this.advancedSearch) {
      this.advancedSearch.Config.Filters.Keywords = value;
      this.paginator?.firstPage();

      this._store.dispatch(SearchMedia({advancedSearch: this.advancedSearch, multiOperators: true}));
    }
  }

  public resetSearch(event: Event): void {
    this.searchFilter.nativeElement.value = '';
    this.searchByKeyword(event.target['value']);
  }

  public onPageChange(event: PageEvent) {
    this.pagination = event;

    if (this.advancedSearch) {
      this.advancedSearch.PageSize = this.pagination.pageSize;
      this.advancedSearch.CurrentPage = this.pagination.pageIndex + 1;

      this._store.dispatch(SearchMedia({advancedSearch: this.advancedSearch, multiOperators: true}));
    }
  }

  private getMediasList(): void {
    // Init media
    this._storeSub = combineLatest([
      this._store.select(selectAdvancedSearch)
          .pipe(filter(result => !!result)),
      this._store.select(selectDataLanguage)
    ])
    .subscribe(([advancedSearch, selectedDataLang]) => {
      this.pagination.pageSize = advancedSearch?.PageSize || LIBRARY_MEDIA_MEDIA_PAGE_SIZE;
      this.defaultAdvancedSearch = _.cloneDeep(advancedSearch) as AdvSearchArgs;
      this.advancedSearch = _.cloneDeep(this.defaultAdvancedSearch);

      if (this.advancedSearch) {
        this.advancedSearch.LangID = selectedDataLang?.ID;
        if (this.advancedSearch.Config?.Filters) {
          this.advancedSearch.Config.Filters.ElementTypes = [NPElementType.Media];
        }
      }
      this.selectedDataLanguageID = selectedDataLang?.ID;
      this._store.dispatch(SearchMedia({advancedSearch: this.advancedSearch, multiOperators: true}));
    });
  }
  searchMedias() {
    this.dispatchSearchElements(this.advancedSearch);
  }

  resetMediaFilters() {
    this.advancedSearch = _.cloneDeep(this.defaultAdvancedSearch);
    this.dispatchSearchElements(this.advancedSearch);
  }

  public filterByProductType(productType: CharTemplateDto) {

    this.advancedSearch.Config.Filters.ProductsTypeID = productType.ID;
    this.advancedSearch.Config.Filters.ProductsType375 = {
      Items: {[productType.ID]: ''}
    };

    this.paginator?.firstPage();

    this.dispatchSearchElements(this.advancedSearch);
  }

  public dispatchSearchElements(advancedSearch?: AdvSearchArgs) {
    this._store.dispatch(SearchMedia({advancedSearch: advancedSearch, multiOperators: true}));
  }

  ngOnDestroy(): void {
    this._storeSub?.unsubscribe();
    this._dataExportSub?.unsubscribe();
  }

}
