import {DicoCarac, NPElement} from "../../../lib";

export class FilePreview {
    name: string;
    url: string
    extension: string

    constructor(file?: NPElement) {
        if (file) {
            this.name = file.getValueTextValue(DicoCarac.MEDIA_LABEL);
            this.url = file.getValueTextValue(DicoCarac.MEDIA_FILE);
            const values = this.url.split('.')
            this.extension = values?.length > 1 ? `.${values.pop()}`: '';
        }
    }
}