import {Injectable} from '@angular/core';
import {Constantes} from '@nextpage/np-sdk-data';


export interface Sheet {
    readonly classes: Object;
    // readonly update: Function;
}

@Injectable({
    providedIn: 'root'
})
export class ThemingService {

    constructor(private _constantes: Constantes) {

    }

    getCssOveride(componentname: string) {
        return {};
    }

    getComponentConfigStyle(componentname: string) {
        const override_css = this.getCssOveride(componentname)
        return override_css;
    }
}
