import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { iif, of } from 'rxjs';
import { PublicationChaptersActions } from '@store/publication';
import { selectPublicationChannelScopeExtId } from '../../selector/user-info.selectors';
import { Store } from '@ngrx/store';
import { ElementReaderService } from 'src/app/data/services/element-reader.service';
import { DicoCarac, NPElement } from 'src/lib';

@Injectable()
export class PublicationChaptersEffect {
  constructor(
    private actions$: Actions,
    private _store: Store,
    private _elementReaderService: ElementReaderService
  ) {
  }

  fetchPublicationChapters$ = createEffect(() => this.actions$.pipe(
    ofType(PublicationChaptersActions.fetchPublicationChapters),
    switchMap(() => {
      return this._store.select(selectPublicationChannelScopeExtId).pipe(
        filter(channelExtId => !!channelExtId),
        mergeMap(channelExtId => {
          return iif(
            () => !!channelExtId,
            this._elementReaderService.getDescendants(channelExtId, true, [DicoCarac.CHANNEL_NODE_LABEL, DicoCarac.CHANNEL_LABEL])
              .pipe(map((parent: NPElement) => parent?.Children || [])),
            of([])
          ).pipe(
            map(chapters => PublicationChaptersActions.fetchPublicationChaptersSuccess({ chapters })),
            catchError(error => of(PublicationChaptersActions.fetchPublicationChaptersFailure({message: error})))
          );
        })
      );
    })
  ));
}
