import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NpParameterService} from 'src/lib/data/services';
import {map} from 'rxjs/operators';
import {removeUnauthorizedChars} from '@data/core';
import {NpParametersConstants} from '@nextpage/np-sdk-data';
import * as _ from 'lodash';
import {NpParameterDTO} from '@lib/models';

@Injectable({
    providedIn: 'root'
})
export class ThumbsService {

    private _parameterService = inject(NpParameterService);

    getAllowedThumbsSizes(): Observable<string[]> {
        return this._parameterService.getByName(NpParametersConstants.THUMB_SIZE)
            .pipe(
                map(thumbsSizes =>
                    !!thumbsSizes?.Valeur ?
                        thumbsSizes.Valeur
                            .split(',')
                            .map(removeUnauthorizedChars)
                        : [])
            );
    }

    getThumbsParameters(): Observable<{ allowedThumbExtensions: string[], allowedThumbSizes: string[] }> {
        return this._parameterService.getByNames(
            [NpParametersConstants.ALLOWED_THUMB_EXTENSIONS, NpParametersConstants.THUMB_SIZE]
        ).pipe(
            map(parameters => _.keyBy(parameters, 'Cle') as { [key: string]: NpParameterDTO }),
            map(parameters => {
                if (!_.isEmpty(parameters)) {
                    const allowedThumbExtensions = parameters[NpParametersConstants.ALLOWED_THUMB_EXTENSIONS]?.Valeur?.split(',') || [];
                    const allowedThumbSizes = parameters[NpParametersConstants.THUMB_SIZE]?.Valeur?.split(',') || [];
                    return {allowedThumbExtensions, allowedThumbSizes};
                }
                return {allowedThumbExtensions: [], allowedThumbSizes: []};
            })
        );
    }
}
