<p-overlayPanel #op styleClass="edit-edito__overlay" (onShow)="getEditoTemplates()">
    <ng-template pTemplate="content">
        <mat-card class="edit-edito__container">
            <mat-card-header class="align-items-center justify-content-between edit-edito__header">
                <mat-card-title class="edit-catalogue__title">{{ 'publication.catalogue.edito' | translate }}</mat-card-title>
                <fa-icon [icon]="['far', 'xmark']" size="lg" (click)="closeOverlay()"></fa-icon>
            </mat-card-header>

            <mat-card-content class="edit-edito__content">
                <form class="d-flex flex-column" [formGroup]='catalogEditoForm'>
                    <mat-form-field class="mb-2" appearance='outline'>
                      <mat-label>{{ 'common.fields.catalogue-edito-text' | translate }}</mat-label>
                      <textarea rows="3" matInput [maxlength]="editoTextMaxLength" formControlName='catalogEditoText' (input)="onTextChange($event.target.value)"></textarea>
                      <mat-hint>{{ 'common.fields.default-text' | translate }} 
                        <span [ngClass]="{'limit-reached': editoTextLength >= editoTextMaxLength}">{{editoTextLength}} / {{editoTextMaxLength}}</span>
                    </mat-hint>
                    </mat-form-field>

                    <p class="text-center template-title">{{ 'publication.catalogue.choose-page-template' | translate }}</p>

                    <ng-container *ngIf="(editoTemplatesView$ | async) as editoTemplatesView">
                        <mat-radio-group *ngIf="editoTemplatesView?.hasTemplates; else noResult"
                        formControlName="catalogEditoTemplate"
                        required>
                            <mat-radio-button *ngFor="let editoTemplate of editoTemplatesView?.publicationTemplates; let index = index"
                            class="mat-radio-container-no-show" 
                            (change)="templateChange(editoTemplate)"
                            [value]="editoTemplate?.id">
                                <img *ngIf="editoTemplate?.jpgFile" 
                                [src]="editoTemplate?.jpgFile" 
                                [alt]="editoTemplate?.description"
                                (error)="displayDefaultImage(editoTemplate)">
                                <span class="edito-label">{{ editoTemplate?.name || editoTemplate?.inddFile }}</span>
                            </mat-radio-button>
                        </mat-radio-group>

                        <ng-template #noResult>
                            <div class="text-center" *ngIf="!editoTemplatesView?.loading">{{'publication.catalogue.no-template-found' | translate}}</div>
                          </ng-template>
                      
                        <app-loader-spinner *ngIf='editoTemplatesView?.loading' [diameter]="50"></app-loader-spinner>
                    </ng-container>
          
                  </form>
            </mat-card-content>

            <mat-card-actions class="justify-content-end edit-edito__actions">
                <button mat-stroked-button class="cancel-btn" (click)="closeOverlay()">{{ 'common.cancel' | translate }}</button>
                <button mat-flat-button 
                color='primary' 
                class="validate-btn"
                type='submit'
                [disabled]='catalogEditoForm.invalid || (loading$ | async)'
                (click)="editEdito()">
                <span *ngIf='(loading$ | async)'
                class='spinner-border spinner-border-sm mr-1'></span>
                {{ 'common.validate' | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </ng-template>
</p-overlayPanel>
