import {createReducer, on} from '@ngrx/store';
import {FetchWorkflowStatusFailure, FetchWorkflowStatusSuccess} from './workflow-status.actions';
import {WorkflowStatusDto} from '@nextpage/np-sdk-data';

export const statusFeatureKey = 'status';

export interface StatusState {
    status: WorkflowStatusDto[];
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
}

export const initialStatus: StatusState = {
    status: [],
    errorMessage: null,
    loading: true,
    hasError: false
};

export const workflowStatusReducer = createReducer(
    initialStatus,
    on(
        FetchWorkflowStatusSuccess,
        (state, {status}): StatusState => ({
            ...state,
            status: status,
            loading: false,
            errorMessage: null,
        })
    ),
    on(FetchWorkflowStatusFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
