<div class="header-popup">
  <h2 mat-dialog-title class="dialog-title">{{dialogTitle}}</h2>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
       (click)="close()">
    <title>Fermer</title>
    <path d="M16.9494 15.7188C17.2307 16.0312 17.2307 16.5 16.9494 16.7812C16.6369 17.0938 16.1682 17.0938 15.8869 16.7812L12.1994 13.0625L8.48065 16.7812C8.16815 17.0938 7.6994 17.0938 7.41815 16.7812C7.10565 16.5 7.10565 16.0312 7.41815 15.7188L11.1369 12L7.41815 8.28125C7.10565 7.96875 7.10565 7.5 7.41815 7.21875C7.6994 6.90625 8.16815 6.90625 8.4494 7.21875L12.1994 10.9688L15.9182 7.25C16.1994 6.9375 16.6682 6.9375 16.9494 7.25C17.2619 7.53125 17.2619 8 16.9494 8.3125L13.2307 12L16.9494 15.7188Z" fill="#03A9C1"/>
  </svg>
</div>

<mat-dialog-content>
    <ng-container *ngIf="currentPhoto">
        <div class="gallery-viewer">
            <img [src]="currentPhoto.src" [alt]="currentPhoto.alt" [title]="currentPhoto.alt">
        </div>
    </ng-container>
    <ng-container *ngIf="photoUrls.length > 1">
        <div class="gallery-list">
            <div class="photo-legend" [ngClass]="isSelected(photo) ? 'photo-legend-active' : ''" *ngFor="let photo of photoUrls" (click)="circleSelected(photo)"></div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="button-popup-img download"
            type="button"
            title="Télécharger"
            (click)="downloadPicture()">
        Télécharger
    </button>
    <button class="button-popup-img update"
            type="button"
            title="Modifier cette image"
            (click)="changePicture(currentPhoto)">
        Modifier
    </button>
    <button class="button-popup-img delete"
            type="button"
            title="Supprimer cette image"
            (click)="deletePicture(currentPhoto)">
       Supprimer
    </button>
</mat-dialog-actions>
