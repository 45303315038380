/**
 * Compare two texts ignoring accents and capital letters
 * @param firstValue
 * @param secondValue
 */
export function compareTexts(firstValue: string, secondValue: string): boolean {
    return normalize(firstValue).includes(normalize(secondValue));
}


/**
 * Remove accents/capital letters
 * @param value
 */
export function normalize(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
}
