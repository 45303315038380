import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Guid } from "guid-typescript";
import { Paginator } from "primeng/paginator";
import { ParamsFilterBuilder } from "src/app/data/builders";
import { PUBLICATION_DEFAULT_PAGE_SIZE } from "src/app/data/constants/products-page-size.constants";
import { ParamsFilter, ProductSummary, Chapter, ProductWrapper, CatalogPage, Product } from "@app/models";
import * as _ from "lodash";
import { MatDialogRef } from "@angular/material/dialog";
import { DialogProductsSelectionComponent } from "../../dialog-products-selection.component";

@Component({
  selector: 'app-products-selection',
  templateUrl: './products-selection.component.html',
  styleUrls: ['./products-selection.component.scss'],
})
export class ProductsSelectionComponent {

  @ViewChild(Paginator) paginator: Paginator;

  @Input() public loading: boolean;
  @Input() public products: ProductSummary[];
  @Input() public totalRecords: number;
  @Input() public chapter: Chapter;
  @Input() public page: CatalogPage;
  @Input() public paramFilterBuilder: ParamsFilterBuilder;
  @Input() public pageSize = PUBLICATION_DEFAULT_PAGE_SIZE;
  @Input() public descriptionField: string;
  @Input() public productElement: ProductWrapper;

  @Output() filterElements = new EventEmitter<ParamsFilter>();

  constructor(public _dialogRef: MatDialogRef<DialogProductsSelectionComponent>,private _store: Store) {
  }

  public onPageChange(event) {
    const paramsFilter = this.paramFilterBuilder
      .withPage(event.page + 1)
      .withNumberOfElementByPage(event.rows)
      .build();

    this.filterElements.emit(paramsFilter);
  }

  public goToFirstPage(): void {
    this.paginator.first = 0;
  }

  public selectProduct(product: ProductSummary) {
    const newProduct = new Product({
      id: Guid.create().toString(),
      name: product.label,
      productId: product.element.ID
    });

    if (this.chapter
      && this.page) {
        this.productElement.product = newProduct;
    }

    this._dialogRef.close();
  }
}
