<div id="dialog-check-block">
  <p mat-dialog-title>{{dialogTitle}}</p>

  <mat-dialog-content>
    <div id="dialog-check-content">

      <div class="checkbox" *ngFor="let displayValue of displayValues">
        <div>
          <input type="checkbox"
                 [value]="displayValue"
                 [checked]="isChecked(displayValue.ValeurID)"
                 (change)="onValueChange(displayValue.ValeurID)">{{displayValue.Valeur}}
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="dialog-btn-block">
      <button class="btn btn-primary np-button np-close-btn" title="Fermer" (click)="closeMe()">
        <i class="ti ti-close"></i> Fermer
      </button>
      <button class="btn btn-primary np-button" (click)="saveInElement()" title="Enregistrer">
        <i class="ti ti-save"></i>
        Enregistrer
      </button>
    </div>
  </mat-dialog-actions>
</div>


