import {BrowserModule} from '@angular/platform-browser';
import {isDevMode, NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '@env';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomePageComponent} from './viewsModules/common/home-page/home-page.component';
import {ProductsPageComponent} from './viewsModules/common/products-page/products-page.component';
import {ImportPageComponent} from './viewsModules/common/import-page/import-page.component';
import {AccountPageComponent} from './viewsModules/common/account-page/account-page.component';
import {AuthenticationComponent} from './viewsModules/shared/authentication/authentication.component';
import {
    DialogResetPasswordComponent
} from './graphics/components/dialog-reset-password/dialog-reset-password.component';
import {SharedModule} from './graphics/shared/shared.module';
import {UserNameComponent} from './graphics/components/user-name/user-name.component';
import {SupplierInformationComponent} from './graphics/components/supplier-information/supplier-information.component';
import {MainPageComponent} from './viewsModules/common/main-page/main-page.component';
import {SidebarContentComponent} from './graphics/components/sidebar-content/sidebar-content.component';
import {LogoSupplierComponent} from './graphics/components/logo-supplier/logo-supplier.component';
import {PieChartComponent} from './graphics/components/pie-chart/pie-chart.component';
import {UserInformationComponent} from './graphics/components/user-information/user-information.component';
import {
    DialogElementWriterComponent
} from './graphics/components/dialog-element-writer/dialog-element-writer.component';
import {DialogLoaderComponent} from './graphics/components/dialog-loader/dialog-loader.component';
import {SnackBarComponent} from './graphics/components/snack-bar/snack-bar.component';

import {LoaderSpinnerComponent} from './graphics/components/loader-spinner/loader-spinner.component';
import {LanguageSelectorComponent} from './graphics/components/language-selector/language-selector.component';
import {FavoritePageComponent} from './viewsModules/common/favorite-page/favorite-page.component';
import {FavoriteSelectorComponent} from './graphics/components/favorite-selector/favorite-selector.component';
import {
    CatalogueHeaderActionsComponent
} from './graphics/components/publication/catalogue-header-actions/catalogue-header-actions.component';


import {
    advancedSearchFeatureKey,
    advancedSearchReducer,
    authenticationFeatureKey,
    authenticationReducer,
} from '@store/reducers';

import {AuthenticationEffects, FavoriteEffect, MediaLibraryEffects} from '@store/effects';
import {DialogProductSheetComponent} from './graphics/components/dialog-product-sheet/dialog-product-sheet.component';
import {NgOptimizedImage} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './data/interceptors/auth.interceptor';
import {MediasPageComponent} from './viewsModules/common/medias-page/medias-page.component';
import {NoInstanceFoundComponent} from './viewsModules/shared/no-instance-found/no-instance-found.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from 'ng-lazyload-image';
import {NpHomePageComponent} from './viewsModules/common/np-home-page/np-home-page.component';
import {DialogMediaSheetComponent} from './graphics/components/dialog-media-sheet/dialog-media-sheet.component';
import {TruncatePipe} from './data/pipes/truncate.pipe';
import {AdvancedSearchEffect} from './store/effects/advanced-search.effect';
import {UserInfoEffect} from './store/effects/user-info.effect';
import {userInfoFeatureKey, userInfoReducer} from './store/reducers/user-info.reducer';
import {mediaLibraryFeatureKey, mediaLibraryReducer} from './store/reducers/media-library.reducer';
import {GraphicsModule} from './graphics/graphics.module';
import {HomePageBlockComponent} from './graphics/components/home-page-block/home-page-block.component';
import {MediaCardsComponent} from './graphics/components/media-cards/media-cards.component';
import {ExportBaseComponent} from './graphics/components/base/export-base.component';
import {ElementsExportEffect, elementsExportFeatureKey, elementsExportReducer} from '@store/exports';
import {FavoritePipe} from '@data/pipes';
import {ElementsEffect, elementsFeatureKey, elementsReducer} from '@store/elements';
import {statusFeatureKey, WorkflowStatusEffect, workflowStatusReducer} from '@store/workflow-status';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {languageFeatureKey, languageReducer} from './store/reducers/language.reducer';
import {LanguageEffect} from './store/effects/language.effect';
import {AdvancedSearchParamsEffect, advancedSearchParamsFeatureKey, advancedSearchParamsReducer} from '@store/table';
import {ProductsEffect, productsFeatureKey, productsReducer} from '@store/products';
import {DashboardEffect, dashboardPageFeatureKey, dashboardReducer} from '@store/dashboard';
import {
    CharTemplateEffect,
    productCharTemplateFeatureKey,
    productCharTemplateReducer
} from '@store/product-char-template';
import {CharacteristicsEffect, characteristicsFeatureKey, characteristicsReducer} from '@store/characteristics';
import {DataLanguageEffect, dataLanguageFeatureKey, dataLanguageReducer} from '@store/data-languages';
import {
    CharTemplateFiltersComponent
} from './graphics/components/char-template-filters/char-template-filters.component';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
    MediaCharTemplateEffect,
    mediaCharTemplateFeatureKey,
    mediaCharTemplateReducer
} from '@store/media-char-template';
import {
    DialogCatalogueDeletionComponent
} from './graphics/components/publication/dialog-catalogue-deletion/dialog-catalogue-deletion.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {
    faCopy as farCopy,
    faDownload as farDownload,
    faEye as farEye,
    faFilePdf as farFilePdf,
    faFilePlus as farFilePlus,
    faMagnifyingGlass as farMagnifyingGlass,
    faMagnifyingGlassPlus as farMagnifyingGlassPlus,
    faPen as farPen,
    faSquarePlus as farSquarePlus,
    faSquareXmark as farSquareXmark,
    faTrash as farTrash,
    faXmark as farXmark,
    faCircleXmark as farCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faClone as fasClone,
    faClone,
    faCopy,
    faDownload,
    faEye,
    faFilePdf,
    faFilePlus,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faPen,
    faSquarePlus,
    faSquareXmark,
    faTrash,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
    DialogCataloguesManagementComponent
} from './graphics/components/publication/dialog-catalogues-management/dialog-catalogues-management.component';
import {
    DialogPageDeletionComponent
} from './graphics/components/publication/dialog-page-deletion/dialog-page-deletion.component';
import {
    DialogPagesAdditionComponent
} from './graphics/components/publication/dialog-pages-addition/dialog-pages-addition.component';
import {DialogPageZoomComponent} from './graphics/components/publication/dialog-page-zoom/dialog-page-zoom.component';
import {
    DialogProductsSelectionComponent
} from './graphics/components/publication/dialog-products-selection/dialog-products-selection.component';
import {
    ProductsSelectionFiltersComponent
} from './graphics/components/publication/dialog-products-selection/elements/products-selection-filters/products-selection-filters.component';
import {
    ProductsSelectionComponent
} from './graphics/components/publication/dialog-products-selection/elements/products-selection/products-selection.component';
import {LogoHeadlineComponent} from './graphics/components/publication/logo-headline/logo-headline.component';
import {PdfGuideComponent} from './graphics/components/publication/pdf-guide/pdf-guide.component';
import {
    ProductItemComponent
} from './graphics/components/publication/dialog-products-selection/elements/product-item/product-item.component';

import {
    CatalogueChapterComponent,
    CatalogueChapterRowComponent,
    CatalogueCoverEditionOverlayComponent,
    CatalogueEditionOverlayComponent,
    CatalogueEditoEditionOverlayComponent,
    CataloguePageAdditionComponent,
    CataloguePageComponent,
    CataloguePageTitleComponent,
    CatalogueProductsComponent,
    CataloguesComponent,
    CatalogueTemplateSelectionComponent,
    CatalogueTemplateSelectionOverlayComponent,
    PublicationCatalogueCreationComponent,
    PublicationCataloguePageComponent,
    PublicationHomepageComponent,
    PublicationMainPageComponent
} from '@view/publication';
import {
    CatalogEffect,
    catalogFeatureKey,
    catalogReducer,
    CatalogTreeEffect,
    catalogTreeFeatureKey,
    catalogTreeReducer,
    PublicationChaptersEffect,
    publicationChaptersFeatureKey,
    publicationChaptersReducer,
    PublicationChoiceCriteriasEffect,
    publicationChoiceCriteriasFeatureKey,
    publicationChoiceCriteriasReducer,
    PublicationTemplateEffect,
    publicationTemplateFeatureKey,
    publicationTemplateReducer
} from '@store/publication';
import {
    DialogProductDeletionComponent
} from './graphics/components/publication/dialog-product-deletion/dialog-product-deletion.component';
import {
    DialogCatalogueGenerationErrorComponent
} from './graphics/components/publication/dialog-catalogue-generation-error/dialog-catalogue-generation-error.component';
import {LogoMemberComponent} from './graphics/components/publication/logo-member/logo-member.component';
import {
    SearchableSelectComponent
} from './graphics/components/char-template-filters/char-template-filters-search/searchable-select.component';
import {elementsSelectionFeatureKey, elementsSelectionReducer} from '@data/selection';
import {favoriteFeatureKey, FavoriteReducer} from '@store/favorites';
import {FileEffect, fileFeatureKey, FileReducer} from '@store/file';
import {ImportMediaPageComponent} from './viewsModules/common/import-media-page/import-media-page.component';
import {
    ImportMediaEffect,
    importMediaFeatureKey,
    importMediaReducer,
} from '@store/import-media';
import {ElementsTableComponent} from './graphics/components/elements-table/elements-table.component';
import {
    ProductTableCellViewComponent
} from './graphics/components/elements-table/product-table-cell-view/product-table-cell-view.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        ProductsPageComponent,
        ImportPageComponent,
        ImportMediaPageComponent,
        AccountPageComponent,
        AuthenticationComponent,
        DialogResetPasswordComponent,
        UserNameComponent,
        SupplierInformationComponent,
        MainPageComponent,
        SidebarContentComponent,
        LogoSupplierComponent,
        PieChartComponent,
        UserInformationComponent,
        ProductTableCellViewComponent,
        DialogElementWriterComponent,
        DialogLoaderComponent,
        SnackBarComponent,
        LoaderSpinnerComponent,
        LanguageSelectorComponent,
        FavoritePageComponent,
        FavoriteSelectorComponent,
        TruncatePipe,
        ElementsTableComponent,
        DialogProductSheetComponent,
        MediasPageComponent,
        NpHomePageComponent,
        NoInstanceFoundComponent,
        DialogMediaSheetComponent,
        HomePageBlockComponent,
        MediaCardsComponent,
        FavoritePipe,
        CharTemplateFiltersComponent,
        PublicationMainPageComponent,
        PublicationHomepageComponent,
        PublicationCatalogueCreationComponent,
        DialogCatalogueDeletionComponent,
        PublicationCataloguePageComponent,
        CatalogueHeaderActionsComponent,
        CatalogueEditionOverlayComponent,
        DialogCataloguesManagementComponent,
        CataloguesComponent,
        CatalogueChapterComponent,
        CatalogueChapterRowComponent,
        CataloguePageTitleComponent,
        CataloguePageComponent,
        CatalogueProductsComponent,
        CatalogueTemplateSelectionComponent,
        CataloguePageAdditionComponent,
        CatalogueCoverEditionOverlayComponent,
        CatalogueEditoEditionOverlayComponent,
        CatalogueTemplateSelectionOverlayComponent,
        DialogPageDeletionComponent,
        DialogProductDeletionComponent,
        DialogPagesAdditionComponent,
        DialogPageZoomComponent,
        DialogProductsSelectionComponent,
        DialogCatalogueGenerationErrorComponent,
        ProductsSelectionFiltersComponent,
        ProductsSelectionComponent,
        ProductItemComponent,
        LogoHeadlineComponent,
        LogoMemberComponent,
        PdfGuideComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        GraphicsModule,
        FontAwesomeModule,
        OverlayPanelModule,
        SearchableSelectComponent,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot({
            [authenticationFeatureKey]: authenticationReducer,
            [favoriteFeatureKey]: FavoriteReducer,
            [advancedSearchFeatureKey]: advancedSearchReducer,
            [userInfoFeatureKey]: userInfoReducer,
            [mediaLibraryFeatureKey]: mediaLibraryReducer,
            [importMediaFeatureKey]: importMediaReducer,
            [elementsExportFeatureKey]: elementsExportReducer,
            [elementsFeatureKey]: elementsReducer,
            [statusFeatureKey]: workflowStatusReducer,
            [languageFeatureKey]: languageReducer,
            [dataLanguageFeatureKey]: dataLanguageReducer,
            [advancedSearchParamsFeatureKey]: advancedSearchParamsReducer,
            [productsFeatureKey]: productsReducer,
            [dashboardPageFeatureKey]: dashboardReducer,
            [productCharTemplateFeatureKey]: productCharTemplateReducer,
            [mediaCharTemplateFeatureKey]: mediaCharTemplateReducer,
            [publicationChoiceCriteriasFeatureKey]: publicationChoiceCriteriasReducer,
            [characteristicsFeatureKey]: characteristicsReducer,
            [publicationChaptersFeatureKey]: publicationChaptersReducer,
            [catalogTreeFeatureKey]: catalogTreeReducer,
            [publicationTemplateFeatureKey]: publicationTemplateReducer,
            [catalogFeatureKey]: catalogReducer,
            [elementsSelectionFeatureKey]: elementsSelectionReducer,
            [fileFeatureKey]: FileReducer,
        }, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
        EffectsModule.forRoot(
            [
                AuthenticationEffects,
                FavoriteEffect,
                AdvancedSearchEffect,
                UserInfoEffect,
                MediaLibraryEffects,
                ImportMediaEffect,
                ElementsExportEffect,
                ElementsEffect,
                WorkflowStatusEffect,
                LanguageEffect,
                DataLanguageEffect,
                AdvancedSearchParamsEffect,
                ProductsEffect,
                DashboardEffect,
                CharTemplateEffect,
                CharacteristicsEffect,
                MediaCharTemplateEffect,
                PublicationChaptersEffect,
                PublicationChoiceCriteriasEffect,
                CatalogTreeEffect,
                PublicationTemplateEffect,
                CatalogEffect,
                FileEffect,
            ]
        ),
        NgOptimizedImage,
        LazyLoadImageModule,
        ExportBaseComponent,
    ],
    providers: [environment.providers,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }],
    bootstrap: [AppComponent],
    exports: [FavoritePageComponent, LoaderSpinnerComponent],
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
            faFilePdf,
            farFilePdf,
            faCopy,
            farCopy,
            faClone,
            fasClone,
            faDownload,
            farDownload,
            faPen,
            farPen,
            faTrash,
            farTrash,
            faXmark,
            farXmark,
            faSquareXmark,
            farSquareXmark,
            faSquarePlus,
            farSquarePlus,
            faFilePlus,
            farFilePlus,
            faMagnifyingGlass,
            farMagnifyingGlass,
            faEye,
            farEye,
            faMagnifyingGlassPlus,
            farMagnifyingGlassPlus,
            farCircleXmark
        );
      }
}
