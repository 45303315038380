<div>
    <h3>{{ 'import.import-media' | translate }}</h3>
</div>
<mat-divider class="my-3"></mat-divider>
<div class="stepper-content">
    <div class="dropzone-group">
        <div class="dropzone fullscreen" fileDrop
                (filesDropped)="handleDrop($event)"
                (filesHovered)="handleHover($event)"
                [class.hovered]="dropZoneHovered">
            <div>
                <div class="dropzone-info">
                    <span class="no-selected-file">
                        {{ (uploadImages$ | async)?.length  === 0 ? ("import.no-selected-file" | translate) : '' }}
                    </span>
                </div>
                <div class="file-list">
                    <div *ngFor="let uploadImage of uploadImages$ | async" class="drop-zone-file-container">
                        <div class="image-preview">
                            <img [src]="uploadImage.imgURL" *ngIf="uploadImage.imgURL" alt="{{ uploadImage.file?.name }}"  title="{{ uploadImage.file?.name }}">
                        </div>
                        <span class="selected-file" title="{{ uploadImage.file?.name }}">
                            {{ uploadImage.file?.name }}
                        </span>
                        <fa-icon class="remove-icon" [icon]="['far', 'circle-xmark']" (click)="removeFile(uploadImage, $event)" title="{{ 'medias.delete-media' | translate }}"></fa-icon>
                    </div>
                </div>
                <input
                    #inputFile
                    style="display: none"
                    type="file"
                    multiple="multiple"
                    (change)="onFileChange($event)"/>

                <div class="input-file-block">
                    <p>{{ 'import.drag-file' | translate }}</p>
                    <p>{{ 'common.or' | translate }}</p>
                    <button mat-raised-button (click)="inputFile.click()">
                        <i class="pr-1 pi pi-download"></i>
                        {{ 'medias.select-media' | translate }}
                    </button>
                </div>
            </div>
                <div *ngIf="(uploadImages$ | async)?.length > 0">
                    <button class="button-validate"
                            [disabled]="isUploaded$ | async"
                            (click)="uploadAllFiles()"
                    >
                        <div class="is-saving">
                            <mat-spinner *ngIf="isUploaded$ | async" diameter="20" strokeWidth="3"></mat-spinner>
                            <span>{{ 'medias.validate-upload' | translate }}</span>
                        </div>
                    </button>
            </div>
        </div>
        <div class="dropzone fullscreen url-media">
            <mat-form-field appearance="outline" class="field-search-custom">
                <mat-label>{{ 'import.url-import' | translate }}</mat-label>
                <input [(ngModel)]="externalUrlMedia" type="text" matInput/>
            </mat-form-field>
            <div class="is-saving">
                <ng-container *ngIf="isUploaded$ | async; else showIcon">
                    <mat-spinner diameter="20" strokeWidth="3"></mat-spinner>
                </ng-container>
                <ng-template #showIcon>
                    <i class="pr-1 pi pi-download url-media" (click)="createMediaByExternalUrl()"></i>
                </ng-template>
            </div>

        </div>
    </div>
</div>

