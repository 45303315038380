export enum Components {
    FILE_UPLOAD = 'FileUploadComponent',
    LINKROUTER = 'LinkRouterComponent',
    MULTILINKVIEWWITHBULLET = 'MultiLinkViewWithBullet',
    STATUSVIEW = 'StatusView',
    LINKCARACWRITER = 'LinkCaracWriteComponent',
    LINKCARACWRITER_V2 = 'LinkCaracWriterV2Component',
    SELECTLISTVALUE = 'SelectListValue',
    SELECTLISTVALUELIEN = 'SelectListValueLien',
    TITLE_2 = 'Title2Component',
    VISUALMEDIA = 'VisualMediaComponent',
    VISUALMEDIAV2 = 'VisualMediaV2Component',
    DATAPICKER = 'NpDatepickerComponent',
    SIMPLEIMAGEVIEW = 'SimpleImageView',
    CHECKLIST = 'CheckList',
    CHECKLISTIMAGE = 'CheckListImagesComponent',
    NPNUMBER = 'NpNumberComponent',
    ARRAYCOMPONENT = 'LinkStyleArrayComponent',
    ARRAYCOMPONENT2 = 'LinkStyleArray2Component',
    TREEVIEW = 'NpTreeviewComponent',
    REPEATER = 'NpRepeaterComponent',
    CROSSTABLE = 'CrossTableComponent',
    VALUELINKREADER = 'LinkReaderComponent',
    COMBOBOX = 'ComboboxComponent',
    CHECK_SIMPLE_LIST = 'CheckSimpleListComponent',
    CHECK_SIMPLE_LIST_V2 = 'CheckSimpleListV2Component',
    HTML_EDITOR = 'HTMLEditorComponent',
    MULTIPLE_CHOICE = 'NpMultipleChoiceComponent',
}
