import { Catalog, CatalogConfig } from '@app/models';

export class CatalogConfigBuilder {
    private _catalogId: number;
    private _structure: Catalog;
    private _selectedChapters: string[] | [];

    withCatalogId(value: number): CatalogConfigBuilder {
        this._catalogId = value;
        return this;
    }

    withStructure(value: Catalog): CatalogConfigBuilder {
        this._structure = value;
        return this;
    }

    withSelectedChapters(value: string[]): CatalogConfigBuilder {
        this._selectedChapters = value;
        return this;
    }

    build(): CatalogConfig {
        return {
            catalogId: this._catalogId,
            structure: this._structure,
            selectedChapters: this._selectedChapters
        };
    }
}
