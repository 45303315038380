
<div class="container-image" *ngIf="items !== null && items.length > 0">
    <div class="image">
      <img alt="" [src]="currentItem.thumb" />
      <ul class="list-unstyled img-actions">
        <li>
          <a [href]="currentItem.thumb" download queryParamsHandling="merge" title="Zoom" target="_blank">
            <img src="assets/imgs/picto-zoom-photo.png" alt="Zoom" />
          </a>
        </li>
        <li>
          <a [href]="currentItem.src" download  queryParamsHandling="merge" title="Télécharger" target="_blank">
            <img src="assets/imgs/picto-telecharger-photo.png"  />
          </a>
        </li>
      </ul>
    </div>
    <div class="buttons">
      <span class="arrow" (click)="getPrevious()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </span>
      <span class="bulle" *ngFor="let e of items; index as i" [class.active]="currentIndex === i" (click)="getByIndex(i)"></span>
      <span class="arrow" (click)="getNext()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </span>
    </div>
</div>
