import {TypeCode} from "@nextpage/np-sdk-data";

export enum DicoCaracDestination {
    MEDIA = '202',
}

export const COMPONENT_NAME_BY_TYPE = {
    [TypeCode.NUMERIC]: 'NpNumberComponent',
    [TypeCode.DATETYPE]: 'NpDatepickerComponent',
    [TypeCode.CHOIX_NP]: 'NpMultipleChoiceComponent',
    [TypeCode.FICH_NP]: 'FileUploadComponent',
    [TypeCode.FICHPDF_NP]: 'FileUploadComponent',
    [TypeCode.IMG_NP]: 'FileUploadComponent',
    [TypeCode.LISTSPE]: 'SelectListValue',
    [TypeCode.LIENREFLEXIF]: 'SelectListValueLien',
    [TypeCode.LIEN]: 'SelectListValueLien',
    [TypeCode.TXTHTML_HTML]: 'HTMLEditorComponent',
    [TypeCode.TAB_NP]: '',
}

export const LINK_COMPONENT_NAME_BY_DESTINATION = {
    [DicoCaracDestination.MEDIA]: 'VisualMediaV2Component',
}

export const LINK_COMPONENT_NAME_BY_CARDINALITY = {
    1: 'LinkCaracWriteComponent',
};



