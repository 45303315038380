import {Component, Input} from '@angular/core';
import { Chapter } from '@app/models';

@Component({
  selector: 'app-catalogue-chapter',
  templateUrl: './catalogue-chapter.component.html',
  styleUrls: ['./catalogue-chapter.component.scss']
})
export class CatalogueChapterComponent {

  @Input() chapter: Chapter;
  public opened: boolean;

}
