<div class="input">

    <!--    Acces en écriture/lecture-->
    <ng-container *ngIf="!readOnlyAccess; else readOnlyAccessBlock">
        <input matInput type="number" class="example-right-align" [(ngModel)]="value.Value" class="input-border"
               [class.red-border]="!isValid" [formControl]="inputFormC" (keypress)="numberOnly($event)">
    </ng-container>

    <!--    Acces en lecture seule-->
    <ng-template #readOnlyAccessBlock>
        {{value && value.Value ? value.Value : ''}}
    </ng-template>

    <!--    Si la carac contient une unité-->
    <ng-container *ngIf="hasUnite">
        <span matSuffix class="carac-unite">{{caracConfig.DicoCarac.Unite}}</span>
    </ng-container>
    <span *ngIf="!isValid" class="requied-error-message inpu-custo">Champ obligatoire</span>

</div>
