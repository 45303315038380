import {createAction, emptyProps, props} from '@ngrx/store';
import {UploadImage} from '../../../lib/graphics/np-media-library/np-media-library.component';
import {NPElement} from '@lib/models';
import {MediaSummary} from '@app/models';

export const UploadFiles = createAction(
    '[Upload] Upload Files',
   emptyProps
);

export const UploadFileSuccess = createAction(
    '[Upload] Upload File Success',
    emptyProps
);

export const UploadFileFailure = createAction(
    '[Upload] Upload File Failure',
    props<{ error: string }>()
);

export const createMediaByExternalUrl = createAction(
    '[Create] Create Media By External Url',
    props<{ url: string }>()
);

export const createMediaByExternalUrlSuccess = createAction(
    '[Create] Create Media By External Url Success',
    props<{ url: string }>()
);

export const createMediaByExternalUrlFailure = createAction(
    '[Create] Create Media By External Url Failure',
    props<{ error: string }>()
);

export const UpdateMedia = createAction(
    '[Update] Update File',
    props<{ uploadMedia: File, media: MediaSummary }>()
);

export const UpdateMediaSuccess = createAction(
    '[Update] Update Media Success',
    props<{ uploadMedia: NPElement }>()
);

export const UpdateMediaFailure = createAction(
    '[Update] Update Media Failure',
    props<{ error: string }>()
);
export const AddMedias = createAction(
    '[Add] Add Medias',
    props<{ medias: UploadImage[] }>()
);
export const RemoveMedia = createAction(
    '[Remove] Remove Media',
    props<{ media: UploadImage }>()
);
