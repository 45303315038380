import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {NpInstanceService} from '@data/services';
import {NP_ENTITY_CONFIG} from '../../constants/np-entity.constants';

@Injectable({
    providedIn: 'root'
})
export class NpEntityGuard implements CanActivate {

    constructor(private _npInstanceService: NpInstanceService) {
    }

    canActivate(route: ActivatedRouteSnapshot)
        : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (route.url && route.url.length > 0 && route.url[0].path) {
            const currentEntity = route.url[0].path;
            if (NP_ENTITY_CONFIG.has(currentEntity) && this._npInstanceService.hasAllowedEntity(currentEntity)) {
                this._npInstanceService.currentEntity = NP_ENTITY_CONFIG.get(currentEntity);
                return true;
            }
        }
        return false;
    }

}
