<ng-container *ngIf="file">
    <div [ngClass]="customClass">
        <div class="file-item-container">
            <mat-chip>Fichier</mat-chip>
            <span class="media-name">{{file?.name}}</span>
            <a [href]="file?.url" [attr.title]="'medias.download-tooltip' | translate">
                <span class="material-icons">download</span>
            </a>

        </div>
    </div>
</ng-container>

