import {ChoiceCriteria} from '@data/types';

/**
 * Remplace les nouveaux objet (facets) par les objects initiaux
 * Met à jour les nombres de résultats sur chaque facet
 * @param choiceCriteria
 */
export function swapFacets(choiceCriteria: ChoiceCriteria) {
    choiceCriteria.facets = choiceCriteria.facets
        .map(facet => {
            const swappedFacet = choiceCriteria.initialFacets
                .find(initialFacet => {
                    return !!initialFacet.Value ?
                        initialFacet.Value === facet.Value :
                        initialFacet.ValueId === facet.ValueId
                });

            swappedFacet.Count = facet.Count;

            return swappedFacet;
        });
}