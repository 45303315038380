import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import {
    FetchPublicationTemplates,
    FetchPublicationTemplatesFailure,
    FetchPublicationTemplatesSuccess
} from '@store/publication';
import { Store } from '@ngrx/store';
import { NpPublicationTemplateService } from '@nextpage/np-sdk-data';
import { toPublicationTemplate } from '@np/utils';
import { FileReaderService } from 'src/lib/services/file-reader.service';
import { selectPublicationChannelScopeExtId } from '../../selector/user-info.selectors';
import { ProductsFacade } from 'src/app/data/facades';
import { SpDicoCarac } from 'src/app/data/constants';
import { PublicationTemplateDto } from 'src/lib';

@Injectable()
export class PublicationTemplateEffect {
    constructor(private actions$: Actions,
        private _store: Store,
        private _publicationTemplateService: NpPublicationTemplateService,
        private _fileReaderService: FileReaderService,
        private _productsFacade: ProductsFacade) {
    }

    publicationTemplatesEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchPublicationTemplates),
            switchMap(action => {
                const characteristicExtID = action.templateCharacteristicExtID;
                const isChannelCategory = characteristicExtID === SpDicoCarac.CP_EXTID_MODELE_PAGE_PRODUITS;
                const channelCategoryExtID = isChannelCategory ? action.channelCategoryExtID : null;
    
                const getElementExtId$ = isChannelCategory
                    ? of(channelCategoryExtID)
                    : this._store.select(selectPublicationChannelScopeExtId);

                return this._getPublicationTemplates(getElementExtId$, characteristicExtID).pipe(
                    map((publicationTemplates: PublicationTemplateDto[]) => FetchPublicationTemplatesSuccess({ publicationTemplates: publicationTemplates.map(toPublicationTemplate) })),
                );
            }),
            catchError(error => of(FetchPublicationTemplatesFailure({ message: error })))
        );
    });

    private _getPublicationTemplates(extId$: Observable<string>, characteristicExtID: string): Observable<PublicationTemplateDto[]> {
        return extId$.pipe(
            switchMap(channelExtId =>
                combineLatest([
                    this._productsFacade.getElements([channelExtId], [[]], [characteristicExtID]),
                    of(channelExtId)
                ])
            ),
            switchMap(([channelNode, channelExtId]) => {
                const templateCharacteristicValues = channelNode
                    .get(channelExtId)
                    .getValueListe(characteristicExtID)
                    .Values.map(value => value.Label);

                return this._publicationTemplateService.getTemplatesByNames(templateCharacteristicValues)
                .pipe(
                    map((templates: PublicationTemplateDto[]) => templates.map(template => ({
                        ...template,
                        JpgFile: this._fileReaderService.toTemplateFilePathWithFullPath(template.JpgFile) ?? ''
                    })))
                );
            })
        );
    }
}
