import { ChapterPortal, CatalogPortal } from '@lib/models';

export class CatalogPortalBuilder {
    private _id: string;
    private _name: string;
    private _description: string;
    private _chapters: ChapterPortal[];

    withId(value: string): CatalogPortalBuilder {
        this._id = value;
        return this;
    }

    withName(value: string): CatalogPortalBuilder {
        this._name = value;
        return this;
    }

    withDescription(value: string): CatalogPortalBuilder {
        this._description = value;
        return this;
    }

    withChapters(value: ChapterPortal[]): CatalogPortalBuilder {
        this._chapters = value;
        return this;
    }

    build(): CatalogPortal {
        return {
            Id: this._id,
            Name: this._name,
            Description: this._description,
            Chapters: this._chapters
        };
    }
}
