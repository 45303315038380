import {createReducer, on} from '@ngrx/store';
import {FetchPublicationTemplates, FetchPublicationTemplatesFailure, FetchPublicationTemplatesSuccess} from '@store/publication';
import * as _ from 'lodash';
import {PublicationTemplate} from '@app/models';

export const publicationTemplateFeatureKey = 'publication-templates';

export interface PublicationTemplateState {
    publicationTemplates: PublicationTemplate[];
    hasTemplates: boolean;
    errorMessage: any;
    loading: boolean;
    hasError: boolean;
}

export const initialStatePublicationTemplate: PublicationTemplateState = {
    publicationTemplates: [],
    hasTemplates: false,
    errorMessage: null,
    loading: true,
    hasError: false
};

export const publicationTemplateReducer = createReducer(
    initialStatePublicationTemplate,
    on(
        FetchPublicationTemplates,
        (state, ): PublicationTemplateState => ({
            ...state,
            publicationTemplates: null,
            hasTemplates: false,
            loading: true, // To maintain the loader
            errorMessage: null
        })
    ),
    on(
        FetchPublicationTemplatesSuccess,
        (state, {publicationTemplates: publicationTemplates}): PublicationTemplateState => ({
            ...state,
            publicationTemplates: publicationTemplates,
            hasTemplates: !_.isEmpty(publicationTemplates),
            loading: false,
            hasError: false,
            errorMessage: null
        })
    ),
    on(
        FetchPublicationTemplatesFailure,
        (state, {message}): PublicationTemplateState => {
            return ({
                ...state,
                publicationTemplates: [],
                hasTemplates: false,
                loading: false,
                hasError: true,
                errorMessage: message
            });
        }
    ),
);
