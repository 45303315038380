import {createReducer, on} from '@ngrx/store';
import {AdvSearchArgs} from '@nextpage/np-sdk-data';
import {FetchAdvancedSearchFailure, FetchAdvancedSearchSuccess, SaveAdvancedSearch} from './advanced-search.actions';

export const advancedSearchFeatureKey = 'advancedSearch';

export interface AdvancedSearchState {
    advancedSearch: AdvSearchArgs | null;
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
}

export const initialAdvancedSearch: AdvancedSearchState = {
    advancedSearch: null,
    errorMessage: null,
    loading: true,
    hasError: false,
};

export const advancedSearchReducer = createReducer(
    initialAdvancedSearch,
    on(
        FetchAdvancedSearchSuccess,
        (state, {advSearch}): AdvancedSearchState => {
            return ({
                ...state,
                advancedSearch: advSearch,
                loading: false,
                errorMessage: null
            });
        }
    ),
    on(FetchAdvancedSearchFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    ),
    on(SaveAdvancedSearch,
        (state, {advSearch}): AdvancedSearchState => ({
            ...state,
            advancedSearch: advSearch,
            loading: false,
            hasError: true
        })
    )
);
