<mat-form-field>
  <mat-select [formControl]="dropDownControl" [(value)]="selectedValue">
    <mat-option></mat-option>
    <mat-option *ngFor="let displayValue of displayValues" [value]="displayValue">
      {{displayValue.Valeur}}
    </mat-option>
  </mat-select>
</mat-form-field>
<span *ngIf="displayRequiedError" class="requied-error-message select-list-custom">Champ obligatoire</span>


