import {Injectable} from '@angular/core';

@Injectable(
  {providedIn: 'root'}
)
export class Constantes {
  public baseUrl = '';
  public screenConfig = '';
  public token = null;
  /**
   * Variable utilisé pour vérifier l'environnement utilisé
   */
  public envProd: true;
  public componentGraphicsConfig;
  public primaryColor = '';
  samlRedirectUrl? = '';
}
