import {createFeatureSelector, createSelector} from '@ngrx/store';
import {languageFeatureKey, LanguageState} from '../reducers/language.reducer';

const selectState = createFeatureSelector<LanguageState>(
    languageFeatureKey
);

export const selectLanguage = createSelector(
    selectState, (state) => state.selectedUiLang
);
