<form [formGroup]='formFilter'>
    <ng-container *ngIf="charTemplates">
        <div class="list-filters__container" *ngIf="charTemplates.length > 0">
            <mat-form-field appearance='outline' class='filter-form-field'>
                <mat-label>{{ 'products.filter-by' | translate }}</mat-label>
                <mat-select formControlName="formProductType"
                            panelClass="filter-form-field__container"
                            (selectionChange)='filterByTypeProduct($event)'>
                    <mat-option [value]="'reset'">{{ 'products.all' | translate }}</mat-option>
                    <mat-option *ngFor='let typeProduct of charTemplates' [value]='typeProduct'>
                        {{ typeProduct.Name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

                <ng-container *ngIf='filtersCriteriaList && filtersCriteriaList.length > 0'>
                    <ng-container *ngFor='let filtersCriteria of filtersCriteriaList'>
                        <ng-container *ngIf="filtersCriteria?.facets?.length > 0">
                            <app-searchable-select
                                    class="filter-form-field"
                                    [filtersCriteria]="filtersCriteria"
                                    [formFilter]="formFilter"
                                    (selectedFacets)="onFacetsChange($event)"
                                    (filterBySelectedFacets)="filterByFacet($event)"
                                    (dropDownOpened)="onChoicesCriteriaDropdownOpened($event.dropdownOpened, $event.filtersCriteria)"
                                    (resetFilter)="clearSingleFilter($event)">
                            </app-searchable-select>
                        </ng-container>
                    </ng-container>

                    <button (click)="clearAllFilters()"
                            mat-stroked-button
                            class="filter-btn">
                        <mat-icon>clear</mat-icon>
                        {{ 'products.reset' | translate }}
                    </button>
                </ng-container>
            </div>
    </ng-container>
</form>
