import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {CaracConfig, DicocaracRepository, NPCaracListe} from '@nextpage/np-sdk-data';
import {CaracInfo} from '../Model';


@Component({
    selector: 'lib-app-value-list-read-view',
    templateUrl: './np-value-list-read-view.component.html',
    styleUrls: ['./np-value-list-read-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NpValueListReadViewComponent implements OnInit, OnChanges {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracListe;
    @Input() caracConfig: CaracConfig;

    public displayValues: string[] = [];
    public excludedValue: string;

    constructor(private _dicoCaracRep: DicocaracRepository) {
    }

    ngOnInit() {
    }

    public initValues() {
        if (this.value != null) {
            this._dicoCaracRep.getDicoCarac(this.value.DicoCaracExtID).subscribe(dc => {
                if (dc.Values != null && (<NPCaracListe>this.value).Values != null) {
                    const result = (<NPCaracListe>this.value).Values.map(data => {
                        // TODO le modèle de données envoyées va changer=> il faudra le reprendre
                        const SelectedVals = dc.Values.filter(ListVal => ListVal.ValeurID === data.ValueID);
                        return SelectedVals;
                    });


                    // Recherche des valeurs à ne pas afficher
                    this.excludedValue = this.caracConfig.Specific
                    && this.caracConfig.Specific.ExcludedValue
                    && this.caracConfig.Specific.ExcludedValue.toString().trim() !== '' ? this.caracConfig.Specific.ExcludedValue : null;

                    result.forEach((value) => {
                      if (value[0]) {
                        this.displayValues.push(value[0]['Valeur']);
                      }
                    });
                    if (this.excludedValue) {
                        this.displayValues = this.displayValues.filter(value => value.trim() !== this.excludedValue);
                    }
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log('caracConfig : ', this.caracConfig);
        this.initValues();
    }


}
