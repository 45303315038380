import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CatalogState, selectCatalogView, GenerateCatalog, SaveCatalog, GetCatalogPdf, GetCatalogSources, CatalogTreeState, selectCatalogTreeView, selectDownloadProgress } from '@store/publication';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { PublicationHelper } from 'src/app/data/helpers/publication.helper';
import { Catalog, TreeNodeCatalog, CatalogConfig } from '@app/models';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogCatalogueGenerationErrorComponent } from '../dialog-catalogue-generation-error/dialog-catalogue-generation-error.component';
import { TemplatesTags } from '@data/enums';

@Component({
    selector: 'app-catalogue-header-actions',
    templateUrl: './catalogue-header-actions.component.html',
    styleUrls: ['./catalogue-header-actions.component.scss'],
})
export class CatalogueHeaderActionsComponent implements OnInit {
    public catalogTreeNode: TreeNodeCatalog;
    public isLoading: boolean;
    public isZipLoading = false;
    public pdfButtonClicked = false;
    public sourcesButtonClicked = false;

    public catalogTreeView$ = this._store.select(selectCatalogTreeView)
    .pipe(
        tap((catalogTreeState: CatalogTreeState) => {
            this.isLoading = catalogTreeState?.loading;
        })
    );

    public catalogView$ = this._store.select(selectCatalogView)
    .pipe(
        filter((catalogState: CatalogState) => !!catalogState?.catalog?.chapters),
        tap((catalogState: CatalogState) => {
            this.isLoading = catalogState?.loading;
            if(!catalogState.catalogConfig)  {
                const catalogCopy = _.cloneDeep(catalogState.catalog);
                //Coming from edition page
                this._configToSend = new CatalogConfig();
                this._configToSend.catalogId = this._catalogId;
                this._configToSend.structure = PublicationHelper.removeFirstBlankPage(catalogCopy) as Catalog;
                this._configToSend.selectedChapters = [];
            } else {
                //Coming from creation page
                this._configToSend = catalogState.catalogConfig;
            }
        })
    );

    public downloadProgressView$ = this._store.select(selectDownloadProgress)
    .pipe(tap((downloadProgressState) => {
        this.isZipLoading = downloadProgressState > 0 && downloadProgressState < 100;
    }));

    private _configToSend: CatalogConfig;
    private _catalogId: number;
    private _emptyPages = new Map<string, number[]>();
    private _pagesWithMissingProducts = new Map<string, number[]>();
    private _routeSub: Subscription;

    constructor(
        public dialog: MatDialog,
        private _store: Store,
        private _route: ActivatedRoute,
        private _location: Location) {
    }

    public ngOnInit(): void {
        this._routeSub = this._route.queryParams.subscribe(params => {
            this._catalogId = parseInt(params['id']);
        });

        this.catalogTreeNode = this._location.getState()['catalogTreeNode'];
    }

    public saveCatalogue(): void {
        this._store.dispatch(SaveCatalog({catalogConfig: this._configToSend}));
    }

    public generateCatalogue(): void {
        this._emptyPages.clear();
        this._pagesWithMissingProducts.clear();
        this._configToSend?.structure?.chapters
        .forEach(chapter => {
            chapter?.pages?.forEach((page, pageIndex) => {
                if (_.isNil(page?.template) || page?.template?.id === -1) {
                    if (!this._emptyPages.has(chapter.nameWithoutTag)) {
                        this._emptyPages.set(chapter.nameWithoutTag, []);
                    }
                    this._emptyPages.get(chapter.nameWithoutTag).push(pageIndex + 1);
                }

                if(page?.products) {
                    const memberProductsExists = page.products.some(product => product.name.includes(TemplatesTags.MEMBER));
                    const maxElements = page.template?.numberOfElements || 0;
                    const missingThreshold = memberProductsExists ? maxElements : maxElements - 1;

                    if (page.products.length < missingThreshold) {
                        if (!this._pagesWithMissingProducts.has(chapter.nameWithoutTag)) {
                            this._pagesWithMissingProducts.set(chapter.nameWithoutTag, []);
                        }
                        this._pagesWithMissingProducts.get(chapter.nameWithoutTag).push(pageIndex + 1);
                    }
                }
            });
        });
        
        if(this._emptyPages?.size > 0 || this._pagesWithMissingProducts?.size > 0) {
            this.dialog.open(DialogCatalogueGenerationErrorComponent, {
                width: '750px',
                data: {emptyPages: this._emptyPages, pagesWithMissingProducts: this._pagesWithMissingProducts},
                panelClass: "catalogue-generation-error-dialog"
            });
        } else {
            this._store.dispatch(GenerateCatalog({catalogConfig: this._configToSend}));
        }
    }

    public downloadPdf(): void {
        if(!_.isEmpty(this.catalogTreeNode.pdfFileName)) {
            this.sourcesButtonClicked = false;
            this.pdfButtonClicked = true;
            this._store.dispatch(GetCatalogPdf({catalog: this.catalogTreeNode}));
        }
    }

    public downloadSources(): void {
        if(!_.isEmpty(this.catalogTreeNode.zipFileName)) {
            this.sourcesButtonClicked = true;
            this.pdfButtonClicked = false;
            this._store.dispatch(GetCatalogSources({catalog: this.catalogTreeNode}));
        }
    }

    ngOnDestroy(): void {
        this._routeSub?.unsubscribe();
    }
}
