import {Guid} from 'guid-typescript';
import {ChaptersTags, TemplatesNames, TemplatesTags} from '@data/enums';
import {createProductWrappers, generateArray} from '@np/utils';

export class CatalogConfig {
    catalogId: number;
    structure: Catalog;
    selectedChapters: string[];
}

export class Catalog {
    id: string; // does not match catalog id. Always equal to '-1'
    name: string;
    description?: string;
    chapters: Chapter[];
}

export class Chapter {
    name: string;
    id?: string;
    nameWithoutTag?: string;
    isIntroductionChapter?: boolean;
    isEndChapter?: boolean;
    channelCategoryID?: number;
    channelCategoryExtID?: string;
    pages?: CatalogPage[];

    constructor(pages?: CatalogPage[]) {
        this.pages = pages;
        const chapterNameSplitted = this.name?.split(" - ");
        this.nameWithoutTag = chapterNameSplitted?.length > 0 ? chapterNameSplitted[1] : this.name;
        this.isEndChapter = this.name?.includes(ChaptersTags.END);
        this.isIntroductionChapter = this.name?.includes(ChaptersTags.INTRO);
    }
}

export class CatalogPage {
    public id: string;
    public name: string;
    public nameWithoutTag: string;
    public type = TemplatesNames || '';
    public isEditable: boolean;
    public description?: string;
    public template?: PageTemplate;
    public products?: Product[];
    public productsToAdd?: { product: Product }[];
    public memberProduct?: Product;

    constructor(params: Partial<CatalogPage>) {
        const splitPageName = params.name.split(' - ');

        this.name = params.name;
        this.nameWithoutTag = splitPageName[1] ?? this.name;
        this.description = params.description;
        this.template = params.template;
        this.products = params.products;

        if (params.name.includes(TemplatesTags.COVER)) {
            this.type = TemplatesNames.COVER;
        } else if (params.name.includes(TemplatesTags.EDITO)) {
            this.type = TemplatesNames.EDITO;
        } else if (params.name.includes(TemplatesTags.PRODUCT)) {
            this.type = TemplatesNames.PRODUCT;
        } else {
            this.type = '';
        }

        this.isEditable = this.type === TemplatesNames.COVER
            || this.type === TemplatesNames.EDITO
            || this.type === TemplatesNames.PRODUCT;

        this.id = params.id ?? Guid.create().toString();
    }

    public setTemplate(template: PageTemplate) {
        if(this.template?.numberOfElements > template?.numberOfElements) {
            this.products = [];
        }
        this.template = template;
        this.rebuildProductWrappers();
    }

    public rebuildProductWrappers() {
        if(this.template) {
            // Recherche du produit membre (le cas échéant)
            const memberProductIndex = this.products.findIndex(product => product?.name?.includes(TemplatesTags.MEMBER));
            const productsCopy = Object.assign([], this.products);

            if (memberProductIndex !== -1) {
                // On stocke le produit membre dans une autre variable;
                this.memberProduct = this.products[memberProductIndex];
                productsCopy.splice(memberProductIndex, 1);
            }

            // Création des données du formulaire d'ajout de produits
            this.productsToAdd = generateArray(this.template.numberOfElements - 1, (index) => {
                return createProductWrappers(productsCopy[index]);
            });
        }
    }
}

export class Product {
    id: string;
    name: string;
    productId: number;

    constructor(params: Product) {
        this.id = params.id ?? Guid.create().toString();
        this.name = params.name;
        this.productId = params.productId;
    }
}

export class PageTemplate {
    id: number;
    path: string;
    completePath: string;
    numberOfElements?: number;
    maxCharactersLength?: number;

    constructor(params: PageTemplate) {
        this.id = params.id;
        this.path = params.path;
        this.completePath = params.path;
        this.numberOfElements = params.numberOfElements;
        this.maxCharactersLength = params.maxCharactersLength;
    }
}

export interface ProductWrapper {
    product: Product;
}

export class TemplateDescription {
    Name?: string;
    MaxCharactersLength?: number;
}