import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-dialog-catalogues-management',
    templateUrl: './dialog-catalogues-management.component.html',
    styleUrls: ['./dialog-catalogues-management.component.scss']
})
export class DialogCataloguesManagementComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }   
}