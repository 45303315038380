export class ExportMediasParam {
    checked = false;
    parameters: ExportMediaItem[] = [];

    constructor(label: string) {
        this.parameters.push(new ExportMediaItem(ExportMediaType.EXPORT_MEDIA_THUMB, label));
    }
}

export class ExportMediaItem {
    public type: ExportMediaType;
    public label: string;
    public value: string;
    public checked = false;

    constructor(type?: ExportMediaType, label?: string, value?: string) {
        this.type = type;
        this.label = label;
        this.value = value;
    }
}

export enum ExportMediaType {
    EXPORT_MEDIA_THUMB = 'ExportMediaThumb',
    EXPORT_MEDIA_FORMAT = 'ExportMediaFormat'
}

export class ExportDialogParam {
    elementIds: number[];
    fileName: string;
}
