import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NpDownloadService} from '@nextpage/np-sdk-data';
import {MediaSummary, ProductSummary} from '@app/models';
import {Field} from '@data/types';
import {ProductsFacade} from '@data/facades';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {NpInstanceService} from '@data/services';
import {
  UpdateMedia,
  selectIsUploaded,
} from '@store/import-media';

@Component({
  selector: 'app-dialog-media-sheet',
  templateUrl: './dialog-media-sheet.component.html',
  styleUrls: ['./dialog-media-sheet.component.scss']
})
export class DialogMediaSheetComponent implements OnInit, OnDestroy {

  public fields: Field[];
  public loading: boolean;
  public isEditing: boolean;
  public selectedFile: File;
  public imageByDefault$ =  this._instanceService.getImageByDefault();
  public isUploaded$ = this._store.select(selectIsUploaded);

  private fields$: Subscription;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: ProductSummary | MediaSummary,
      private _downloadService: NpDownloadService,
      private _productFacade: ProductsFacade,
      private _store: Store,
      private _instanceService: NpInstanceService,
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.fields$ = this._productFacade
        .getInfoForMedia(this.data as ProductSummary)
        .subscribe(result => {
            this.loading = false;
            this.fields = result.fields;
        });
  }

  print() {
    window.print();
  }

  downLoad(fileUrl: string) {
    this._downloadService.downloadFile(fileUrl, this.data?.label, (this.data as ProductSummary).fileExtension)
        .subscribe();
  }

  edit() {
    this.isEditing = true;
  }
  cancelEdit() {
    this.isEditing = false;
  }

  onFileSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.data.imageUrl = reader.result as string; // Mise à jour de l'aperçu
        this.data.label = this.selectedFile?.name || ''; // Mise à jour du libellé avec le nom du fichier
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  save(): void {
    if (this.selectedFile) {
      this._store.dispatch(UpdateMedia({uploadMedia: this.selectedFile, media: this.data}));
    }
  }

  ngOnDestroy(): void {
    this.fields$?.unsubscribe();
  }
}
