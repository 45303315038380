<div class="file-upload-container">

  <div class="visuel-media">
      <ng-container *ngIf="currentPhoto; else addFileBlock">
          <div class="photo-block uploaded-img-block">
              <ng-container *ngIf="!isDisplayFullImage; else fullImageBlock">
                  <div class="uploaded-img-container">
                      <img [src]="isImage ? currentPhoto.src : 'assets/images/file-logo.png'" [alt]="currentPhoto.alt" [title]="currentPhoto.alt"
                           class="img-responsive"
                           (click)="openFile()"
                           (mouseenter)="op.show($event)"
                           (mouseleave)="op.hide()"/>

                          <button (click)="remove()" class="remove-btn">
                            <fa-icon class="d-flex" [icon]="['far', 'xmark']"></fa-icon>
                          </button>
                  </div>
              </ng-container>
                  <ng-template #fullImageBlock>
                      <div class="uploaded-full-img-container">
                          <img [src]="isImage ? currentPhoto.src : 'assets/images/file-logo.png'" [alt]="currentPhoto.alt" [title]="currentPhoto.alt"
                               class="img-responsive"/>
                      </div>
                      <button class="select-media-button" mat-raised-button (click)="addFile()">
                          {{ 'medias.select-media' | translate }}
                      </button>
                  </ng-template>
          </div>
      </ng-container>

    <p-overlayPanel #op>
      <img [alt]="currentPhoto?.alt" [src]="currentPhoto?.src" width="300">
    </p-overlayPanel>


      <ng-template #addFileBlock>
        <div *ngIf="!readOnlyAccess else noAddFileBlock"class="photo-block photo-add-wrapper">
            <input disabled type="file" name="file" id="file" class="inputfile"/>
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="addFile()">
              <title>Ajouter un fichier</title>
              <path d="M12.7109 9.0625C13.2578 9.0625 13.5312 9.49219 13.5312 10C13.5312 10.5469 13.1406 10.9375 12.6328 10.9375H9.82031V13.75C9.82031 14.2969 9.42969 14.6875 8.92188 14.6875C8.375 14.6875 7.98438 14.2969 7.98438 13.75V10.9375H5.21094C4.70312 10.9375 4.27344 10.5469 4.27344 10C4.27344 9.49219 4.70312 9.0625 5.21094 9.0625H8.02344V6.25C8.02344 5.74219 8.49219 5.3125 9 5.3125C9.46875 5.3125 9.89844 5.74219 9.89844 6.25V9.0625H12.7109ZM15.25 1.25C16.6172 1.25 17.75 2.38281 17.75 3.75V16.25C17.75 17.6562 16.6172 18.75 15.25 18.75H2.75C1.34375 18.75 0.25 17.6562 0.25 16.25V3.75C0.25 2.38281 1.34375 1.25 2.75 1.25H15.25ZM15.875 16.25V3.75C15.875 3.4375 15.5625 3.125 15.25 3.125H2.75C2.39844 3.125 2.125 3.4375 2.125 3.75V16.25C2.125 16.6016 2.39844 16.875 2.75 16.875H15.25C15.5625 16.875 15.875 16.6016 15.875 16.25Z" fill="#03A9C1"/>
            </svg>
        </div>
      </ng-template>

    <ng-template #noAddFileBlock>
      <div class="photo-block photo-add-wrapper noAddFile" title="Ajouter un fichier">
        <input disabled type="file" name="file" class="inputfile"/>
      </div>
    </ng-template>

  </div>

</div>

<!--          DONT REMOVE THIS-->

<!--          <ul class="list-unstyled img-actions" *ngIf="!readOnlyAccess">-->
<!--            <li>-->
<!--              <a-->
<!--                (click)="addFile()"-->
<!--                queryParamsHandling="merge"-->
<!--                title="Modifier"-->
<!--              >-->
<!--                <mat-icon style="color: white; font-size: 20px" alt="Modifier"-->
<!--                  >edit</mat-icon-->
<!--                >-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a-->
<!--                title="Zoom"-->
<!--                target="_blank"-->
<!--                (click)="openFile()"-->
<!--                [attr.data-lightbox]="currentPhoto.src"-->
<!--              >-->
<!--                <mat-icon style="color: white; font-size: 20px" alt="Zoomer"-->
<!--                  >zoom_in</mat-icon-->
<!--                >-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a-->
<!--                title="Télécharger"-->
<!--                (click)="downloadImage()"-->
<!--                class="download-btn"-->
<!--              >-->
<!--                <mat-icon-->
<!--                  style="color: white; font-size: 20px"-->
<!--                  alt="Télécharger"-->
<!--                  >download</mat-icon-->
<!--                >-->
<!--              </a>-->
<!--            </li>-->
<!--            <li *ngIf="!readOnlyAccess">-->
<!--              <a-->
<!--                [routerLink]=""-->
<!--                (click)="remove()"-->
<!--                queryParamsHandling="merge"-->
<!--                title="Supprimer"-->
<!--              >-->
<!--                <mat-icon style="color: white; font-size: 20px" alt="Supprimer"-->
<!--                  >delete_outline</mat-icon-->
<!--                >-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->



  <!--<div class="input-group">
        <ng-container *ngIf="currentMedia && currentMedia.Value !==''">
            <input type="text" class="form-control" disabled [title]="currentMedia.Value"  [value]="currentMedia.Value">
        </ng-container>
        <div class="input-group-append">
            <ng-container *ngIf="currentMedia && currentMedia.Value !==''">
                <button class="btn btn-primary np-button custom-button"
                        type="button"
                        title="Prévisualiser le fichier"
                        (click)="openFile()">
                    <i class="glyphicon glyphicon-zoom-in"></i>
                </button>
            </ng-container>
            <button class="btn btn-primary np-button custom-button"
                    [ngClass]="hasFile() ? '' : 'full-size'"
                    type="button"
                    title="Accès à un fichier"
                    (click)="addFile()">
                <i class="glyphicon glyphicon-pencil"></i> {{hasFile() ? '' : 'Associer un fichier'}}
            </button>

            <ng-container *ngIf="currentMedia && currentMedia.Value !==''">
                <button class="btn btn-primary np-button custom-button"
                        type="button"
                        title="Supprimer le fichier"
                        (click)="remove()">
                    <i class="glyphicon glyphicon-remove"></i>
                </button>
            </ng-container>

        </div>
    </div>-->
