import {Component, Input, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {
    CaracConfig,
    DicoCarac,
    ElementRepository,
    ElementWriterService,
    ManagementRulesCheckerService,
    NPCaracLien,
    NPElement,
    UiTranslationService
} from '@nextpage/np-sdk-data';
import {ImageObject} from '../np-value/np-value-image-read-view/np-value-image-read-view.component';
import {FileReaderService} from '../../services';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
    selector: 'lib-check-list-image',
    templateUrl: './check-list-image.component.html',
    styleUrls: ['./check-list-image.component.css']
})
export class CheckListImageComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public photosUrl: ImageObject[] = [];
    private _checkedElements: NPElement[] = [];
    private _translations = new Map<string, string>();
    public isValid = true;

    constructor(private _elementRepository: ElementRepository,
                private _elementWriter: ElementWriterService,
                private _translateSrv: UiTranslationService,
                private _fileReader: FileReaderService,
                private _ManagementRulesChecker: ManagementRulesCheckerService) {
    }

    ngOnInit() {
        this._initData();
        this._ManagementRulesChecker.subjectCheck
            .subscribe(isSaving => {
                if (isSaving) {
                    this._initRequiedError();
                }
            });
    }

    _initData() {
        // Initialisation des images existantes
        this._checkedElements = this.value && this.value.RebuildLinkedElements ?
            this.value.RebuildLinkedElements.map(currCaracLien => currCaracLien.Element) : [];
        // Chargement des images possibles
        this._elementRepository.getDescendants([this.caracConfig.DicoCarac.ElementLinkExtID], [[]], true)
            .subscribe(mapOfMediaElements => {
                const tmpMediaElements: NPElement = mapOfMediaElements.get(this.caracConfig.DicoCarac.ElementLinkExtID);
                if (tmpMediaElements && tmpMediaElements.Children) {
                    this.photosUrl = tmpMediaElements.Children.map(currMediaElement => {
                        return {
                            src: `${this._fileReader.toUrlThumbImage(currMediaElement)}`,
                            thumbSrc: this._fileReader.toUrlThumbImage(currMediaElement),
                            alt: currMediaElement.getValueTextValue(DicoCarac.MEDIA_FILE),
                            element: currMediaElement
                        };
                    });
                    this.photosUrl = this.photosUrl.filter(photo => photo.element !== undefined
                        && photo.src !== '' && photo.thumbSrc !== '');
                }
            });
    }

    /**
     * Enregistre l'elément coché
     */
    private selectOption(value: ImageObject) {
        this._elementWriter.concatValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, value.element);
        this._checkedElements.push(value.element);
    }

    isChecked(mediaElement: NPElement) {
        return this._checkedElements.some(currElement => currElement.ExtID === mediaElement.ExtID);
    }

    check(event: MatCheckboxChange | Event, value: ImageObject) {
        if (event['checked']) {
            this.selectOption(value);
        } else {
            this._uncheck(value);
        }
        this._initRequiedError();
    }

    private _uncheck(uncheckedValue: ImageObject) {
        const elementToRemove = this.value.Element.getValueLien(this.caracConfig.DicoCarac.ExtID)
            .RebuildLinkedElements.find(d => d.Element.ID === uncheckedValue.element.ID);
        if (elementToRemove) {
            this._elementWriter.deleteValueLink(this.value.Element, this.caracConfig.DicoCaracExtID, elementToRemove.Element);
            this._checkedElements = this._checkedElements.filter(currValue => currValue.ID !== uncheckedValue.element.ID);
        }
    }

    public translate(key: string, defaultvalue: string): string {
        if (this._translations.has(key)) {
            return this._translations.get(key);
        } else {

            this._translateSrv.translate(key).subscribe(v =>
                this._translations.set(key, v)
            );
            return defaultvalue;
        }
    }

    private _initRequiedError() {
        this.isValid = this._ManagementRulesChecker.isValueValide(this.value.Element, this.caracConfig);
    }
}
