import {ChoiceCriteria, Facet} from '@data/types';
import { compareTexts } from './string-value.utils';

export function searchInFacets(searchValue: string, choiceCriteria: ChoiceCriteria) {
    if (!!searchValue) {
        return filterFacetsByValue(Object.assign([], choiceCriteria.facets), searchValue);
    }

    return choiceCriteria.facets;
}

function filterFacetsByValue(initialFacets: Facet[], value: string): Facet[] {
    return initialFacets.filter(option => includesValue(option, value));
}

function includesValue(option: Facet, value: string): boolean {
    return  compareTexts(option.Value, value);
}

