import {createAction, props} from '@ngrx/store';
import {NpUser} from '../../../lib';

export const FetchUserInfo = createAction(
    '[UserInfo] Fetch UserInfo'
);

export const FetchUserInfoFailure = createAction(
    '[UserInfo] Fetch Failure',
    props<{ message: string }>()
);

export const FetchUserInfoSuccess = createAction(
    '[UserInfo] Fetch Success',
    props<{ userInfo: NpUser }>()
);

