<div class="input">

<!--Si mode lecture/écriture-->
    <ng-container *ngIf="!readOnlyAccess">
        <mat-form-field>
            <mat-label>{{!selectedValue? 'Sélectionnez une valeur' : ''}}</mat-label>
            <mat-select [(value)]="selectedValue">
<!--                <input [formControl]="selectFilterCtrl" matInput placeholder="Rechercher..." class="research-input"
                       (keydown.space)="$event.stopPropagation()">-->
<!--                Champ de recherche-->
                <div class="nav-research-block">
                    <div class="input-group">
                        <input type="text" class="form-control" npUiTranslate="_rechercherCombobox" translateIn="placeholder"  [formControl]="selectFilterCtrl">
                        <div class="input-group-append">
                            <button class="btn btn-secondary " type="button" title="Effacer" (click)="resetField()">
                                <i class="glyphicon glyphicon-remove"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <mat-option (click)="selectOption(null)">--------</mat-option>
                <mat-option *ngFor="let possible of listPossiblesFiltered" [value]="possible"
                            (click)="selectOption(possible)">
                    {{possible.elementName}}
                </mat-option>
            </mat-select>
            <span matSuffix class="carac-unite">{{caracConfig.DicoCarac.Unite}}</span>
        </mat-form-field>
        <span *ngIf="!isValid" class="requied-error-message inpu-custo">Champ obligatoire</span>
    </ng-container>

    <!--Si mode lecture seule-->
    <ng-container *ngIf="readOnlyAccess">
        <div class="readOnlyAccess-block">
            <span>{{selectedValue ? selectedValue.elementName : ''}}</span>
            <span class="carac-unite">{{caracConfig.DicoCarac.Unite}}</span>
        </div>
    </ng-container>

</div>
