import {createFeatureSelector, createSelector} from '@ngrx/store';
import {fileFeatureKey, FileState} from './file.reducer';

const selectState = createFeatureSelector<FileState>(fileFeatureKey);

export const selectThumbParameters = createSelector(
    selectState,
    state => (
        {
            allowedThumbExtensions: state.allowedThumbExtensions.map(ext => ext.toLowerCase()),
            allowedThumbSizes: state.allowedThumbSizes
        }
    )
);
