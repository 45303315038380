import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {CaracConfig, NPCaracLien, StructuredDataService} from '@nextpage/np-sdk-data';
import {CaracInfo} from '../np-value/Model';
import {RepeaterItem, RepeaterService} from '../../services';

@Component({
    selector: 'lib-np-repeater',
    templateUrl: './np-repeater.component.html',
    styleUrls: ['./np-repeater.component.css']
})
export class NpRepeaterComponent implements OnInit {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;
    @Input() caracConfig: CaracConfig;

    public dataRepeater: RepeaterItem[] = [];
    // private _initialIngredientTreeview: TreeviewItem[] = [];

    constructor(@Inject(forwardRef(() => RepeaterService)) private _repeaterService: RepeaterService,
                private _structuredDataService: StructuredDataService) {
    }

    ngOnInit() {

        this._initData();

        /**
         * Filtre les données de Treewiew en fonction du texte saisi dans la zone de recherche
         * se trouvant dans la zone du titre bleu
         */
/*        this._structuredDataService.getListenedCaracValueForFilter(this.caracConfig.DicoCaracExtID)
            .subscribe(researchValue => {
                this.dataRepeater = researchValue && researchValue.value !== '' ?
                    this._initialIngredientTreeview.filter(item => item.title.toUpperCase().indexOf(researchValue.value.toUpperCase()) !== -1) :
                    this._initialIngredientTreeview;
            });*/
    }

    private _initData() {
/*        console.log('caracInfo', this.caracInfo);
        console.log('value', this.value);
        console.log('caracConfig', this.caracConfig);*/

        this._repeaterService.setRebuildLinkedElements(this.value.RebuildLinkedElements, this.caracConfig);
        this.dataRepeater = this._repeaterService.getTreeviewData(this.caracConfig);

        // this._initialIngredientTreeview = Object.assign([], this.ingredientTreeview);
    }
}
