<form>
    <ng-container *ngIf="!advancedMode; else advancedBlock">
        <div class="checkbox" *ngFor="let displayValue of displayValues">
            <div>
                <input type="checkbox"
                       [value]="displayValue"
                       [checked]="isChecked(displayValue.ValeurID)"
                       (change)="onValueChange(displayValue.ValeurID)">{{displayValue.Valeur}}
            </div>
        </div>
    </ng-container>
    <ng-template #advancedBlock>
        <ng-container *ngIf="displayValueForAdvancedMode">
            <div class="checkbox">
                <div>
                    <input type="checkbox"
                           [value]="displayValueForAdvancedMode"
                           [checked]="isChecked(displayValueForAdvancedMode.ValeurID)"
                           (change)="onValueChange(displayValueForAdvancedMode.ValeurID)">{{displayValueForAdvancedMode.Valeur}}
                </div>
            </div>
            <!--            <div (click)="openDialog()" class="moreValues" title="Afficher toutes les valeurs">Plus...</div>-->
        </ng-container>
    </ng-template>
</form>
