<mat-expansion-panel class="catalogue-page__chapter"
  [expanded]="chapter?.isIntroductionChapter"
  (opened)="opened = true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{chapter?.nameWithoutTag}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="d-flex flex-column align-items-center expansion-panel-content">
    <ng-container *ngIf="chapter?.pages?.length > 0 && opened; else noPage">
      <ng-container *ngFor="let page of chapter.pages; let i = index; let last = last">
        <app-catalogue-chapter-row class="d-flex justify-content-center w-100 catalogue-page__row"
        *ngIf="i % 2 === 0"
        [chapter]="chapter"
        [leftPage]="chapter.pages[i]" 
        [rightPage]="chapter.pages[i + 1] || null"></app-catalogue-chapter-row>

        <app-catalogue-chapter-row class="d-flex justify-content-center w-100 catalogue-page__row"
        [chapter]="chapter"
        *ngIf="last && chapter.pages.length % 2 === 0"
        ></app-catalogue-chapter-row>
      </ng-container>
    </ng-container>

    <ng-template #noPage>
      <div class="d-flex justify-content-center w-100 catalogue-page__row">
        <div class="d-flex flex-column align-items-end catalogue-page__left-part"></div>
        <div class="d-flex catalogue-page__pages">
          <div class="d-flex justify-content-center align-items-center text-center catalogue-page__side catalogue-page__left-side add-page">
            <app-catalogue-page-addition [chapter]="chapter"></app-catalogue-page-addition>
          </div>
        </div>
        <div class="d-flex flex-column align-items-start catalogue-page__right-part"></div>
      </div>
    </ng-template>
  </div>
</mat-expansion-panel>