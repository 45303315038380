import {Component, Input, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogPageZoomComponent
} from 'src/app/graphics/components/publication/dialog-page-zoom/dialog-page-zoom.component';
import { FileReaderService } from 'src/lib';
import { CatalogPage, Chapter } from '@app/models';
import { NpInstanceService } from '@data/services';
import { ChaptersTags } from '@data/enums';
import { PublicationHelper } from 'src/app/data/helpers/publication.helper';

@Component({
  selector: 'app-catalogue-page',
  templateUrl: './catalogue-page.component.html',
  styleUrls: ['./catalogue-page.component.scss']
})
export class CataloguePageComponent implements OnInit {

  @Input() chapter: Chapter;

  public imageTemplateByDefault = this._instanceService.getPublicationImageTemplateByDefault();
  public isFirstBlankPage: boolean;

  private _page: CatalogPage;
  @Input()
  set page(value: CatalogPage) {
    this._page = value;
    this._generateTemplateFileUrl();
  }

  get page(): CatalogPage {
    return this._page;
  }

  constructor(
    public dialog: MatDialog,
    private _fileReaderService: FileReaderService,
    private _instanceService: NpInstanceService
  ) {
  }

  ngOnInit(): void {
    this.isFirstBlankPage = this.chapter?.name?.includes(ChaptersTags.INTRO) && this.page?.name === '';
  }

  public zoomPage(page: CatalogPage): void {
    this.dialog.open(DialogPageZoomComponent, {
      panelClass: 'page-zoom-dialog',
      data: page
    });
  }

  private _generateTemplateFileUrl(): void {
    if (PublicationHelper.transformPath(this._page, this.imageTemplateByDefault)) {
      this._page.template.completePath = this._fileReaderService.toTemplateFilePath(this._page.template.path);
    }
  }
}
