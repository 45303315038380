import {Injectable} from '@angular/core';
import {CaracConfig, DicoCarac, NPCaracLienRebuildValue, NPElement} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '../graphics/np-value/Model';

@Injectable({
    providedIn: 'root'
})
export class RepeaterService {

    /*public recursiveDS = 'DC_DS_INGREDIENT_DE_RECETTE_DEPUIS_RECETTE';
    public recursiveDSdepuisDS = 'DC_DS_INGREDIENT_DE_RECETTE_DEPUIS_INGREDIENT_DE_RECETTE';
    public caracLabelIngredient = 'DC_LR_I_ET_MP_DEPUIS_INGREDIENT_DE_RECETTE';
    */
    private _rebuildLinkedElements: NPCaracLienRebuildValue[] = [];

    constructor() {
    }

    static _getIngredientLabel(element: NPElement, caracLabelIngredient: string) {
        const tmpValueLien = element.getValueLien(caracLabelIngredient);
        return tmpValueLien
        && tmpValueLien.RebuildLinkedElements
        && tmpValueLien.RebuildLinkedElements[0] ?
            `${tmpValueLien.RebuildLinkedElements[0].Element.getValueTextValue(DicoCarac.PRODUCT_LABEL)}`
            : 'Aucun élément trouvé';
    }

    static _getPrefix(currentIndexLevel: number, parentPrefix: string) {
        return parentPrefix ? `—${parentPrefix}.${currentIndexLevel}` : `${currentIndexLevel}`;
    }

    /**
     * Retourne le noml de la class css en fonction du niveau
     * Cela permettra des définir un css à chaque niveau de treeview
     */
    static _getLevelStyle(currentLevel: number): string {
        return currentLevel ? `level_${currentLevel}` : '';
    }

    public createRepeaterTreeview(element: NPCaracLienRebuildValue[], nodeCarac: CaracConfig, parentIndex = null, level = 1): RepeaterItem[] {

        return element.map((item, index) => {
            const currentPrefixLabel = RepeaterService._getPrefix(index + 1, parentIndex);
            const currentLabel = item.Element.getValueTextValue(DicoCarac.PRODUCT_LABEL);

            // console.log('item.Element', item.Element);
            // const currElement = item.Element.getValueLien(caracLabelIngredient).RebuildLinkedElements[0];

            // type de produits
            const childrensElements = nodeCarac.Specific;
            const productTypeExtID = nodeCarac.BlocInfo;

            const currElement = item.Element;
            return ({
                title: `${currentPrefixLabel} - ${currentLabel}`,
                element: currElement,
                children: this.createRepeaterChildrenTreeview(currElement, childrensElements, currentPrefixLabel, level + 1),
                indexStyle: RepeaterService._getLevelStyle(level),
                opened: false,
                hasContent: true,
                productTypeExtID: ''
            });
        });
    }

    public createRepeaterChildrenTreeview(element: NPElement, childrensElements, parentIndex = null, level = 1): RepeaterItem[] {

        return childrensElements.ProductType.map((item, index) => {
            const currentPrefixLabel = RepeaterService._getPrefix(index + 1, parentIndex);
            const currentLabel = childrensElements.ProductTypeLabel[index];
            const currentLabelExtId = item;
            return ({
                title: `${currentPrefixLabel} - ${currentLabel}`,
                element: element,
                children: [],
                indexStyle: RepeaterService._getLevelStyle(level),
                opened: false,
                hasContent: true,
                productTypeExtID: currentLabelExtId
            });
        });

       /* return childrensElements.map((item, index) => {
            console.log('items', item);
            let currentLabel = '';
            const currentPrefixLabel = RepeaterService._getPrefix(index + 1, parentIndex);
            if (childrensElements.length > 0) {
                currentLabel = childrensElements[0];
                childrensElements.splice(0, 1);

                console.log('childrensElements', childrensElements);
                return ({
                    title: `${currentPrefixLabel} - ${currentLabel}`,
                    children: [],
                    indexStyle: RepeaterService._getLevelStyle(level),
                    opened: false,
                    hasContent: true
                });
            }
        });*/


        /*let currentLabel = '';
        const currentPrefixLabel = RepeaterService._getPrefix(level + 1, parentIndex);
        if (childrensElements.length > 0) {
            currentLabel = childrensElements[0];
            childrensElements.splice(0, 1);

            console.log('childrensElements', childrensElements);
            return ([{
                title: `${currentPrefixLabel} - ${currentLabel}`,
                children: [],
                indexStyle: RepeaterService._getLevelStyle(level),
                opened: false,
                hasContent: true
            }]);
        }*/
    }

    setRebuildLinkedElements(rebuildLinkedElements: NPCaracLienRebuildValue[], caracConfig?: CaracConfig) {
        if (rebuildLinkedElements) {
            this._rebuildLinkedElements = rebuildLinkedElements;
        }
        /*if (caracConfig) {
            this.initDataProperties(caracConfig);
        }*/
        // console.log('this._rebuildLinkedElements', this._rebuildLinkedElements);
    }

    getTreeviewData(nodeCarac): RepeaterItem[] {
        let tmpTreeviewData: RepeaterItem[] = [];
        if (this._rebuildLinkedElements && this._rebuildLinkedElements.length > 0) {
            let parents: NPCaracLienRebuildValue[] = [];
            this._rebuildLinkedElements.forEach(currElement => {
                const linkedElement = currElement.Element.getValueLien(nodeCarac.Specific.RecursiveDS);
                parents = parents.concat(linkedElement && linkedElement.RebuildLinkedElements ?
                    linkedElement.RebuildLinkedElements : []);
            });
            tmpTreeviewData = this.createRepeaterTreeview(parents, nodeCarac)
                .filter(item => item !== undefined && item !== null);
        }
        return tmpTreeviewData ? tmpTreeviewData : [];
    }

    /**
     * Retourne la config à passer en paramètre d'entrée du composnat lib-app-value-view
     */
    getCISV(caracConfig: CaracConfig, element: NPElement): CaracIdStateValue {
        const tmpCaraValue = element.getValue(caracConfig.DicoCaracExtID);
        if (tmpCaraValue) {
            return {
                value: element.getValue(caracConfig.DicoCaracExtID),
                config: caracConfig,
                element: element,
                baseElement : element
            };
        }
        return null;
    }
}

export interface RepeaterItem {
    title: string;
    element?: NPElement;
    children: RepeaterItem[];
    indexStyle: string;
    opened: boolean;
    hasContent: boolean;
    productTypeExtID: string;
}


