import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Subscription} from 'rxjs';
import {SpDicoCarac} from 'src/app/data/constants';
import {
    DialogPageDeletionComponent
} from 'src/app/graphics/components/publication/dialog-page-deletion/dialog-page-deletion.component';
import {
    DialogPagesAdditionComponent
} from 'src/app/graphics/components/publication/dialog-pages-addition/dialog-pages-addition.component';
import {
    EditPage,
    FetchPublicationTemplates,
    FetchPublicationTemplatesSuccess,
    PublicationTemplateState,
    selectPublicationTemplateView,
    UpdateChapter
} from '@store/publication';
import {selectLoadingAuthentication} from 'src/app/store/selector';
import {CatalogPage, Chapter, PublicationTemplate} from '@app/models';
import {TemplatesTags} from 'src/app/data/enums/publication-tags.enum';
import * as _ from 'lodash';
import {NpInstanceService} from '@data/services';
import {PublicationHelper} from 'src/app/data/helpers/publication.helper';

@Component({
    selector: 'app-catalogue-template-selection-overlay',
    templateUrl: './catalogue-template-selection-overlay.component.html',
    styleUrls: ['./catalogue-template-selection-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CatalogueTemplateSelectionOverlayComponent implements OnInit, OnDestroy {
    @Input() public chapter: Chapter;
    @Input() public page: CatalogPage;

    @ViewChild('op') overlayPanel: OverlayPanel;
    @ViewChild('productsPageTitleInput') productsPageTitleInput: ElementRef;

    public loading$ = this._store.select(selectLoadingAuthentication); // TODO new store?
    public productsTemplatesView$ = this._store.select(selectPublicationTemplateView);
    public templateSelectionForm: FormGroup;

    private _imageTemplateByDefault = this._instanceService.getPublicationImageTemplateByDefault();
    private _productsTemplatesViewSub: Subscription;
    private _confirmPageDeletionSub: Subscription;
    private _productsTemplates: PublicationTemplate[];

    constructor(
        public dialog: MatDialog,
        private _fb: FormBuilder,
        private _store: Store,
        private _instanceService: NpInstanceService) {
    }

    ngOnInit(): void {
        this.templateSelectionForm = this._fb.group({
            productsPageTitle: [this.page.nameWithoutTag, Validators.required],
            productsPageTemplate: ['', Validators.required]
        });
    }

    public getProductsTemplates(): void {
        this._productsTemplatesViewSub = this._store.select(selectPublicationTemplateView)
        .subscribe((templatesView: PublicationTemplateState) => {
            if (templatesView?.hasTemplates) {
                this._productsTemplates = templatesView.publicationTemplates;
                this.setDefaultProductsTemplate(templatesView);
            }
        });

        this.productsPageTitleInput.nativeElement.focus();
        this._store.dispatch(FetchPublicationTemplates(
            { templateCharacteristicExtID: SpDicoCarac.CP_EXTID_MODELE_PAGE_PRODUITS,
            channelCategoryExtID: this.chapter.channelCategoryExtID }
        ));
    }

    public displayDefaultImage(productsTemplate: PublicationTemplate): void {
        productsTemplate.jpgFile = this._imageTemplateByDefault;
    }

    public toggle(event: Event): void {
        this.overlayPanel.toggle(event);
    }

    public closeOverlay(): void {
        this.overlayPanel.hide();
        this._store.dispatch(FetchPublicationTemplatesSuccess({ publicationTemplates: null }));
    }

    public submitTemplateSelection(): void {
        PublicationHelper.updateCatalogPage(
            this.templateSelectionForm.controls.productsPageTitle.value,
            this.templateSelectionForm.controls.productsPageTemplate.value,
            this.page,
            this._productsTemplates,
            false);

        this._store.dispatch(EditPage({page: this.page, isIntroPage: false, updatedChapter: this.chapter}));

        this.closeOverlay();
    }

    public confirmPageDeletion(): void {
        this._confirmPageDeletionSub = this.dialog.open(DialogPageDeletionComponent, {
            panelClass: 'page-deletion-dialog'
        }).afterClosed()
            .subscribe(confirmDeletion => {
                if (confirmDeletion
                    && this.chapter?.pages
                    && this.page) {
                    _.remove(this.chapter.pages, this.page);

                    _.remove(this.chapter.pages, (page: CatalogPage) => page.name.includes(TemplatesTags.NOTE));

                    this._store.dispatch(UpdateChapter({chapter: this.chapter}));
                }
            });
    }

    public openPagesAddition(): void {
        this.dialog.open(DialogPagesAdditionComponent, {
            panelClass: 'add-pages-dialog',
            data: { chapter: this.chapter, page: this.page }
        });
    }

    private setDefaultProductsTemplate(templatesView: PublicationTemplateState): void {
        const defaultProductsTemplate = (!_.isNil(this.page?.template) && this.page.template.id !== -1) ? this.page.template : templatesView?.publicationTemplates[0];
        if (defaultProductsTemplate) {
            this.templateSelectionForm.patchValue({ productsPageTemplate: defaultProductsTemplate.id });
        }
    }

    ngOnDestroy(): void {
        this._confirmPageDeletionSub?.unsubscribe();
        this._productsTemplatesViewSub?.unsubscribe();
    }
}
