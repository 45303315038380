import {createActionGroup, emptyProps, props} from '@ngrx/store';

export const ElementsSelection = createActionGroup({
    source: '[Elements selection]',
    events: {
        // Add element to selection
        'add To Selection': props<{ elementId: number, checked: boolean }>(),

        // Set all selection to "True"
        'set Select All Elements': props<{ isSelectAllProducts: boolean }>(),

        // Export entire scope
        'export All': emptyProps(),

        // Export selected elements
        'export Selection': emptyProps(),

        // Clean store
        'clean store': emptyProps(),
    },
});
