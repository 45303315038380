import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {CharTemplateDto} from '@app/models';
import {map, mergeMap} from 'rxjs/operators';
import {AdvSearchArgs, NPElementType} from '../model';
import {WSParameters, WsParamsService} from './ws-params.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CharTemplateService {
    private _urlGetCharTemplate = '/api/sdk/chartemplate/GetAll';
    private _urlGetCharTemplateByExtIds = '/api/sdk/chartemplate/GetByElementExtIds';
    private _urlGetCharTemplateByAdvSearch = '/api/sdk/chartemplate/GetByAdvancedSearch';

    constructor(private _http: HttpClient, private _wsParamsService: WsParamsService) {
    }

    getByAdvSearch(advancedSearch: AdvSearchArgs): Observable<CharTemplateDto[]> {
        return this._http.post(this._urlGetCharTemplateByAdvSearch, advancedSearch)
            .pipe(
                map(response => {
                    if (response && 'Results' in response) {
                        const results = response['Results'];
                        if ('CharTemplates' in results) {
                            return results['CharTemplates'] as CharTemplateDto[];
                        }
                    }
                    return [];
                })
            );
    }

    getProductTemplate(ElementIDs: string[]): Observable<CharTemplateDto[]> {
        return this._wsParamsService.getParams().pipe(
            mergeMap((params: WSParameters) => {
                return this._http.post(this._urlGetCharTemplateByExtIds, {
                    ElementType: NPElementType.Product,
                    LangID: params.LangID,
                    ContextID: params.ContextID,
                    ExtIDs: ElementIDs,
                    GetDescendants: true,
                }).pipe(
                    map(response => {
                        if (response && 'Results' in response) {
                            const results = response['Results'];
                            if ('CharTemplates' in results) {
                                return results['CharTemplates'] as CharTemplateDto[];
                            }
                        }
                        return [];
                    })
                );
            })
        );
    }

    getAll() {
        // TODO: implementation
    }
}
