import {Component} from '@angular/core';
import {NpInstanceService} from '@data/services';

@Component({
    selector: 'app-publication-homepage',
    templateUrl: './publication-homepage.component.html',
    styleUrls: ['./publication-homepage.component.scss'],
})
export class PublicationHomepageComponent {

    public logoUrl$ = this._instanceService.getLoginLogoUrl$();
    public instanceBg$ = this._instanceService.getBgImageInstance();

    constructor(private _instanceService: NpInstanceService) {
    }
}
