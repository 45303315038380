import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ElementElementsSelectionState, elementsSelectionFeatureKey} from './export-selection.reducer';
import * as _ from 'lodash';

const selectElementsSelectionState = createFeatureSelector<ElementElementsSelectionState>(
    elementsSelectionFeatureKey
);

export const selectExportSelection = createSelector(
    selectElementsSelectionState, (state) => state
);

export const selectHasElementsSelection = createSelector(
    selectElementsSelectionState, (state) =>
        !_.isEmpty(state.elementIds)
);

export const selectHasExcludeElementsSelection = createSelector(
    selectElementsSelectionState, (state) =>
        !_.isEmpty(state.excludedElementIds)
);

export const selectIsNotSelectAllModel = createSelector(
    selectHasElementsSelection,
    selectHasExcludeElementsSelection,
    (hasElementsSelection, hasExcludeElementsSelection) => {
        return hasElementsSelection || hasExcludeElementsSelection;
    }
);
