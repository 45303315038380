import {Component} from '@angular/core';
import {NpInstanceService} from '@data/services';

@Component({
  selector: 'app-logo-supplier',
  templateUrl: './logo-supplier.component.html',
  styleUrls: ['./logo-supplier.component.scss']
})
export class LogoSupplierComponent {

  public logoUrl = this._instance.getLogoUrl();

  constructor(private _instance: NpInstanceService) {
  }

}
