import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NPElement } from 'src/lib';

export const PublicationChaptersActions = createActionGroup({
  source: 'publicationChapters',
  events: {
      'fetch publication chapters': emptyProps(),
      'fetch publication chapters success': props<{ chapters: NPElement[] }>(),
      'fetch publication chapters failure': props<{ message: any }>()
  },
});
