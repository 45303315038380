import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CatalogueEditionOverlayComponent
} from './elements/catalogue-edition-overlay/catalogue-edition-overlay.component';
import { Store } from '@ngrx/store';
import { GetCatalog, selectCatalogView } from '@store/publication';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-publication-catalogue-page',
  templateUrl: './publication-catalogue-page.component.html',
  styleUrls: ['./publication-catalogue-page.component.scss'],
})
export class PublicationCataloguePageComponent implements OnInit, OnDestroy {
  @ViewChild('catalogueEditionOverlay') catalogueEditionOverlay: CatalogueEditionOverlayComponent;

  public catalogView$ = this._store.select(selectCatalogView);
  public catalogId: number;

  private _routeSub: Subscription;

  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _location: Location) {
  }

  ngOnInit(): void {
    const routeState = this._location.getState();
    this._routeSub = this._route.queryParams.subscribe(params => {
      this.catalogId = parseInt(params['id']);
    });

    const isCreationStored = sessionStorage.getItem('isCreation');
    if (routeState['isCreation'] && !isCreationStored) {
      sessionStorage.setItem('isCreation', 'true');
    } else {
      this._store.dispatch(GetCatalog({ id: this.catalogId }));
    }
  }

  public toggleOverlay(event: Event): void {
    this.catalogueEditionOverlay.toggle(event);
  }

  ngOnDestroy(): void {
    this._routeSub?.unsubscribe();
  }
}
