<div id="no-instance-page">
    <mat-card>
        <mat-card-content>
            <div>
                <span class="material-icons">remove_circle_outline</span>
                <span>{{ 'instance.no-instance-found' | translate }}</span>
            </div>
            <div>
                <span>{{ 'instance.enter-correct-name-url' | translate }}</span>
                <span>{{ 'instance.instance-url-example' | translate }}</span>
                <span>{{ 'instance.contact-admin-nextpage' | translate }}</span>
            </div>
        </mat-card-content>
    </mat-card>
</div>
