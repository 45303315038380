import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SpDicoCarac} from "@data/constants";
import {map, mergeMap} from "rxjs/operators";
import {SpUserInfoService} from "@data/services";
import {ElementRepository, FileReaderService, NPElement} from "@nextpage/np-sdk-data";
import {FilePreview} from "../models/file-preview.model";

@Injectable({
    providedIn: 'root'
})
export class FileFacade {

    constructor(
        private _userInfoService: SpUserInfoService,
        private _elementRepository: ElementRepository,
        private _fileReaderService: FileReaderService
    ) {
    }

    public getDashboardMediaFolderExtId(): Observable<string> {
        return this._userInfoService.getUserCustomFieldByExtId(SpDicoCarac.CP_DOCUMENTSACCUEIL)
            .pipe(
                map(userField => {
                    return userField && userField.Values && userField.Values.length > 0 ? String(userField.Values[0]?.Value) : null;
                })
            );
    }

    public getFiles(): Observable<FilePreview[]> {
        return this.getDashboardMediaFolderExtId()
            .pipe(
                mergeMap(folderExtId =>
                    this._elementRepository.getDescendants([folderExtId], [[]])
                        .pipe(
                            map(elementMap => elementMap?.get(folderExtId)?.Children || []),
                            map((files: NPElement[]) => files.map(file => new FilePreview(file))),
                            map((files: FilePreview[]) => {
                                return files.map(file => {
                                    file.url = this._fileReaderService.toFilePathToDownload(file.url)
                                    return file;
                                })
                            })
                        )
                ),
            )
    }
}
