<mat-dialog-content style="height: 100%">
    <header *ngIf="logoUrl" class="d-none d-print-flex align-items-center justify-content-between client-logo">
        <img [attr.src]="logoUrl" alt="Logo Nextpage">
        <div>{{currentDate | date:'dd/MM/yy'}}</div>
    </header>
    <div class="content-information">
        <div class="title-information">
            <h4>{{ data.label }}</h4>
            <div>
                <ng-container *ngIf="isSupplierEntityName">
                    <img style="cursor: pointer" (click)="editInfos(data, $event)" src="./assets/icons/edit.svg"
                         class="fontawesome-icon-custom" alt="edit">
                </ng-container>
                <img style="cursor: pointer" (click)="print()" src="./assets/icons/print.svg"
                     class="fontawesome-icon-custom" alt="print">
                <img mat-dialog-close="true" src="./assets/icons/x-mark.svg" class="fontawesome-icon-custom"
                     alt="close">
            </div>
        </div>
        <app-loader-spinner *ngIf="loading"></app-loader-spinner>
        <mat-card class="mat-card-container">
            <mat-card-content>
                <div class="content-information-details">
                    <div style="margin-right: 25px">
                        <img class="imageUrlStyle" [src]="data.imageUrl ? data.imageUrl : imageByDefault$ | async " alt="Aperçu du média" />
                    </div>
                    <div class="information-details">
                        <div class="pField" *ngFor="let field of fields">
                            <div class="strongField">{{field.label}} :</div>
                            <div class="charac-value mb-2" >
                                <span *ngIf="field.value && !field.pdf">{{ field.value }}</span>
                                <a *ngIf="!!field.pdf" [href]="field.pdf">{{ field.value }}</a>
                                {{field.unit}}
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-content *ngIf="linkedElements && linkedElements.size">
                <div class="cross-selling-container">
                    <div *ngFor="let linkElement of linkedElements | keyvalue">
                        <h5 *ngIf="linkElement.value.length > 0">{{ linkElement.key }}</h5>
                        <div class="d-flex">
                            <div *ngFor="let link of linkElement.value" class="cross-selling-label"><strong>
                                {{ link.label }}
                            </strong>
                                <img
                                        [src]='link.imageUrl'
                                        class="imageUrlStyle"
                                        alt="Aperçu du média"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</mat-dialog-content>
