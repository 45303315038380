import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {FileActions} from './file.actions';
import {of} from 'rxjs';
import {ThumbsService} from '@data/services';

@Injectable()
export class FileEffect {
    constructor(
        private _actions$: Actions,
        private _ThumbsService: ThumbsService) {
    }

    fetchThumbsParameters$ = createEffect(() => {
            return this._actions$.pipe(
                ofType(FileActions.fetchThumbParameters),
                mergeMap(() => this._ThumbsService.getThumbsParameters()),
                map(thumbsParameters => FileActions.fetchThumbParametersSuccess({thumbsParameters})),
                catchError(error => of(FileActions.fetchThumbParametersFailure({message: error?.toString()})))
            );
        },
    );

}
