import {Access, TypeCode} from '@nextpage/np-sdk-data';


export class CaracInfo {
  authorization: Access;
  type: TypeCode;
  name: string;
  component?: string;
  componentRouting?: any;
  unite?: string;
  blocName: string;
  enableMultiValues: boolean;
}
