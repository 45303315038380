import {createReducer, on} from '@ngrx/store';

import {User} from '@app/models';
import {Login, LoginFailure, LoginSuccess} from '@store/action';

export const authenticationFeatureKey = 'authentication';

export interface AuthenticationState {
    isAuthenticated: boolean;
    user: User | null;
    errorMessage: string | null;
    loading: boolean;
}

export const initialStateAuth: AuthenticationState = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
  loading: false,
};

export const authenticationReducer = createReducer(
  initialStateAuth,
  on(
    Login,
    (state, { user }): AuthenticationState => ({
      ...state,
      user: user,
      loading: true,
      errorMessage: null,
    })
  ),
  on(
      LoginSuccess,
      (state: AuthenticationState, {result}): AuthenticationState => {
          return ({
              ...state,
              isAuthenticated: true,
              user: {...state.user, token: result},
              errorMessage: null,
              loading: false,
          });
      }
  ),
  on(
    LoginFailure,
    (state: AuthenticationState, { message }): AuthenticationState => ({
      ...state,
      user: { ...state.user, token: null },
      errorMessage: message,
      loading: false,
    })
  )
);
