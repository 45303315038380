import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { NpApiResult } from '../../../model';

/**
 * Cette classe sert à Intercepter une requete http et à en changer la réponse
 * Le but est de permettre de :
 *  Tester l'application avec un jeu de données
 *  Travailler sur le localStorage
 *  Réaliser des tests unitaires
 */
export abstract class DataMocker {
  /**
   * Méthode qui va mocker la réponse, uniquement si il y a matching d'url
   */
  mockResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // si le pattern d'url marche, alors on renvoi les data
    if (this.matchUrl(request)) {
      return Observable.of(null).mergeMap(() => {
        return Observable.of(new HttpResponse({ status: 200, body: this.getData() }));
      });
    } else {
      return null;
    }
  }

  matchUrl(request: HttpRequest<any>): boolean {
    return request.url === this.getUrlPattern();
  }

  /**
   * Retourne le pattern d'url à utiliser pour le check d'url
   */
  abstract getUrlPattern(): string;

  abstract getData(): any;

  /*
   *Construit un objet de type NpApiResult qui doit être retourné par les api nextPage
   * Cette fonction permet d'injecter des data dans l'objet NpApiResult
   * Il s'agit presque d'un helper pour les classes filles
   *
   */
  protected BuildAPIResult(data: object): NpApiResult {
    const result = new NpApiResult();
    result.Errors = [];
    result.Warnings = [];
    result.Results = data;
    return result;
  }
}
export abstract class AddedDataMocker extends DataMocker {}
