<div class="dialog-content__filters-container">
    <form class="d-flex mb-4 dialog-content__filters-search">
        <mat-form-field appearance='outline' class="search-input">
            <mat-label>{{ 'publication.catalogue.search-product' | translate }}</mat-label>
            <input type='search' matInput
                   [formControl]="searchFormControl"
                   (keyup.enter)='searchProducts($event.target.value)' #searchFilter/>
        </mat-form-field>
        <button mat-icon-button
                class="submit-btn"
            type='submit'>
            <fa-icon class="d-flex" [icon]="['far', 'magnifying-glass']"></fa-icon>
        </button>
        <img src="./assets/icons/x-mark.svg"
            class="fontawesome-icon-custom ml-2 cursor-pointer"
            alt="Reset"
            *ngIf="searchFilter.value !== ''"
            (click)="resetSearch($event)"/>
    </form>

    <ng-container *ngIf="choiceCriteriasView$ | async as choiceCriterias">
        <form class="d-flex flex-wrap align-items-center dialog-content__filters-criterias"
        *ngIf="choiceCriterias.length > 0"
        [formGroup]='productsFiltersForm'>
            <ng-container *ngFor="let choiceCriteria of choiceCriteriasList">
                <p-multiSelect 
                *ngIf="choiceCriteria?.facets?.length > 0"
                [pTooltip]="choiceCriteriasSelected.get(choiceCriteria.DicoCaracID)?.length > 0 ? choiceCriteria.label : ''" 
                tooltipPosition="top"   
                [showHeader]="false"
                [placeholder]="choiceCriteria.label"
                styleClass="filters-dropdown"
                panelStyleClass="filters-dropdown-panel"
                [options]="choiceCriteria.facets"
                [formControlName]="choiceCriteria.DicoCaracID"
                optionLabel="Value"
                (onChange)="filterByFacet(choiceCriteria)">
                    <ng-template let-facet pTemplate="item">
                        {{ facet.Value }} ({{facet.Count}})
                    </ng-template>
                </p-multiSelect>
            </ng-container>
    
            <button *ngIf="choiceCriteriasSelected.size > 0"
                    (click)="clearAllFilters()"
                    mat-stroked-button
                    class="reset-btn">
                {{ 'publication.catalogue.reset-all' | translate }}
                <mat-icon iconPositionEnd>clear</mat-icon>
            </button>
        </form>
    </ng-container>
</div>
