import {createAction, props} from '@ngrx/store';
import {AdvancedSearchDTO} from '@data/types';

export const FetchAdvancedSearch = createAction('[Advanced Search] Fetch Advanced Search');
export const AdvancedSearchSuccess = createAction('[Advanced Search] Fetch Advanced Search Success', props<{
    advancedSearch: AdvancedSearchDTO
}>());
export const AdvancedSearchFailure = createAction('[Advanced Search] Fetch Advanced Search Failure', props<{
    message: string
}>());
