import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Store} from '@ngrx/store';
import {ElementSummary, ProductSummary} from 'src/app/data/models';
import {SearchDisplayFieldType, TABLE_HEADER_LABELS} from 'src/app/data/constants';
import {selectFavoritesExtIds} from 'src/app/store/selector';
import {MatTableDataSource} from '@angular/material/table';
import {
    DialogElementWriterComponent
} from 'src/app/graphics/components/dialog-element-writer/dialog-element-writer.component';
import {EntityName} from 'src/lib/data/model/portal';
import {NpInstanceService} from '@data/services';
import {MatSort, Sort} from '@angular/material/sort';
import {SortType} from '@data/types';
import {DialogService} from 'src/app/graphics/services/dialog.service';
import {ElementSelectionService} from '@data/services';
import {ElementsSelection, selectExportSelection} from '@data/selection';
import {FavoriteActions} from '@store/favorites';

@Component({
    selector: 'app-elements-table',
    templateUrl: './elements-table.component.html',
    styleUrls: ['./elements-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ElementsTableComponent implements OnDestroy {
    @Input()
    set dataSource(value: MatTableDataSource<ProductSummary>) {
        this._dataSource = value;
        this.isToggleAllFavorites = false;
    }
    @Input() columnsToDisplay: string[] = [];
    @Input() public headerLabels = TABLE_HEADER_LABELS;
    @Output() sortChange = new EventEmitter<Partial<SortType>>();
    @Output() addAllElementsToFavorites = new EventEmitter<boolean>();

    @ViewChild(MatSort) sort: MatSort;

    public isSupplierEntityName: boolean;
    public favoritesState$ = this._store.select(selectFavoritesExtIds);
    public imageByDefault$ = this._instanceService.getImageByDefault();
    public exportSelectionView$ = this._store.select(selectExportSelection);
    public isToggleAllFavorites = false;

    get dataSource(): MatTableDataSource<ProductSummary> {
        return this._dataSource;
    }

    private _dataSource: MatTableDataSource<ProductSummary>;

    constructor(
        private _store: Store,
        private _dialog: MatDialog,
        private _instanceService: NpInstanceService,
        private _dialogService: DialogService,
        private _selectionService: ElementSelectionService
    ) {
        this.isSupplierEntityName = this._instanceService.currentEntity.name === EntityName.Supplier;
    }

    public onSortChange(sortedColumn: Sort) {
        if (this.sort instanceof MatSort) {
            const caracId = this.assignFieldType(sortedColumn.active);
            this.sortChange.emit({
                FieldType: caracId,
                DicoCaracID: caracId,
                SortDesc: sortedColumn.direction === 'desc'
            });
        }
    }

    public assignFieldType(columnName: string): SearchDisplayFieldType {
        switch (columnName) {
            case 'label':
                return SearchDisplayFieldType.Label;
            case 'modificationDate':
                return SearchDisplayFieldType.ModificationDate;
            case 'status':
                return SearchDisplayFieldType.WFStatus;
            default:
                return SearchDisplayFieldType.Label;
        }
    }

    public isSortableColumn(column: string): boolean {
        return column === 'label' || column === 'status' || column === 'modificationDate';
    }

    public toggleFavorite(elementSummary: ElementSummary, $event: Event) {
        $event.stopPropagation();
        elementSummary.isFavoriteElement = !elementSummary.isFavoriteElement;

        this._store.dispatch(
            FavoriteActions.toggleFavorite({
                favorites: {
                    extID: elementSummary.element.ExtID
                },
            })
        );
    }

    public showMoreInfos(element: ProductSummary, $event: Event) {
        $event.stopPropagation();
        this._dialogService.openProductDialog(element);
    }

    public editInfos(element, $event: MouseEvent) {
        if (this.isSupplierEntityName) {
            $event.stopPropagation();
            this._dialog.open(DialogElementWriterComponent, {
                height: '95%',
                width: '80%',
                data: element
            });
        }
    }

    public selectAllElements(checked: boolean) {
        this._dataSource.data.forEach(row => row.selected = checked);
        this._store.dispatch(ElementsSelection.setSelectAllElements({isSelectAllProducts: checked}));
    }

    public addToSelection(selectedItem: ProductSummary, checked: boolean) {
        this._store.dispatch(ElementsSelection.addToSelection({elementId: selectedItem.element?.ID, checked: checked}));
    }

    toggleAllFavorites() {
        this.isToggleAllFavorites = !this.isToggleAllFavorites;
        this.addAllElementsToFavorites.emit(this.isToggleAllFavorites);
    }
    ngOnDestroy() {
        this._store.dispatch(ElementsSelection.cleanStore());
    }

}
