import {Component, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RouteParamName} from '../../../data/constants/route-portals.constants';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '@env';
import {NP_INSTANCE_CONFIG} from '../../../data/constants/np-instance.constants';
import {Constantes} from '@nextpage/np-sdk-data';

@Component({
    selector: 'app-base',
    template: '',
    standalone: true
})
export class BaseComponent {
    protected instanceId: string;
    private _activatedRoute = inject(ActivatedRoute);
    private constants = inject(Constantes);


    // TODO: Delete after Angular updation to v16 and use parameter binding
    afterIntInstanceConfig() {
        return this._activatedRoute.paramMap
            .pipe(
                switchMap(params => {
                    if (params && params.has(RouteParamName.INSTANCE_ID)) {
                        this.instanceId = params.get(RouteParamName.INSTANCE_ID);
                        this._initEnvironment();
                        of(params);
                    } else {
                        return of(null);
                    }
                })
            );
    }

    private _initEnvironment () {
        const instance = NP_INSTANCE_CONFIG.get(this.instanceId);
        environment.instance = instance;
        this.constants.baseUrl = instance.npBaseUrl;
    }


}
