<ng-container *ngIf="ready()">
    <div class="table-responsive data-table">
        <table class="table">
            <thead>
            <tr>
                <th *ngFor="let column of columns">{{getColumnName(column)}}</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="elements && elements.length > 0">
                <tr *ngFor="let tableRow of elements" [class]="isFull(tableRow.element) ? 'fillRateInfo': ''"
                    (mouseover)="tableRow.over = true" (mouseleave)="tableRow.over = false">
                    <td *ngFor="let column of columns">
                        <lib-app-value-view [element]="tableRow.element"
                                            [caracIdStateValue]="getCISV(tableRow.element, column)"></lib-app-value-view>
                    </td>
                    <ng-container *ngIf="!readOnlyAccess">
                        <td class="delete-block" title="Cliquez pour supprimer cette ligne">
                            <mat-icon [class.hide-me]="!tableRow.over" [class.display-me]="tableRow.over"
                                      (click)="delete(tableRow)">delete
                            </mat-icon>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            </tbody>
        </table>

        <ng-container *ngIf=" elements.length <= 0">
            <div class="no-data-block">
                Aucune information disponible !
            </div>
        </ng-container>

        <ng-container *ngIf="!readOnlyAccess">
            <div class="add-btn-block">
                <mat-icon [matMenuTriggerFor]="menu">add_circle</mat-icon>
                <mat-menu class="menu-block" #menu="matMenu">
                    <div>
                        <div>
                            <input matInput class="search-input" value="" (click)="$event.stopPropagation()"
                                   npUiTranslate="_rechercherCombobox" translateIn="placeholder"
                                   [formControl]="searchTextControl" autofocus>
                        </div>
                        <div class="table-items-block">
                            <ng-container *ngIf="listPossibles && listPossibles.length > 0; else noDataBlock">
                                <p (click)="addElement(possible)"
                                   *ngFor="let possible of listPossibles">{{possible.elementName}}</p>
                            </ng-container>
                            <ng-template #noDataBlock>
                                <div class="no-menu-data">Aucune information disponible!</div>
                            </ng-template>
                        </div>
                    </div>

                </mat-menu>
            </div>
        </ng-container>

    </div>

</ng-container>
