import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {convertFacetMapToItems, getFacetValueByType} from 'src/app/core';
import {CharsBuilder, ItemBuilder, ParamsFilterBuilder, SelectedDicoBuilder} from 'src/app/data/builders';
import {CONSULTATION_DEFAULT_PAGE_INDEX} from 'src/app/data/constants/products-page-size.constants';
import {ProductsFacade} from 'src/app/data/facades';
import {CharTemplateDto, ParamsFilter} from 'src/app/data/models';
import {Chars, ChoiceCriteria, Facet, Item, SelectedDico} from 'src/app/data/types';
import {environment} from 'src/environments/environment';
import {NpwFilterLogicalOperator, NpwFilterOperator} from 'src/lib';
import {AdvSearchArgs, NPElement} from 'src/lib/data/model';
import {ExportBaseComponent} from '../base/export-base.component';
import {swapFacets} from '@np/utils';

@Component({
    selector: 'app-char-template-filters',
    templateUrl: './char-template-filters.component.html',
    styleUrls: ['./char-template-filters.component.scss']
})
export class CharTemplateFiltersComponent extends ExportBaseComponent implements OnDestroy {

    get charTemplates(): CharTemplateDto[] {
        return this._charTemplates;
    }

    @Input() set charTemplates(value: CharTemplateDto[]) {
        this._charTemplates = value;

        if (this._charTemplates?.length === 1) {
            // if only one Product Type, we select it by default
            this._initSelectedCharTemplate(this._charTemplates[0]);
        }
    }

    @Input() channelCategorySelected: NPElement;
    @Input() defaultChannelScope: { scopeId: number | null; isChannel: boolean };
    @Input() paramFilterBuilder: ParamsFilterBuilder;
    @Input() selectedDataLanguageId: number;
    @Input() advancedSearch: AdvSearchArgs;
    @Input() defaultItems: Item[] = [];

    @Output() filterByProductType = new EventEmitter<{ productType: CharTemplateDto }>();
    @Output() filterElements = new EventEmitter<ParamsFilter>();
    @Output() resetElements = new EventEmitter<boolean>();

    @Output() resetAllFilters = new EventEmitter<boolean>();

    public formFilter: FormGroup;
    public filtersCriteriaList: ChoiceCriteria[];

    private _charTemplates: CharTemplateDto[] = [];
    private _currentFacet: ChoiceCriteria;
    private _selectedChoiceCriteria = new Map<number, Facet[]>();
    private _currentValue: Facet[] = [];
    private _newValue: Facet[] = [];
    private _filtersCriteriaSub: Subscription;

    constructor(
        private _fb: FormBuilder,
        private _productsFacade: ProductsFacade) {
        super();

        this.formFilter = this._fb.group({
            formProductType: [''],
            formChannelCategories: ['']
        });
    }

    public onChoicesCriteriaDropdownOpened(dropdownOpened: boolean, filtersCriteria: ChoiceCriteria): void {
        if (dropdownOpened) {
            this._currentValue = this.formFilter.get(filtersCriteria.DicoCaracID.toString())?.value;

            if (!_.isEqual(this._newValue, this.formFilter.get(filtersCriteria.DicoCaracID.toString())?.value)) {
                this._newValue = this.formFilter.get(filtersCriteria.DicoCaracID.toString())?.value;
            }
        } else {
            const currentValueOfCurrentFacet = this._currentValue.filter(currentValue => currentValue.DicoCaracID === filtersCriteria.DicoCaracID);
            const newValueOfCurrentFacet = this._newValue.filter(newValue => newValue.DicoCaracID === filtersCriteria.DicoCaracID);

            if (!_.isEqual(currentValueOfCurrentFacet, newValueOfCurrentFacet)) {
                this.filterByFacet(filtersCriteria);
            }
        }
    }

    public onFacetsChange(selectedFacets: Facet[]): void {
        this._newValue = selectedFacets;
    }


    public clearAllFilters(): void {
        this.resetAllSelectedFilters();

        this.resetAllFilters.emit(true);
    }

    public clearSingleFilter(dicoCaracId: number): void {
        this._selectedChoiceCriteria.delete(dicoCaracId);

        if (this.formFilter.get(dicoCaracId.toString())?.value !== 'reset') {
            this.formFilter.get(dicoCaracId.toString()).patchValue([]);
        }

        this._initializeFilters(dicoCaracId);
    }

    public resetData(scope?: { scopeId: number | null; isChannel: boolean }): void {
        this.resetAllSelectedFilters();

        this.paramFilterBuilder
            .withScope(scope)
            .withChars(new Chars())
            .withProductTypeId(null)
            .withPage(CONSULTATION_DEFAULT_PAGE_INDEX);
    }

    public filterByTypeProduct($event: MatSelectChange): void {

        this.resetAllSelectedFilters();

        const filtersCriteriaList = $event.value.CharTemplateChoiceCriterias;
        if (filtersCriteriaList) {
            this._buildForm(filtersCriteriaList, $event.value);

            this.filtersCriteriaList = filtersCriteriaList;

            if (filtersCriteriaList && filtersCriteriaList.length > 0) {
                this.filtersFacets(filtersCriteriaList, $event.value.ID);
            }

            // Search products/media by ProductType
            this.filterByProductType.emit({productType: $event.value});

        } else if ($event.value === 'reset') {
            this.clearAllFilters();
        }
    }

    public filterByFacet(filtersCriteria: ChoiceCriteria): void {
        const choiceCriteriasSelected = this.formFilter.get(filtersCriteria.DicoCaracID.toString())?.value;
        if (choiceCriteriasSelected.length > 0) {
            this._selectedChoiceCriteria.set(filtersCriteria.DicoCaracID, choiceCriteriasSelected);
        } else {
            this._selectedChoiceCriteria.delete(filtersCriteria.DicoCaracID);
        }

        this._initializeFilters(filtersCriteria.DicoCaracID);
    }

    public filtersFacets(filtersList: ChoiceCriteria[], productTypeID: number, index?: number, chars?: Chars): void {
        if (this._currentFacet
            && this._selectedChoiceCriteria.has(this._currentFacet.DicoCaracID)
            && !isNaN(index)) {
            filtersList.splice(index, 1);
        }

        if (productTypeID) {
            if (this.advancedSearch?.Config?.Filters) {
                this.advancedSearch.Config.Filters.ProductsTypeID = productTypeID;
                this.advancedSearch.Config.Filters.ProductsType375 = {
                    Items: {[productTypeID]: ''}
                };

                chars = new CharsBuilder()
                    .withItems([...chars?.Items || [], ...this.defaultItems])
                    .withLogicalOperator(NpwFilterLogicalOperator.AND)
                    .build();

                this.advancedSearch.Config.Filters.Chars = chars;
            }

            this._filtersCriteriaSub = this._productsFacade
                .completeChoiceCriteriaWithFacets(
                    filtersList,
                    productTypeID,
                    this.selectedDataLanguageId,
                    !!this.advancedSearch ? null : chars,
                    !!this.advancedSearch ? null : {
                        scopeId: this.channelCategorySelected?.ID || this.defaultChannelScope?.scopeId,
                        isChannel: this.defaultChannelScope?.isChannel
                    },
                    this.advancedSearch?.Config
                ).subscribe((criteriasChoice: ChoiceCriteria[]) => {
                    this.filtersCriteriaList = criteriasChoice;
                    this.filtersCriteriaList.map(criteria => {
                        if (_.isEmpty(criteria.initialFacets)) {
                            criteria.initialFacets = Object.assign([], criteria.facets);
                        } else {
                            swapFacets(criteria);
                        }
                        if (this._selectedChoiceCriteria.size === 1 && this._selectedChoiceCriteria.keys().next().value === criteria.DicoCaracID) {
                            criteria.facets = Object.assign([], criteria.initialFacets);
                        }
                    });

                    if (this._currentFacet
                        && this._selectedChoiceCriteria.has(this._currentFacet.DicoCaracID)
                        && !isNaN(index)) {
                        this.filtersCriteriaList.unshift(this._currentFacet);
                    }

                    this._selectedChoiceCriteria.forEach((values, key) => {
                        const dropdownChoiceCriteriasSelectedFacets = this.filtersCriteriaList.find(criteria => criteria.DicoCaracID === key)?.facets || [];
                        const intersections = _.intersectionBy(dropdownChoiceCriteriasSelectedFacets, values, 'Value');
                        this.formFilter.get(key.toString())?.setValue(intersections);
                    });

                    if (this._currentFacet?.DicoCaracID && this.formFilter.get(this._currentFacet.DicoCaracID.toString())?.value.length === 0) {
                        const facetToMove = _.pullAt(this.filtersCriteriaList, index)[0];
                        this.filtersCriteriaList.push(facetToMove);
                    }

                    this._filtersCriteriaSub.unsubscribe();
                });
        }
    }

    private _buildForm(filtersCriteriaList: ChoiceCriteria[], charTemplateDto: CharTemplateDto): void {
        this.formFilter = this._fb.group({
            formProductType: this._fb.control(charTemplateDto)
        });

        filtersCriteriaList.forEach(criteria =>
            this.formFilter.addControl(criteria.DicoCaracID.toString(), this._fb.control([]))
        );
    }

    private _initializeFilters(dicoCaracID: number): void {
        this._currentFacet = this.filtersCriteriaList.find(criteria => criteria.DicoCaracID === dicoCaracID);

        const chars: Chars = new CharsBuilder()
            .withLogicalOperator(NpwFilterLogicalOperator.AND.toLowerCase())
            .withItems(convertFacetMapToItems(this._selectedChoiceCriteria)).build();

        const productTypeID = this.formFilter.get('formProductType')?.value.ID;

        const indexOfCurrentFacet = this.filtersCriteriaList
            ?.map(criteria => criteria?.DicoCaracID)
            ?.indexOf(this._currentFacet.DicoCaracID);

        this.filtersFacets(this.filtersCriteriaList, productTypeID, indexOfCurrentFacet, chars);

        this._initFilterData();
    }

    private _initFilterData(): void {
        if (!!this.paramFilterBuilder) {
            const items = this._buildItems();
            const chars: Chars = new CharsBuilder()
                .withLogicalOperator(NpwFilterLogicalOperator.AND.toLowerCase())
                .withItems(items).build();

            this.paramFilterBuilder
                .withElementTypes([environment.instance.elementType])
                .withChars(chars);
        }

        this.filterElements.emit(null);
    }

    private _buildItems(): Item[] {
        const items: Item[] = [];

        if (this._selectedChoiceCriteria.size > 0) {
            Array.from(this._selectedChoiceCriteria.values())
                ?.reduce((acc, value) => [...acc, ...value])
                .map((fieldFilters: Facet) => {
                    const selectedDico: SelectedDico = new SelectedDicoBuilder()
                        .withID(fieldFilters.DicoCaracID)
                        .withTypeCode(fieldFilters.typeCode)
                        .build();

                    items.push(
                        new ItemBuilder()
                            .withOperatorValue(NpwFilterOperator.Equal)
                            .withValue(getFacetValueByType(fieldFilters))
                            .withSelectedDico(selectedDico).build()
                    );
                });
        }

        return items;
    }

    public resetAllSelectedFilters() {
        this.filtersCriteriaList = [];
        this._currentFacet = null;
        this._newValue = [];
        this._currentValue = [];
        this.formFilter.reset();
        this._selectedChoiceCriteria.clear();
    }

    private _initSelectedCharTemplate(selectedCharTemplate: CharTemplateDto) {
        if (selectedCharTemplate) {
            const selectedTemplateCriteria = selectedCharTemplate.CharTemplateChoiceCriterias;
            this._buildForm(selectedTemplateCriteria, selectedCharTemplate);
            this.filtersCriteriaList = selectedTemplateCriteria;

            this._handleTemplateCriteria(selectedCharTemplate);
            this._emitProductType();
        }
    }

    private _handleTemplateCriteria(selectedCharTemplate: CharTemplateDto) {
        if (selectedCharTemplate && selectedCharTemplate.CharTemplateChoiceCriterias?.length > 0) {
            this.filtersFacets(selectedCharTemplate.CharTemplateChoiceCriterias, selectedCharTemplate.ID);
        }
    }

    private _emitProductType() {
        this.filterByProductType.emit({productType: this._charTemplates[0]});
    }

    ngOnDestroy() {
        this._filtersCriteriaSub?.unsubscribe();
    }

}
