import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FileActions = createActionGroup({
    source: 'favorite',
    events: {
        'fetch thumb parameters': emptyProps(),
        'fetch thumb parameters success': props<{ thumbsParameters: { allowedThumbExtensions: string[], allowedThumbSizes: string[] }}>(),
        'fetch thumb parameters failure ': props<{ message: string}>(),
    },
});
