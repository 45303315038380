export class Language {
    code: LangCode;
    label: LangLabel;
}

export enum LangCode {
    FR = 'fr',
    EN = 'en'
}

export enum LangLabel {
    FR = 'Français',
    EN = 'English'
}

export const LanguagesList: Language[] = [
    { code: LangCode.FR, label: LangLabel.FR },
    { code: LangCode.EN, label: LangLabel.EN }
];
