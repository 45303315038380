import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProductWrapper } from "@app/models";

@Component({
    selector: 'app-dialog-product-deletion',
    templateUrl: './dialog-product-deletion.component.html',
    styleUrls: ['./dialog-product-deletion.component.scss']
})
export class DialogProductDeletionComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: ProductWrapper) {}
}