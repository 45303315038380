import { AdvSearchArgs } from 'src/lib';
import { AdvancedSearchDTO } from '../types';

export class AdvSearchArgsBuilder {
  private _Type: number;
  private _AdvancedSearchID: number;
  private _Config: AdvancedSearchDTO;
  private _CurrentPage: number;
  private _PageSize: number;
  private _Paths: string[][];
  private _DCExtIDs: string[];
  private _LangID: number;
  private _ContexteID = 1;
  private _UILangCode: 'fr';
  private _Facets: string[];

  withType(value: number): AdvSearchArgsBuilder {
    this._Type = value;
    return this;
  }

  withAdvancedSearchID(value: number): AdvSearchArgsBuilder {
    this._AdvancedSearchID = value;
    return this;
  }

  withConfig(value: AdvancedSearchDTO): AdvSearchArgsBuilder {
    this._Config = value;
    return this;
  }

  withCurrentPage(value: number): AdvSearchArgsBuilder {
    this._CurrentPage = value;
    return this;
  }

  withPageSize(value: number): AdvSearchArgsBuilder {
    this._PageSize = value;
    return this;
  }

  withPaths(value: string[][]): AdvSearchArgsBuilder {
    this._Paths = value;
    return this;
  }

  withDCExtIDs(value: string[]): AdvSearchArgsBuilder {
    this._DCExtIDs = value;
    return this;
  }

  withLangID(value: number): AdvSearchArgsBuilder {
    this._LangID = value;
    return this;
  }

  withContexteID(value: number): AdvSearchArgsBuilder {
    this._ContexteID = value;
    return this;
  }

  withUILangCode(value: 'fr'): AdvSearchArgsBuilder {
    this._UILangCode = value;
    return this;
  }

  withFacets(value: string[]): AdvSearchArgsBuilder {
    this._Facets = value;
    return this;
  }

  build(): AdvSearchArgs {
    return {
      Type: this._Type ?? 0,
      AdvancedSearchID: this._AdvancedSearchID ?? 0,
      Config: this._Config,
      CurrentPage: this._CurrentPage,
      PageSize: this._PageSize,
      Paths: this._Paths ?? [],
      DCExtIDs: this._DCExtIDs ?? [],
      LangID: this._LangID ?? 1,
      ContexteID: this._ContexteID ?? 1,
      UILangCode: this._UILangCode ?? 'fr',
      Facets: this._Facets,
    };
  }
}
