import {SearchDisplayFieldType} from '@data/constants';

export class SortType {
  DicoCaracID: number;
  ExtID?: string;
  FieldType: SearchDisplayFieldType;
  External?: string;
  Order?: number;
  SortDesc: boolean;
  SelectedItem?: SearchSortFieldDto;
}

export class SearchSortFieldDto {
  ID: number;
  UniqueKey: string;
  AdvancedSearchID: number;
  Name: string;
  SearchDisplayFieldType: SearchDisplayFieldType;
  DicoCaracID?: number;
  Type: string;
  Order: number;
}



