import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CaracInfo} from '../np-value/Model';
import {DicoCarac, NPCaracLien} from '@nextpage/np-sdk-data';
import {ImageObject} from '../np-value/np-value-image-read-view/np-value-image-read-view.component';
import {FileReaderService} from '../../services';

@Component({
    selector: 'lib-np-simple-image-view',
    templateUrl: './np-simple-image-view.component.html',
    styleUrls: ['./np-simple-image-view.component.css']
})
export class NpSimpleImageViewComponent implements OnInit, OnChanges {

    @Input() caracInfo: CaracInfo;
    @Input() value: NPCaracLien;

    photosUrl: ImageObject;

    constructor(private _fileReader: FileReaderService) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.value.RebuildLinkedElements == null) {
            this.photosUrl = undefined;
            return;
        }
        const tmpLink = this.value.RebuildLinkedElements[0];
        if (tmpLink) {
            this.photosUrl = {
                src: `${this._fileReader.toUrlThumbImage(tmpLink.Element)}`,
                thumbSrc: this._fileReader.toUrlThumbImage(tmpLink.Element),
                alt: tmpLink.Element.getValueTextValue(DicoCarac.MEDIA_FILE)
            };
        }
    }

}
