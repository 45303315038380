import {CaracConfig, NPElement, ValueOneElementHelper} from '@nextpage/np-sdk-data';
import {CaracIdStateValue} from '@nextpage/np-sdk-graphics';

export class SpValueHelper {
  private static _valueHelper = new ValueOneElementHelper();

  public static toBlock(element: NPElement, config: CaracConfig): CaracIdStateValue {
    return {
      config: config,
      element: element,
      value: SpValueHelper._valueHelper.getCaracValue(element, config),
      baseElement: null
    };
  }
}

