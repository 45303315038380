import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SearchRepository} from '../../../lib';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AdvancedSearchFailure, AdvancedSearchSuccess, FetchAdvancedSearch} from '../actions/advanced-search.actions';
import {Store} from '@ngrx/store';
import {selectAdvancedSearchId} from '../selector/user-info.selectors';
import {of, throwError} from 'rxjs';

@Injectable()
export class AdvancedSearchEffect {
    constructor(private actions$: Actions,
                private _searchService: SearchRepository,
                private _store: Store) {
    }

    fetchAdvancedSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchAdvancedSearch),
            switchMap(() => {
                return this._store.select(selectAdvancedSearchId)
                    .pipe(
                        map(advancedSearchId => advancedSearchId ?
                            advancedSearchId :
                            throwError('Advanced search Id not fount. Please create ou fill CP_MEDIA_ADVANCED_SEARCH_ID in your NEXTPAGE@ instance.')
                        )
                    );
            }),
            switchMap(advancedSearchId => {
                    return this._searchService.getAdvancedSearch(Number(advancedSearchId))
                        .pipe(map(result => AdvancedSearchSuccess({advancedSearch: result})));
                }
            ),
            catchError(error => of(AdvancedSearchFailure({message: error?.toString()})))
        );
    });
}
