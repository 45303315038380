import {AfterContentChecked, Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
    selector: '[appEqualize]'
})
export class EqualizeDirective implements AfterContentChecked {

    constructor(private element: ElementRef, private _renderer: Renderer2) {
    }

    ngAfterContentChecked() {
        const nativeElement = <HTMLElement>this.element.nativeElement;
        // const test = this._renderer.
        nativeElement.classList.add('equalize');
        let maxHeight = 0;
        // Récupération de la taille maximale
        for (let i = 0; i < nativeElement.children.length; i++) {
            const child = <HTMLDivElement>nativeElement.children.item(i);
            // Si l'enfant est une 'div' ou 'span' ou 'p'
            if (child.nodeType === child.ELEMENT_NODE) {
                maxHeight = child.offsetHeight > maxHeight ? child.offsetHeight : maxHeight;
            }
        }

        // Attribution de la taille maximale à tous les enfants
        for (let i = 0; i < nativeElement.children.length; i++) {
            const child = <HTMLDivElement>nativeElement.children.item(i);
            // Si l'enfant est une 'div' ou 'span' ou 'p'
            if (child.nodeType === child.ELEMENT_NODE) {
                this._renderer.setStyle(child, 'height', maxHeight + 'px');
            }
        }
    }
}
