import {Injectable} from '@angular/core';
import {CaracIdStateValue} from '../graphics/np-value/Model';
import {DicoCarac, NPCaracLienRebuildValue, NPElement, CaracConfig} from '@nextpage/np-sdk-data';

@Injectable({
    providedIn: 'root'
})
export class TreeviewService {

    public recursiveDS = 'DC_DS_INGREDIENT_DE_RECETTE_DEPUIS_RECETTE';
    public recursiveDSdepuisDS = 'DC_DS_INGREDIENT_DE_RECETTE_DEPUIS_INGREDIENT_DE_RECETTE';
    public caracLabelIngredient = 'DC_LR_I_ET_MP_DEPUIS_INGREDIENT_DE_RECETTE';
    private _rebuildLinkedElements: NPCaracLienRebuildValue[] = [];

    constructor() {
    }

    static _getIngredientLabel(element: NPElement, caracLabelIngredient: string) {
        const tmpValueLien = element.getValueLien(caracLabelIngredient);
        return tmpValueLien
        && tmpValueLien.RebuildLinkedElements
        && tmpValueLien.RebuildLinkedElements[0] ?
            `${tmpValueLien.RebuildLinkedElements[0].Element.getValueTextValue(DicoCarac.PRODUCT_LABEL)}`
            : 'Aucun élément trouvé';
    }

    static _getPrefix(currentIndexLevel: number, parentPrefix: string) {
        return parentPrefix ? `—${parentPrefix}.${currentIndexLevel}` : `${currentIndexLevel}`;
    }

    /**
     * Retourne le noml de la class css en fonction du niveau
     * Cela permettra des définir un css à chaque niveau de treeview
     */
    static _getLevelStyle(currentLevel: number): string {
        return currentLevel ? `level_${currentLevel}` : '';
    }

    /*createIngredientsTreeview(element: NPCaracLienRebuildValue[], recursiveDSdepuisDS: string, caracLabelIngredient: string, parentIndex = null, level = 1): TreeviewItem[] {
        return element.map((item, index) => {
            const childrensElements = item.Element.getValueLien(recursiveDSdepuisDS);
            const currentPrefixLabel = TreeviewService._getPrefix(index + 1, parentIndex);
            if (!childrensElements || !childrensElements.Element) {
                return null;
            }
            const currentLabel = TreeviewService._getIngredientLabel(childrensElements.Element, caracLabelIngredient);
            return ({
                title: `${currentPrefixLabel} - ${currentLabel}`,
                element: item.Element.getValueLien(caracLabelIngredient).RebuildLinkedElements[0].Element,
                children: childrensElements.RebuildLinkedElements.length > 0 ?
                    this.createIngredientsTreeview(childrensElements.RebuildLinkedElements, recursiveDSdepuisDS, caracLabelIngredient, currentPrefixLabel, level + 1) : [],
                indexStyle: TreeviewService._getLevelStyle(level),
                opened: false,
                hasContent: level > 1 // On n'affiche le contenu du niveau 1
            });
        });
    }*/

    public createIngredientsTreeview(element: NPCaracLienRebuildValue[], recursiveDSdepuisDS: string, caracLabelIngredient: string, parentIndex = null, level = 1): TreeviewItem[] {
        return element.map((item, index) => {
            const childrensElements = item.Element.getValueLien(recursiveDSdepuisDS);
            const currentPrefixLabel = TreeviewService._getPrefix(index + 1, parentIndex);
            const currentLabel = TreeviewService._getIngredientLabel(item.Element, caracLabelIngredient);
            const currElement = item.Element.getValueLien(caracLabelIngredient).RebuildLinkedElements[0];
            return ({
                title: `${currentPrefixLabel} - ${currentLabel}`,
                element: currElement ? currElement.Element : null,
                children: childrensElements && childrensElements.RebuildLinkedElements.length > 0 ?
                    this.createIngredientsTreeview(childrensElements.RebuildLinkedElements, recursiveDSdepuisDS, caracLabelIngredient, currentPrefixLabel, level + 1) : [],
                indexStyle: TreeviewService._getLevelStyle(level),
                opened: false,
                hasContent: true
            });
        });
    }

    setRebuildLinkedElements(rebuildLinkedElements: NPCaracLienRebuildValue[], caracConfig?: CaracConfig) {
        if (rebuildLinkedElements) {
            this._rebuildLinkedElements = rebuildLinkedElements;
        }
        if (caracConfig) {
            this.initDataProperties(caracConfig);
        }
    }

    initDataProperties(caracConfig: CaracConfig) {
        if (caracConfig && caracConfig.Specific) {
            this.recursiveDS = caracConfig.Specific[TREEVIEW_CONSTANTES.RecursiveDS] ? caracConfig.Specific[TREEVIEW_CONSTANTES.RecursiveDS] : this.recursiveDS;
            this.recursiveDSdepuisDS = caracConfig.Specific[TREEVIEW_CONSTANTES.RecursiveDSdepuisDS] ? caracConfig.Specific[TREEVIEW_CONSTANTES.RecursiveDSdepuisDS] : this.recursiveDSdepuisDS;
            this.caracLabelIngredient = caracConfig.Specific[TREEVIEW_CONSTANTES.CaracLabelIngredient] ? caracConfig.Specific[TREEVIEW_CONSTANTES.CaracLabelIngredient] : this.caracLabelIngredient;
        }
    }

    getTreviewData(): TreeviewItem[] {
        let tmpTreeviewData: TreeviewItem[] = [];
        if (this._rebuildLinkedElements && this._rebuildLinkedElements.length > 0) {
            let parents: NPCaracLienRebuildValue[] = [];
            this._rebuildLinkedElements.forEach(currElement => {
                const linkedElement = currElement.Element.getValueLien(this.recursiveDS);
                parents = parents.concat(linkedElement && linkedElement.RebuildLinkedElements ?
                    linkedElement.RebuildLinkedElements : []);
            });
            tmpTreeviewData = this.createIngredientsTreeview(parents, this.recursiveDSdepuisDS, this.caracLabelIngredient)
                .filter(item => item !== undefined && item !== null);
        }
        return tmpTreeviewData ? tmpTreeviewData : [];
    }

    /**
     * Retourne la config à passer en paramètre d'entrée du composnat lib-app-value-view
     */
    getCISV(caracConfig: CaracConfig, element: NPElement): CaracIdStateValue {
        const tmpCaraValue = element.getValue(caracConfig.DicoCaracExtID);
        if (tmpCaraValue) {
            return {
                value: element.getValue(caracConfig.DicoCaracExtID),
                config: caracConfig,
                element: element,
                baseElement: element
            };
        }
        return null;
    }
}

export interface TreeviewItem {
    title: string;
    element: NPElement;
    children: TreeviewItem[];
    indexStyle: string;
    opened: boolean;
    hasContent: boolean;
}

enum TREEVIEW_CONSTANTES {
    RecursiveDS = 'RecursiveDS',
    RecursiveDSdepuisDS = 'RecursiveDSdepuisDS',
    CaracLabelIngredient = 'CaracLabelIngredient'
}


