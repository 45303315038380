<div class="writer-v2-container">
    <div class="writer-v2-container-content">
        <ng-container *ngIf="listLinked && listLinked.length > 0; else noDataBlock">
            <ul>
                <li *ngFor="let item of listLinked" [attr.title]="item.elementName" class="np-truncate-text">
                    {{item.elementName}}
                </li>
            </ul>
        </ng-container>
        <ng-template #noDataBlock>
            <span>---</span>
        </ng-template>

    </div>
</div>
