import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {selectMediaFolderId} from '@store/selector';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    createMediaByExternalUrl,
    createMediaByExternalUrlFailure,
    createMediaByExternalUrlSuccess, selectUploadImages,
    UpdateMedia,
    UpdateMediaFailure,
    UpdateMediaSuccess,
    UploadFileFailure,
    UploadFiles,
    UploadFileSuccess
} from '@store/import-media';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ElementWriterService} from '@nextpage/np-sdk-data';
import {DialogService} from '../../graphics/services/dialog.service';
import {MatDialogRef} from '@angular/material/dialog';

@Injectable()
export class ImportMediaEffect {
    constructor(private actions$: Actions,
                private _store: Store,
                private _elementWriter: ElementWriterService,
                private _dialogService: DialogService,
    ) {
    }

    uploadFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UploadFiles),
            concatLatestFrom(() => [this._store.select(selectMediaFolderId), this._store.select(selectUploadImages)]),
            mergeMap(([action, mediaFolderId, media]) => {
                if (!mediaFolderId) {
                    return of(UploadFileFailure({error: 'Folder ID is missing'}));
                }
                return this._elementWriter.createTableOfMedias(media.map(item => item), mediaFolderId)
                    .pipe(
                        map(() => UploadFileSuccess()),
                    );
            }),
            catchError(error => of(UploadFileFailure({error})))
        )
    );

    createMediaByExternalUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createMediaByExternalUrl),
            concatLatestFrom(() => this._store.select(selectMediaFolderId)),
            mergeMap(([action, mediaFolderId]) => {
                if (!mediaFolderId) {
                    return of(UploadFileFailure({error: 'Folder ID is missing'}));
                }
                return this._elementWriter.createMediaByExternalUrl(action.url, mediaFolderId)
                    .pipe(
                        map(() => createMediaByExternalUrlSuccess({url: ''})),
                    );
            }),
            catchError(error => of(UploadFileFailure({error})))
        )
    );

    uploadSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(UploadFileSuccess),
            tap(() => {
                this._dialogService.showSuccessMessage('medias.message-upload-success');
            })
        ),
        {dispatch: false}
    );

    uploadFailure$ = createEffect(
        () => this.actions$.pipe(
            ofType(UploadFileFailure),
            tap(() => {
                this._dialogService.showErrorMessage('medias.message-upload-failure');
            })
        ),
        {dispatch: false}
    );

    createMediaByExternalUrlSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(createMediaByExternalUrlSuccess),
            tap(() => {
                this._dialogService.showSuccessMessage('medias.message-url-media-upload-success');
            })
        ),
        {dispatch: false}
    );

    createMediaByExternalUrlFailure$ = createEffect(
        () => this.actions$.pipe(
            ofType(createMediaByExternalUrlFailure),
            tap(() => {
                this._dialogService.showErrorMessage('medias.message-url-media-upload-failure');
            })
        ),
        {dispatch: false}
    );

    updateMediaEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateMedia),
            mergeMap(action => {
                const {uploadMedia, media} = action;
                const mediaId = media.element.ID;
                const mediaFolderId = media.element.ParentID;
                return this._elementWriter.updateMedia(uploadMedia, mediaFolderId, mediaId).pipe(
                    map(file => UpdateMediaSuccess({uploadMedia: file})),
                );
            }),
            catchError(error => of(UpdateMediaFailure({error})))
        )
    );

    updateMediaSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(UpdateMediaSuccess),
            tap(() => {
                this._dialogService.showSuccessMessage('medias.message-media-update-success');
                setTimeout(() => {
                    this._dialogService.closeAll();
                }, 3000);
            })
        ),
        {dispatch: false}
    );

    updateMediaFailure$ = createEffect(
        () => this.actions$.pipe(
            ofType(UpdateMediaFailure),
            tap(() => {
                this._dialogService.showErrorMessage('medias.message-media-update-failure');
            })
        ),
        {dispatch: false}
    );
}
