import {NiveauAccesDonnee} from './np-carac-valeur';

export class WorkflowStatusDto {
  ID: number;
  Name: string;
  Access: NiveauAccesDonnee;
  Libelle: string;
  Color: string;
  Order: number;
  DefaultWFStatus: boolean;
  IsValidation: boolean;
}
