import {createReducer, on} from '@ngrx/store';
import {FetchCharTemplatesSuccess, FetchProductCharTemplatesFailure} from './char-template.actions';
import {CharTemplateState} from '../media-char-template';

export const productCharTemplateFeatureKey = 'productCharTemplate';
export const initialCharTemplateState: CharTemplateState = {
    charTemplates: [],
    errorMessage: null,
    loading: true,
    hasError: false,
};

export const productCharTemplateReducer = createReducer(
    initialCharTemplateState,
    on(
        FetchCharTemplatesSuccess,
        (state, {charTemplates}): CharTemplateState => {
            return ({
                ...state,
                charTemplates: charTemplates,
                loading: false,
                errorMessage: null,
                hasError: false
            });
        }
    ),
    on(FetchProductCharTemplatesFailure,
        (state, {message}) => ({
            ...state,
            errorMessage: message,
            loading: false,
            hasError: true
        })
    )
);
