<div class='row' [ngClass]="customClass">
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h3 class="m-0 page-title">{{ 'products.title' | translate }}</h3>
            <div class='mat-form-field-text'>
                <mat-form-field appearance='outline' class="field-search-custom">
                    <mat-label>{{ 'common.search' | translate }}</mat-label>
                    <input type='text' matInput
                           [formControl]="searchFormControl"
                           (keyup.enter)='searchByKeyword($event.target.value)' #searchFilter/>
                    <img matSuffix src="./assets/icons/search.svg" class="fontawesome-icon-custom" alt="search"/>
                </mat-form-field>
                <img src="./assets/icons/x-mark.svg"
                        class="fontawesome-icon-custom ml-2 cursor-pointer"
                        alt="Reset"
                        *ngIf="searchFilter.value !== ''"
                        (click)="resetSearch($event)"/>
            </div>
        </div>
        <mat-divider class='my-3'></mat-divider>

        <div class="d-flex justify-content-end">
            <button type="button" mat-flat-button color='primary' (click)="exportAllProducts()" class="custom-button">
                <i class="pr-1 pi pi-download"></i>
                {{ exportBtnLabelKey$ | async | translate }}
            </button>
        </div>
        
        <div class='list-filters'>
            <ng-container *ngIf="(channelCategoriesList$ | async) as channelCategories">
                <div class="d-flex list-filters__channel-cats"
                     *ngIf="channelCategories?.length > 0">
                    <mat-form-field  appearance='outline' class='channel-cats-filter-field'>
                        <mat-label>{{ 'products.channel-category' | translate }}</mat-label>
                        <mat-select panelClass="channel-categories__popup" [value]="channelCategorySelected" (selectionChange)="selectChannelCategory($event)">
                            <mat-option *ngFor="let channelCategory of channelCategories" [value]="channelCategory">
                                <span>{{ channelCategory?.Label }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button *ngIf="channelCategorySelected"
                            (click)="clearChannelCategoriesFilter()"
                            mat-icon-button
                            color="warn"
                            class="ml-3 mt-1"
                            matTooltip="Réinitialiser">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
            </ng-container>

            <div class="d-flex justify-content-between align-items-center mt-3">

                <app-char-template-filters
                        [channelCategorySelected]="channelCategorySelected"
                        [defaultChannelScope]="defaultChannelScope"
                        [paramFilterBuilder]="paramsFilterBuilder"
                        [charTemplates]="charTemplatesView$ | async"
                        [selectedDataLanguageId]="selectedDataLanguageID"
                        (resetAllFilters)="resetProductFilters()"
                        (filterElements)="searchProducts()"
                        (filterByProductType)="filterByProductType($event.productType)">
                </app-char-template-filters>
                
                <mat-button-toggle-group class="toggle-views" [value]="toggle" (change)="toggleView($event)">
                    <mat-button-toggle [value]="true">
                        <mat-icon>menu</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle [value]="false">
                        <mat-icon>apps</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>

        <app-loader-spinner *ngIf='(dataView$ | async)?.loading'></app-loader-spinner>
        <div [ngClass]="{'mat-elevation-z2': toggle}">
            <ng-container *ngIf="dataView$ | async as dataView">
                <!-- Table view-->
                <ng-container *ngIf="toggle else cardsView">
                    <div class="table-container">
                        <app-elements-table
                                [dataSource]="dataView.productWithTotalRow.productSummaries"
                                [columnsToDisplay]='columnsToDisplay'
                                (addAllElementsToFavorites)="onToggleAllFavorites($event)"
                                (sortChange)="sortData($event)">
                        </app-elements-table>
                    </div>
                </ng-container>

                <!-- Mosaic view-->
                <ng-template #cardsView>
                    <div class="cards-block-content mb-2">
                        <app-media-card [filteredCard]="dataView.productWithTotalRow?.productSummaries?.data"></app-media-card>
                    </div>
                </ng-template>

            </ng-container>

            <mat-paginator [ngClass]="{'no-background': !toggle}"
                           [length]='(dataView$ | async)?.productWithTotalRow.totalsRows' showFirstLastButtons
                           [pageSize]='pageSize'
                           [pageSizeOptions]='[5, 10, 25, 50, 100, 200, 500]'
                           (page)='handlePageEvent($event)'>
            </mat-paginator>
        </div>
    </div>
</div>
