import { Item, SelectedDico } from '../types';

export class ItemBuilder {
  private _SelectedDico: SelectedDico;
  private _OperatorValue: string;
  private _Value: string | number;

  withSelectedDico(value: SelectedDico): ItemBuilder {
    this._SelectedDico = value;
    return this;
  }

  withOperatorValue(value: string): ItemBuilder {
    this._OperatorValue = value;
    return this;
  }

  withValue(value: string | number): ItemBuilder {
    this._Value = value;
    return this;
  }

  build(): Item {
    return {
      SelectedDico: this._SelectedDico,
      OperatorValue: this._OperatorValue ?? '=',
      Value: this._Value,
    };
  }
}
