export enum ChaptersTags {
    INTRO = '[INTRO]',
    END = '[END]',
}

export enum TemplatesTags {
    COVER = '[COVER]',
    EDITO = '[EDITO]',
    PRODUCT = '[PRODUCT]',
    NOTE = '[NOTE]',
    MEMBER = '[MEMBER]'
}

export enum TemplatesNames {
    COVER = 'cover',
    EDITO = 'edito',
    PRODUCT = 'product',
}