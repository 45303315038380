<div class="upload-photos-block">
  <!--Listing photos uploadées-->
  <div class="row photos-content">
    <div class="uploaded-img-block" *ngFor="let photo of photosUrl">
      <div class="uploaded-img-container">
        <img [src]="photo.src" [alt]="photo.alt" [title]="photo.alt" class="img-responsive" />
        <ul class="list-unstyled img-actions">
          <li>
            <a [href]="photo.thumbSrc" download queryParamsHandling="merge" title="Zoom" [attr.data-lightbox]="photo.thumbSrc" [attr.data-title]="photo.alt">
              <img src="assets/imgs/picto-zoom-photo.png" alt="Zoom" />
            </a>
          </li>
          <li>
            <a [href]="photo.src" download  queryParamsHandling="merge" title="Télécharger" target="_blank">
              <img src="/assets/imgs/picto-telecharger-photo.png" alt="Télécharger" />
            </a>
          </li>
        </ul>
      </div>
      <div class="uploaded-img-name" [title]="photo.alt">{{photo.alt}}</div>
    </div>
  </div>
</div>
