import {Injectable} from '@angular/core';
import {DialogWarningMessageComponent} from '../graphics/dialog-warning-message/dialog-warning-message.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class WarningService {

    constructor(private _dialog: MatDialog) {
    }

    warningBox() {
        return this._dialog.open(DialogWarningMessageComponent, {
            width: '430px',
        }).afterClosed();
    }
}
