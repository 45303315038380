import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Subscription} from 'rxjs';
import {SpDicoCarac} from 'src/app/data/constants';
import {
    EditPage,
    FetchPublicationTemplates,
    FetchPublicationTemplatesSuccess,
    PublicationTemplateState,
    selectPublicationTemplateView
} from '@store/publication';
import {selectLoadingAuthentication} from 'src/app/store/selector';
import {CatalogPage, PublicationTemplate, TemplateDescription} from '@app/models';
import {PUBLICATION_DEFAULT_COVER_TITLE_MAX_LENGTH} from '@data/constants';
import {NpInstanceService} from '@data/services';
import {PublicationHelper} from 'src/app/data/helpers/publication.helper';

@Component({
    selector: 'app-catalogue-cover-edition-overlay',
    templateUrl: './catalogue-cover-edition-overlay.component.html',
    styleUrls: ['./catalogue-cover-edition-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CatalogueCoverEditionOverlayComponent implements OnInit, OnDestroy {
    @Input() page: CatalogPage;

    @ViewChild('op') overlayPanel: OverlayPanel;

    public loading$ = this._store.select(selectLoadingAuthentication); // TODO new store?
    public coverTemplatesView$ = this._store.select(selectPublicationTemplateView);

    public catalogCoverForm: FormGroup;
    public coverTitleLength = 0;
    public coverTitleMaxLength: number;

    private _imageTemplateByDefault = this._instanceService.getPublicationImageTemplateByDefault();
    private _coverTemplatesViewSub: Subscription;
    private _coverTemplates: PublicationTemplate[];

    constructor(
        private _fb: FormBuilder,
        private _store: Store,
        private _instanceService: NpInstanceService) {
    }

    public ngOnInit(): void {
        this.catalogCoverForm = this._fb.group({
            catalogTitle: [this.page?.description],
            catalogCoverTemplate: ['', Validators.required]
        });
    }

    public getCoverTemplates(): void {
        this._coverTemplatesViewSub = this._store.select(selectPublicationTemplateView)
        .subscribe((templatesView: PublicationTemplateState) => {
            if (templatesView?.hasTemplates) {
                this._coverTemplates = templatesView.publicationTemplates;
                this.setDefaultCoverTemplate(templatesView);
            }
        });

        this._store.dispatch(FetchPublicationTemplates({ templateCharacteristicExtID: SpDicoCarac.CP_EXTID_MODELE_PAGE_COUVERTURE }));
    }

    public displayDefaultImage(coverTemplate: PublicationTemplate): void {
        coverTemplate.jpgFile = this._imageTemplateByDefault;
    }

    public toggle(event: Event): void {
        this.overlayPanel.toggle(event);
        this.coverTitleLength = this.page?.description?.length;
        this.coverTitleMaxLength = this.page?.template?.maxCharactersLength || PUBLICATION_DEFAULT_COVER_TITLE_MAX_LENGTH;
    }

    public closeOverlay(): void {
        this.overlayPanel.hide();
        this._store.dispatch(FetchPublicationTemplatesSuccess({ publicationTemplates: null }));
        this._coverTemplatesViewSub?.unsubscribe();
    }

    public editCover(): void {
        this.closeOverlay();

        PublicationHelper.updateCatalogPage(
            this.catalogCoverForm.controls.catalogTitle.value,
            this.catalogCoverForm.controls.catalogCoverTemplate.value,
            this.page,
            this._coverTemplates,
            true);

        this._store.dispatch(EditPage({ page: this.page, isIntroPage: true }));

    }

    public onTextChange(value: string): void {
        this.coverTitleLength = value.length;
    }

    public templateChange(coverTemplate: PublicationTemplate): void {
        let descriptionJSONParsed: TemplateDescription  | null = null;
        if (coverTemplate?.description) {
            try {
                descriptionJSONParsed = JSON.parse(coverTemplate.description);
            } catch (e) {}
        }

        this.coverTitleMaxLength = descriptionJSONParsed?.MaxCharactersLength || PUBLICATION_DEFAULT_COVER_TITLE_MAX_LENGTH;
    }

    private setDefaultCoverTemplate(templatesView: PublicationTemplateState): void {
        const defaultCoverTemplate = this.page?.template || templatesView?.publicationTemplates[0];
        if (defaultCoverTemplate) {
            this.catalogCoverForm.patchValue({ catalogCoverTemplate: defaultCoverTemplate.id });
        }
    }

    ngOnDestroy(): void {
        this._coverTemplatesViewSub?.unsubscribe();
    }

}
