import { PageTemplate } from '@app/models';

export class PageTemplateBuilder {
    private _id: number;
    private _path: string;
    private _completePath: string;
    private _numberOfElements: number;
    private _maxCharactersLength: number;

    withId(value: number): PageTemplateBuilder {
        this._id = value;
        return this;
    }

    withPath(value: string): PageTemplateBuilder {
        this._path = value;
        return this;
    }

    withCompletePath(value: string): PageTemplateBuilder {
        this._completePath = value;
        return this;
    }

    withNumberOfElements(value: number): PageTemplateBuilder {
        this._numberOfElements = value;
        return this;
    }

    withMaxCharactersLength(value: number): PageTemplateBuilder {
        this._maxCharactersLength = value;
        return this;
    }

    build(): PageTemplate {
        return {
            id: this._id,
            path: this._path,
            completePath: this._path,
            numberOfElements: this._numberOfElements,
            maxCharactersLength: this._maxCharactersLength
        }
    }
}
