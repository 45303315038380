<div class="visuel-media">
    <!--Listing photos uploadées-->
    <div class="photo-block uploaded-img-block" *ngFor="let photo of photoUrls; index as i">
        <div class="uploaded-img-container">
            <img [src]="photo.src" [alt]="photo.alt" [title]="photo.alt" class="img-responsive"/>
            <ul class="list-unstyled img-actions">
                <li *ngIf="!readOnlyAccess">
                    <a (click)="changePicture(photo)" queryParamsHandling="merge" title="Modifier">
                        <img src="assets/imgs/picto-modifier-photo.png" alt="Modifier"/>
                    </a>
                </li>
                <li>
                    <a [href]="photo.src" title="Zoom" target="_blank"
                       [attr.data-lightbox]="photo.src" [attr.data-title]="photo.alt">
                        <img src="assets/imgs/picto-zoom-photo.png" alt="Zoom"/>
                    </a>
                </li>
                <li>
                    <a title="Télécharger" (click)="downloadImage(photo)" class="download-btn">
                        <img src="assets/imgs/picto-telecharger-photo.png" alt="Télécharger"/>
                    </a>
                </li>
                <li *ngIf="!readOnlyAccess">
                    <a [routerLink]="[]" (click)="deletePicture(photo)" queryParamsHandling="merge" title="Supprimer">
                        <img src="assets/imgs/picto-supprimer-photo.png" alt="Supprimer"/>
                    </a>
                </li>
            </ul>
        </div>
<!--        <div class="text-center uploaded-img-name" [title]="photo.alt">{{photo.alt}}</div>-->
    </div>
    <!--Upload-->
    <ng-container *ngIf="!readOnlyAccess">
        <div class="photo-block photo-add-wrapper" (click)="addPicture();" *ngIf="canAddMore">
            <input disabled type="file" name="file" id="file" class="inputfile"/>
        </div>
    </ng-container>

</div>
<span *ngIf="!isValid" class="requied-error-message inpu-custo">Champ obligatoire</span>
